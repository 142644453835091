import React, { useContext, useReducer } from "react";

export const UserContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case "set":
      return { ...state, user: action.user };
    case "setAdmins":
      return { ...state, admins: action.admins };
    case "loading":
      return { ...state, cartLoading: action.loading };
    default:
      return state;
  }
};

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    user: [],
    admins: [],
    userLoadnig: false,
  });

  const setUser = (user) => dispatch({ type: "set", user });

  const setAdmins = (admins) => dispatch({ type: "setAdmins", admins });

  const setUserLoading = (loading) => dispatch({ type: "loading", loading });

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        admins: state.admins,
        userLoading: state.userLoading,
        setUserLoading,
        setUser,
        setAdmins,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
