import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";

import { priceFormat } from "utils/format";
import { TableButton } from "components/TableButton";
import { Trash2 } from "react-feather";

import "./acceptanceActProductTable.scss";

export const AcceptanceActProductTableNew = ({ products, setProducts }) => {
  const removeProductsHandler = (index) => {
    setProducts((prev) => {
      prev.splice(
        prev.findIndex((val) => val.index === index),
        1
      );
      return [...prev];
    });
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => <span className="cute">{cell}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => "editable right",
      validator: (newValue, row) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Должны быть только цифры",
          };
        }
        if (newValue > row.countBefore) {
          return {
            valid: false,
            message: "Не должно быть больше чем кол-во",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Кол-во не может быть меньше 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
      footerClasses: () => "right",
      formatter: (cell) => priceFormat(parseFloat(cell)),
      editable: false,
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
      footerClasses: () => "right",
      formatter: (cell) => priceFormat(parseFloat(cell)),
      editable: false,
    },
    {
      dataField: "countryCode",
      text: "Код страны",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      footer: () => null,
    },
    {
      dataField: "countryName",
      text: "Страна",
      formatter: (cell) => <span className="cute">{cell}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "gtd",
      text: "ГТД",
      formatter: (cell) => <span className="cute">{cell}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      footer: () => null,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <TableButton
          Icon={Trash2}
          onClick={() => removeProductsHandler(row.index)}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      footer: () => null,
      editable: false,
    },
  ];

  const rowClasses = (row) => {
    if (row.nomenclature === null) {
      return "error";
    }
  };

  return (
    <>
      <BootstrapTable
        wrapperClasses="asseptance-act-product"
        keyField="id"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
        })}
        rowClasses={rowClasses}
      />
    </>
  );
};
