import { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { Button, Col, Input, InputGroup, Label, Row } from "reactstrap";
import Select from "react-select";

import {
  addCharacteristicValue,
  createCharacteristic,
  getCharacteristics,
  getCharacteristicsList,
  removeCharacteristicValue,
  updateCharacteristicValue,
} from "services/api";
import { CharacteristicModal } from "../CharacteristicModal";
import { toast } from "react-toastify";
import { PlusSquare, Trash } from "react-feather";
import { MySpinner } from "components/MySpinner";
import { TableButton } from "components/TableButton";
import { CharacteristicsModal } from "features/Characteristics/CharacteristicsModal";

export const Characteristics = ({
  id,
  setCharacteristicsValue,
  characteristicsValue,
}) => {
  const [loading, setLoading] = useState(false);
  const [characteristics, setCharacteristics] = useState([]);
  const [characteristic, setCharacteristic] = useState({});
  const [value, setValue] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [openCharacteristicModal, setOpenCharacteristicModal] = useState("");

  const removeCharacteristicValueHandler = (id) => {
    setLoading(true);
    removeCharacteristicValue({ id })
      .then((res) => {
        setCharacteristicsValue(res);
        toast.success("Значение характеристики успешно удалено");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const editHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateCharacteristicValue({
      id: row.id,
      value: row.value,
    })
      .then((res) => {
        setCharacteristicsValue(res);
        toast.success("Значение характеристики успешно отредактировано");
      })
      .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <>
          {row.name}
          {row.unit && ","} {row.unit}
        </>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
      editable: false,
    },
    {
      dataField: "value",
      text: "Значение",
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <TableButton
          Icon={Trash}
          onClick={() => removeCharacteristicValueHandler(row.id)}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
    },
  ];

  const getAndSetCharacteristics = () => {
    getCharacteristicsList()
      .then((res) => {
        setCharacteristics(
          res.map((val) => {
            return {
              label: `${val.name}, ${val.unit}`,
              value: val.id,
              type: val.type,
            };
          })
        );
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const openFormCharacteristic = () => {
    setLoading(true);
    getAndSetCharacteristics();
    setShowForm(true);
  };

  const addCharacteristicValueHandler = () => {
    setLoading(true);
    addCharacteristicValue({
      nomenclature: id,
      characteristic: characteristic?.value,
      value,
    })
      .then((res) => {
        toast.success("Значение характеристики успешно добавлено");
        setCharacteristicsValue(res);
        setValue("");
        setCharacteristic(null);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const cancel = () => {
    setValue("");
    setCharacteristic(null);
    setShowForm(false);
  };

  const valueInputHandler = (e) => {
    console.log(characteristic);
    switch (characteristic?.type) {
      case "string":
        setValue(e.target.value);
        break;
      case "integer":
        const val = e.target.value.replace(".", ",").replace(" ", "");
        if (val === "" || /^-?\d+(\,\d*)?$/.test(val)) {
          setValue(val);
        }
        break;

      default:
        setValue(e.target.value);
        break;
    }
  };

  return (
    <>
      {loading && <MySpinner overlay />}
      <BootstrapTable
        keyField="id"
        data={characteristicsValue}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        classes="pointer"
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            editHandler(oldData, newData, row),
        })}
      />

      {!showForm && (
        <Row>
          <Button onClick={openFormCharacteristic} color="primary">
            Добавить
          </Button>
        </Row>
      )}
      {showForm && (
        <>
          <Row>
            <Col md={6}>
              <Label>Характеристика</Label>
              <InputGroup>
                <Select
                  name={name}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={characteristics}
                  value={characteristic}
                  onChange={setCharacteristic}
                />
                <Button
                  onClick={() => setOpenCharacteristicModal(true)}
                  color="success"
                >
                  <PlusSquare size={15} />
                </Button>
              </InputGroup>
            </Col>
            <Col md={6}>
              <Label>Значение</Label>
              <Input
                maxLength="128"
                name="value"
                placeholder="Значение"
                value={value || ""}
                onChange={valueInputHandler}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="d-flex justify-content-end mt-1">
              <Button onClick={cancel}>Отменить</Button>
              <Button
                onClick={addCharacteristicValueHandler}
                color="primary"
                className="ml-1"
              >
                Сохранить
              </Button>
            </Col>
          </Row>

          <CharacteristicsModal
            openModal={openCharacteristicModal}
            setOpenModal={setOpenCharacteristicModal}
            setLoading={setLoading}
            loading={loading}
            getAndSetCharacteristics={getAndSetCharacteristics}
          />
        </>
      )}
    </>
  );
};
