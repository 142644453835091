import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";

import { dateTimeFormat, priceFormat } from "utils/format";
import {
  getOrderToProviderStatuses,
  setOrderToProviderStatus,
} from "services/apiSupply";
import { StatusOrderToProvider } from "components/StatusOrderToProvider";

import user from "assets/img/user.svg";
import "./ordersToProviderTable.scss";

export const OrdersToProviderTable = ({
  ordersToProvider,
  getAndSetOrdersToProvider,
  setLoading,
}) => {
  const history = useHistory();
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    getOrderToProviderStatuses()
      .then(setStatuses)
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusHandler = (id, status) => {
    setLoading(true);
    setOrderToProviderStatus({
      id,
      statusId: status?.id,
    })
      .then((res) => {
        getAndSetOrdersToProvider();
        toast.success("Статус успешно изменен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "date",
      text: "Дата",
      headerStyle: () => {
        return { width: "7%" };
      },
      formatter: (cell) => dateTimeFormat(cell),
    },
    {
      dataField: "provider",
      text: "Поставщик",
      headerStyle: () => {
        return { width: "13%" };
      },
      classes: () => "bold",
    },
    {
      dataField: "status",
      text: "Статус",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => (
        <StatusOrderToProvider
          status={{
            color: cell.color,
          }}
          statuses={statuses}
          statusHandler={(status) => statusHandler(row.id, status)}
        >
          {cell.name}
        </StatusOrderToProvider>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "admin",
      text: "Создал",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/receipt/ordersToProvider/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={ordersToProvider}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
