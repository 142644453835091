import React, { useEffect } from "react";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import { FormTitle } from "components/FormTitle";
import { Checkbox } from "components/Checkbox";
import { bankSuggestion } from "services/api";

import "./legalForm.scss";
import { toast } from "react-toastify";

export const LegalForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setLoading,
  setFieldValue
}) => {

  const bankSearch = (search) => {
    setFieldValue("orgBik", search);
    if (search.length !== 9) return;
    setLoading(true);
    bankSuggestion(search)
      .then(val => {
        if (!val) return;

        setFieldValue("orgKs", val.orgKs);
        setFieldValue("orgBank", val.orgBank);

      })
      .catch(e => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false))
  }

  return (
    <Card className="legal-form mb-0">
      <CardBody>
        <FormTitle>Организация</FormTitle>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Полное название организации <span>*</span>
            </Label>
            <Input
              name="orgName"
              placeholder="Полное название организации"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgName}
              invalid={
                errors.orgName && touched.orgName ? true : false
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Краткое название организации <span>*</span>
            </Label>
            <Input
              name="orgNameShort"
              placeholder="Краткое название организации"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgNameShort}
              invalid={
                errors.orgNameShort && touched.orgNameShort ? true : false
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Юридический адрес <span>*</span>
            </Label>
            <Input
              name="orgAddress"
              placeholder="Юридический адрес"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgAddress}
              invalid={
                errors.orgAddress && touched.orgAddress ? true : false
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Наименование грузополучателя</Label>
            <Input
              name="consigneeName"
              placeholder="Наименование грузополучателя"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.consigneeName}
              invalid={
                errors.consigneeName && touched.consigneeName ? true : false
              }
            />
            <span className="sub">
              Если не заполнено - будет использоваться Наименование организации в
              документах
            </span>
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Адрес грузополучателя</Label>
            <Input
              name="consigneeAddress"
              placeholder="Адрес грузополучателя"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.consigneeAddress}
              invalid={
                errors.consigneeAddress && touched.consigneeAddress ? true : false
              }
            />
            <span className="sub">
              Если не заполнено - будет использоваться Юридический адрес в
              документах
            </span>
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              ИНН <span>*</span>
            </Label>
            <Input
              name="orgInn"
              placeholder="ИНН"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgInn}
              invalid={
                errors.orgInn && touched.orgInn ? true : false
              }
            />
          </Col>

          <Col className="elem-field">
            <Label className="text-sm-right">
              КПП <span>*</span>
            </Label>
            <Input
              name="orgKpp"
              placeholder="КПП"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgKpp}
              invalid={
                errors.orgKpp && touched.orgKpp ? true : false
              }
            />
          </Col>

          <Col className="elem-field">
            <Label className="text-sm-right">ОГРН</Label>
            <Input
              name="orgOgrn"
              placeholder="ОГРН"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgOgrn}
              invalid={
                errors.orgOgrn && touched.orgOgrn ? true : false
              }
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">ОКПО</Label>
            <Input
              name="orgOkpo"
              placeholder="ОКПО"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgOkpo}
              invalid={
                errors.orgOkpo && touched.orgOkpo ? true : false
              }
            />

          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              БИК <span>*</span>
            </Label>
            <Input
              name="orgBik"
              placeholder="БИК"
              onChange={e => bankSearch(e.target.value)}
              onBlur={handleBlur}
              value={values.orgBik}
              invalid={
                errors.orgBik && touched.orgBik ? true : false
              }
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Банк <span>*</span>
            </Label>
            <Input
              name="orgBank"
              placeholder="Корр счет"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgBank}
              invalid={
                errors.orgBank && touched.orgBank ? true : false
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Рассчетный счет <span>*</span>
            </Label>
            <Input
              name="orgRs"
              placeholder="Рассчетный счет"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgRs}
              invalid={
                errors.orgRs && touched.orgRs ? true : false
              }
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Корр счет <span>*</span>
            </Label>
            <Input
              name="orgKs"
              placeholder="Корр счет"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgKs}
              invalid={
                errors.orgKs && touched.orgKs ? true : false
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              ФИО руководителя <span>*</span>
            </Label>
            <Input
              name="genDir"
              placeholder="ФИО руководителя"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.genDir}
              invalid={
                errors.genDir && touched.genDir ? true : false
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Должность и ФИО руководителя в род. падеже
            </Label>
            <Input
              name="genDirGenitive"
              placeholder="генерального директора Иванова Ивана Ивановича"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.genDirGenitive}
              invalid={
                errors.genDirGenitive && touched.genDirGenitive ? true : false
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Должность руководителя
            </Label>
            <Input
              name="post"
              placeholder="генеральный директор"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.post}
              invalid={
                errors.post && touched.post ? true : false
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              На основании
            </Label>
            <Input
              name="accordanceDocument"
              placeholder="Устава"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.accordanceDocument}
              invalid={
                errors.accordanceDocument && touched.accordanceDocument ? true : false
              }
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Работает с ДИАДОК</Label>
            <Checkbox
              name="withDiadoc"
              onChange={e => setFieldValue("withDiadoc", e.target.checked)}
              checked={values.withDiadoc}
            />
          </Col>
        </Row>
        {values.withDiadoc &&
          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">
                ID получателя в диадоке (если роуминг)
              </Label>
              <Input
                name="diadocRecepientId"
                placeholder="ID получателя в диадоке (если роуминг)"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.diadocRecepientId}
                invalid={
                  errors.diadocRecepientId && touched.diadocRecepientId ? true : false
                }
              />
              <small>Заполняется, если получатель работает через роуминг. ID можно получить в кабинете Диадока.</small>
            </Col>
          </Row>
        }
      </CardBody>
    </Card>
  );
};
