import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

import { Cut } from "components/Cut";

import { priceFormat } from "utils/format";
import { CheckCircle } from "react-feather";

export const AvitoTable = ({ ads, setSelected }) => {

  const history = useHistory();
  const [ctrl, setCtrl] = useState(false);

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(ads.map((val) => val.id));
      }
    },
  };

  const tableColumns = [
    {
      dataField: "title",
      text: "Заголовок объявлеия",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "20%" };
      },
      editable: false,
    },
    {
      dataField: "active",
      text: "Активно",
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "4%" };
      },
      editable: false,
    },
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "10%" };
      },
      editable: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "10%" };
      },
      editable: false,
    },
    {
      dataField: "marketplacePrice",
      text: "Цена",
      formatter: (cell, row) => priceFormat(cell),
      headerStyle: () => {
        return { width: "6%" };
      },
      align: "right",
      editable: false,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: () => {
        return { width: "20%" };
      },
      editable: false,
    },

    {
      dataField: "sparePartType",
      text: "Вид и тип товара",
      formatter: (cell, row) => cell && cell.length > 1 ? cell.join("/") : null,
      headerStyle: () => {
        return { width: "40%" };
      },
      editable: false,
    }
  ];

  const rowEvents = {
    onClick: (e, row) => {
      if (ctrl) {
        window.open(`/reference/avito/${row.id}`, "_blank");
        return;
      }

      history.push(`/reference/avito/${row.id}`);
    },
  };

  useEffect(() => {
    const onKeyDown = (e) => e.keyCode === 17 && setCtrl(true);
    const onKeyUp = (e) => e.keyCode === 17 && setCtrl(false);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={ads}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
        selectRow={selectRow}
      />
    </>
  );
};
