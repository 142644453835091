import { Type } from "react-bootstrap-table2-editor";
import { dateFormat } from "utils/format";
import { Cut } from "components/Cut";
export const tableColumns = [
  {
    dataField: "id",
    text: "id",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    },
		formatter: (cell, row) => <Cut>{cell}</Cut>,
  },
  {
    dataField: "name",
    text: "Бренд",
    classes: () => "editable",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    },
		formatter: (cell, row) => <Cut>{cell}</Cut>,
  },
  {
    dataField: "active",
    text: "Активен",
    classes: (cell) =>
      cell === "Нет" ? "editable inactive" : "editable active",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    editor: {
      type: Type.CHECKBOX,
      value: "Да:Нет",
    },
  },
  {
    dataField: "countNomenclature",
    text: "Номенклатура",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    editable: false,
  },
  {
    dataField: "createdAt",
    text: "Дата добавления",
    headerStyle: (colum, colIndex) => {
      return { width: "60%" };
    },
    formatter: (cell) => dateFormat(cell),
  },
];
