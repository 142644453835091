import React, { useContext, useReducer } from "react";

export const CartContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return { ...state, cart: [...state.cart, action.prod] }
    case 'set':
      return { ...state, cart: action.cart }
    case 'setCustomer':
      return { ...state, customer: action.customer }
    case 'loading':
      return { ...state, cartLoading: action.loading }
    default: return state;
  }
}

export const useCart = () => {
  return useContext(CartContext);
}

export const CartProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, {
    cart: [],
    customer: {},
    cartLoadnig: false
  });

  const addToCart = prod => dispatch({ type: 'add', prod });

  const setCart = cart => dispatch({ type: 'set', cart });

  const setCustomer = customer => dispatch({ type: 'setCustomer', customer });

  const setCartLoading = loading => dispatch({ type: 'loading', loading });

  return <CartContext.Provider
    value={{
      cart: state.cart,
      customer: state.customer,
      cartLoading: state.cartLoading,
      setCartLoading,
      addToCart,
      setCart,
      setCustomer
    }}
  >
    {children}
  </CartContext.Provider>;

}