import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { getCross, removeCross } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { CrossTable } from "features/Cross/CrossTable";

const SORT_FIELDS = [
  {
    label: "Дата добавления ↑",
    value: "createdAt_asc",
    by: "createdAt",
    dir: "asc",
  },
  {
    label: "Дата добавления ↓",
    value: "createdAt_desc",
    by: "createdAt",
    dir: "desc",
  },
];

export const SOURCES = [
  {
    value: "armtek",
    label: "Армтек",
  },
  {
    value: "trackmotors",
    label: "Тракмоторс",
  },
  {
    value: "corona",
    label: "Корона",
  },
  {
    value: "favorit",
    label: "Фаворит",
  },
  {
    value: "forum",
    label: "Форум",
  },
  {
    value: "omega",
    label: "Омега",
  }
];

export const CrossPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [cross, setCross] = useState([]);
  const [crossCount, setCrossCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
      (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
    )
    : null;
  const [sort, setSort] = useState(sortByDefault);

  const [source, setSource] = useState(
    query.get("source")
      ? { label: query.get("sourceName"), value: query.get("source") }
      : null
  );

  const getAndSetCross = () => {
    getCross({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      sort,
      source: source?.value
    })
      .then((res) => {
        setCross(res.items);
        setCrossCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (sort?.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (source) {
      url.append("source", source?.value);
      url.append("sourceName", source?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search, sort, source]);

  useEffect(() => {
    setLoading(true);
    getAndSetCross();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetCross();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, source]);

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetCross();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const isSearch = () => {
    if (search || source) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
    setSource(null);
  };

  const onSort = (field, order) => {
    setSort({
      by: field,
      dir: order,
    });
  };

  const remove = (id) => {
    setLoading(true);
    removeCross({ id })
      .then((res) => {
        getAndSetCross();
        toast.success("Кроссы успешно удалены");
      })
      .catch((e) => { });
  };

  return (
    <Container fluid className="p-0 cross">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Кроссы</h1>
          <Form className="mb-3">
            <Row>
              <Col md="4">
                <Label>Поиск</Label>
                <Input
                  name="search"
                  placeholder="По артикулу и аналогу"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <Label>Источник</Label>
                <Select
                  name="source"
                  placeholder="Источник"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={SOURCES}
                  value={source}
                  onChange={val => setSource(val)}
                  isClearable
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
          <Link to="/reference/cross/upload" className="btn btn-primary">
            Загрузить кроссы
          </Link>
        </CardBody>
      </Card>
      <div>
        <CrossTable cross={cross} remove={remove} onSort={onSort} />
        <Card>
          <CardBody>
            <Pagination
              totalCount={crossCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
