import { dateFormat } from "utils/format";

import { Cut } from "components/Cut";

export const tableColumns = [
  {
    dataField: "name",
    text: "Имя",
    headerStyle: (colum, colIndex) => {
      return { width: '12%' };
    },
		formatter: (cell, row) => <Cut>{cell}</Cut>,
  },
  {
    dataField: "active",
    text: "Активен",
    headerStyle: (colum, colIndex) => {
      return { width: '4%' };
    },
    formatter: cell => cell ? 'Да' : 'Нет',
  },
  {
    dataField: "orgInn",
    text: "ИНН",
    headerStyle: (colum, colIndex) => {
      return { width: '8%' };
    }
  },
  {
    dataField: "deliveryTime",
    text: <Cut>Срок поставки</Cut>,
    headerStyle: (colum, colIndex) => {
      return { width: '3%' };
    }
  },
  {
    dataField: "probability",
    text: <Cut>Вер-сть поставки</Cut>,
    headerStyle: (colum, colIndex) => {
      return { width: '3%' };
    }
  },
  {
    dataField: "countPrices",
    text: <Cut>Кол-во в прайсе</Cut>,
    headerStyle: (colum, colIndex) => {
      return { width: '3%' };
    }
  },
  {
    dataField: "priceLog",
    text: "Прайс лог",
    headerStyle: (colum, colIndex) => {
      return { width: '7%' };
    }
  },
  {
    dataField: "priceLastUpdate",
    text: "Последнее обновление прайса",
    headerStyle: (colum, colIndex) => {
      return { width: '8%' };
    },
    formatter: cell => cell ? dateFormat(cell) : '',
  },
];