import React, { useState } from "react";
import { NavLink, useHistory, withRouter } from "react-router-dom";

import {
  Badge,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import routes from "routes/index";
import Logo from "assets/img/logoAdmin.svg";
import LogoBottom from "assets/img/logoFooter.svg";
import { useUser } from "features/Users/UserContext";
import { BRANCHES } from "pages/admins";
import { updateAdminSettings } from "services/api";
import { toast } from "react-toastify";

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);


const Sidebar = ({ location }) => {
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const { user, setUser } = useUser();

  const history = useHistory();

  const sidebar = {
    isOpen: true,
    isSticky: true,
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("branch");

    history.push("/sign-in");
  };

  const branchHandler = (branch = 1) => {

    updateAdminSettings({ branch })
      .then(setUser)
      .catch(e => toast.error(e.response?.data?.message))
      .finally(() => document.location.reload())
  };

  const branchRender = () => {
    const branch = BRANCHES.find(item => item.value === Number(user?.settings?.branch));

    return branch?.label || "Москва";
  }


  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const branchSelectRender = () => {
    if (user.roles?.find((role) => role === "ROLE_SUPER_ADMIN")) {
      return <div className="sidebar-block">
        <UncontrolledDropdown>
          <span className="d-none d-sm-inline-block">
            <DropdownToggle>
              Филиал: {branchRender()}
            </DropdownToggle>
          </span>
          <DropdownMenu>
            <DropdownItem onClick={() => branchHandler(1)}>Москва</DropdownItem>
            <DropdownItem onClick={() => branchHandler(2)}>Воронеж</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    }

    return null;
  }

  return (
    <nav
      className={
        "sidebar" +
        (!sidebar.isOpen ? " toggled" : "") +
        (sidebar.isSticky ? " sidebar-sticky" : "")
      }
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <div className="sidebar-block">
            <div className="sidebar-upper">
              <button className="sidebar-button">
                <span></span>
                <span></span>
                <span></span>
              </button>
              <a className="sidebar-brand" href="/">
                <img src={Logo} alt="ЛИДЕР ТРАК" />
              </a>
            </div>

            <div className="sidebar-account">
              <UncontrolledDropdown nav inNavbar>
                <span className="d-none d-sm-inline-block">
                  <DropdownToggle nav>
                    <span className="sidebar-user">
                      {user.firstName}{" "}
                      {user.lastName?.length && user.lastName[0]}
                    </span>
                    <div className="sidebar-icon">
                      {user.firstName?.length && user.firstName[0]}{" "}
                      {user.lastName?.length && user.lastName[0]}
                    </div>
                  </DropdownToggle>
                </span>
                <DropdownMenu end>
                  <DropdownItem onClick={logout}>Выход</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>

          {branchSelectRender()}

          <ul className="sidebar-nav">
            {routes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
          <div className="sidebar__bottom">
            <img src={LogoBottom} alt="ЛИДЕР ТРАК" />
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default withRouter(Sidebar);
