import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { SelectSearch } from "components/SelectSearch";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { PlusSquare, X as XIcon } from "react-feather";
import classNames from "classnames";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { brandsSuggestion, getNomenclatureOne } from "services/api";

import { validHandler } from "utils/validation";
import { Checkbox } from "components/Checkbox";

import "./nomenclatureModal.scss";

import noImage from "assets/img/noimage.jpg";
import { priceFormat } from "utils/format";

export const NomenclatureModal = ({
  openModal,
  setOpenModal,
  id,
  getAndSetNomenclature,
}) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState(null);
  const [name, setName] = useState("");
  const [id1C, setId1C] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [fixPrice, setFixPrice] = useState("");
  const [marketplacePrice, setMarketplacePrice] = useState("");
  const [disableAutoUpdate, setDisableAutoUpdate] = useState(false);
  const [errors, setErrors] = useState([]);
  const [images, setImages] = useState([]);
  const [needValid, setNeedValid] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const inputImage = useRef();
  const [addedImages, setAddedImages] = useState([]);
  const [cell, setCell] = useState("");

  const getAndSetNomenclatureOne = () => {
    if (!id) return;

    getNomenclatureOne(id)
      .then((res) => {
        setActive(res.active);
        setArticle(res.article);
        setName(res.name);
        setBrand(res.brand);
        setId1C(res.id1C);
        setWeight(res.weight);
        setLength(res.size?.length);
        setWidth(res.size?.width);
        setHeight(res.size?.height);
        setMinPrice(res.minPrice);
        setCostPrice(res.costPrice);
        setFixPrice(res.fixPrice);
        setMarketplacePrice(res.marketplacePrice);
        setCell(res.cell?.name);
        setDisableAutoUpdate(res.disableAutoUpdate);
        setImages(res.images || []);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    setActive("");
    setArticle("");
    setName("");
    setBrand(null);
    setId1C("");
    setWeight("");
    setLength("");
    setWidth("");
    setHeight("");
    setMinPrice("");
    setCostPrice("");
    setFixPrice("");
    setMarketplacePrice("");
    setCell("");
    setDisableAutoUpdate("");
    setImages([]);
    setSelectedImage("");
    getAndSetNomenclatureOne();
  }, [id]);

  const renderImage = () => {
    if (images.length > 0 && !selectedImage) {
      return (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${images[0].key}`}
          alt={name}
        />
      );
    }

    if (selectedImage) {
      return (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${selectedImage}`}
          alt={name}
        />
      );
    }

    return <img src={noImage} alt={name} />;
  };

  const getImages = () => {
    if (inputImage) {
      inputImage.current.click();
    }
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="nomenclature-modal"
    >
      {loading && <MySpinner overlay />}
      <ModalHeader>
        <div className="modal-title-text">Номенклатура</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row className="main-info">
          <Col md="5">
            <div className="nomenclature-modal__image">{renderImage()}</div>
            <div className="nomenclature-modal__miniatures">
              {images.map((image) => (
                <div
                  className={classNames("nomenclature-modal__miniature", {
                    selected: image.key === selectedImage,
                  })}
                  key={image.id}
                  onClick={() => setSelectedImage(image.key)}
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/image.view?file=${image.key}`}
                  />
                </div>
              ))}
            </div>
          </Col>
          <Col md="7">
            <Row>
              <Col md="3">
                <Label>Артикул</Label>
              </Col>
              <Col md="9">
                <b>{article}</b>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Label>Бренд</Label>
              </Col>
              <Col md="9">
                <b>{brand?.name}</b>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Label>Наименование</Label>
              </Col>
              <Col md="9">
                <b>{name}</b>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Label>Вес, кг</Label>
              </Col>
              <Col md="9">
                <b>{weight}</b>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Label>Длина, мм</Label>
              </Col>
              <Col md="9">
                <b>{length}</b>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Label>Ширина, мм</Label>
              </Col>
              <Col md="9">
                <b>{width}</b>
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <Label>Высота, мм</Label>
              </Col>
              <Col md="9">
                <b>{height}</b>
              </Col>
            </Row>

            <Row className="prices">
              <Col md="3">
                <Label>Себестоимость</Label>
                <br />
                <b>{priceFormat(costPrice)}</b>
              </Col>

              <Col md="3">
                <Label>Фикс цена</Label>
                <br />
                <b>{priceFormat(fixPrice)}</b>
              </Col>
            </Row>

            <Row className="warehouse">
              <Col md="4">
                <Label>Ячейка</Label>
                <br />
                <b>{cell}</b>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
