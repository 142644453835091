import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import InputMask from "react-input-mask";
import classNames from "classnames";
import Select from "react-select";
import { toast } from "react-toastify";

import { Checkbox } from "components/Checkbox";
import { orgSuggestion, updateCustomer } from "services/api";

import { useUser } from "../UserContext";

import "./mainForm.scss";
import { BRANCHES } from "pages/admins";
import { MarkupModal } from "../MarkupModal";
import { priceFormat } from "utils/format";

export const TYPES = [
  { value: 1, label: "Физ." },
  { value: 2, label: "Юр." },
];

export const PRINT_DOCUMENTS = [
  { value: "invoice", label: "Счет без печати" },
  { value: "utd", label: "УПД" },
  { value: "torg1T", label: "ТТН" }
];

export const MainForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setLoading
}) => {
  const { user, admins } = useUser();
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const searchOrg = (search) => {
    setSearch(search);
    if (search.length !== 10 && search.length !== 12) return;
    setLoading(true);
    orgSuggestion(search, 1)
      .then(val => {
        console.log(val);
        const data = val[0] || null;

        if (!data) return;

        setFieldValue("orgNameShort", data.orgNameShort);
        setFieldValue("orgName", data.orgName);
        setFieldValue("orgAddress", data.orgAddress);
        setFieldValue("orgInn", data.orgInn);
        setFieldValue("orgKpp", data.orgKpp);
        setFieldValue("orgOgrn", data.orgOgrn);
        setFieldValue("orgOkpo", data.orgOkpo);
        setFieldValue("genDir", data.genDir);
        setFieldValue("firstName", data.firstName);
        setFieldValue("lastName", data.lastName);
        setFieldValue("middleName", data.middleName);

        toast.success("Данные организации успешно заполнены");

      })
      .catch(e => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false))
  };

  const branchRender = (item) => {
    return <Row>
      <Col className="elem-field">
        <Label className="text-sm-right">{item.label}</Label>
        <Checkbox
          id={`branch_${item.value}`}
          checked={values.branch[item.value - 1]}
          name="branch"
          onChange={e => {
            const tmp = [...values.branch];
            tmp[item.value - 1] = e.target.checked === true ? 1 : 0;
            setFieldValue("branch", tmp);
          }}
          invalid={
            errors.branch && touched.branch ? true : false
          }
        />
      </Col>
    </Row>
  };

  const markupHandler = (markup) => {
    setLoading(true);
    setOpenModal(false);
    updateCustomer({ id: values.id, markup_id: markup })
      .then((res) => {
        setFieldValue("markup", res.markup);
        toast.success("Изменения успешно сохранены");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  const markupRender = (markup) => {
    return <>
      <div>{markup.name}</div>
      <ul>
        {Object.entries(markup.markup?.list).map(([price, percent]) =>
          <li>до {priceFormat(price)} р: {percent} %</li>
        )}
        <li>{Object.entries(markup.markup?.list).length > 0 ? '... и выше' : 'Единая наценка'}:  {markup.markup['~']} %</li>
      </ul>
    </>
  }

  return (
    <Card
      className={classNames("main-form", "mb-0", {
        "main-form__border": values.type === 2,
      })}
    >
      <CardBody>
        {!values.id && (
          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">Тип</Label>
              <Select
                name="type"
                placeholder="Выберите тип клиента"
                className="react-select-container"
                classNamePrefix="react-select"
                options={TYPES}
                value={TYPES.find((type) => values.type === type.value)}
                onChange={(val) => setFieldValue("type", val.value)}
              />
            </Col>
          </Row>
        )}

        {user.roles?.find((role) => role === "ROLE_SUPER_ADMIN") &&
          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">
                Подразделение
              </Label>
              {BRANCHES.map(item => <div key={item.value}>
                {branchRender(item)}
              </div>)}
            </Col>
          </Row>
        }

        {values?.type === 2 && !values.id && (
          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">
                ИНН <span>*</span>
              </Label>
              <Input
                name="search"
                placeholder="ИНН"
                onChange={e => searchOrg(e.target.value)}
                value={search}
                invalid={
                  errors.search && touched.search ? true : false
                }
              />
              <span className="sub">
                Начните с заполнения ИНН
              </span>
            </Col>
          </Row>
        )}

        <>
          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">Фамилия</Label>
              <Input
                name="lastName"
                placeholder="Фамилия"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
              />
            </Col>
            <Col className="elem-field">
              <Label className="text-sm-right">
                Имя <span>*</span>
              </Label>
              <Input
                name="firstName"
                placeholder="Имя"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                invalid={
                  errors.firstName && touched.firstName ? true : false
                }
              />
            </Col>
            <Col className="elem-field">
              <Label className="text-sm-right">Отчество</Label>
              <Input
                name="middleName"
                placeholder="Отчество"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.middleName}
              />
            </Col>
          </Row>

          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">
                Телефон <span>*</span>
              </Label>
              <InputMask
                id="phone"
                name="phone"
                type="tel"
                placeholder="Телефон"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                mask={"+7(999) 999-99-99"}
                className="form-control"
                invalid={
                  errors.phone && touched.phone ? true : false
                }
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Col>
            <Col className="elem-field">
              <Label className="text-sm-right">
                Email <span>*</span>
              </Label>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                invalid={
                  errors.email && touched.email ? true : false
                }
              />
            </Col>
          </Row>
        </>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Лимит кредита</Label>
            <Input
              name="creditSumMax"
              placeholder="Лимит кредита"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.creditSumMax}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Дней отсрочки</Label>
            <Input
              name="delayDayCount"
              placeholder="Дней отсрочки"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.delayDayCount}
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Ответственный менеджер</Label>
            <Select
              name="admin"
              placeholder="Ответственный менеджер"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(val) => setFieldValue("admin", val)}
              onBlur={handleBlur}
              options={admins}
              value={!values.id ? { label: `${user.lastName} ${user.firstName}`, value: user.id } : values?.admin}
              isDisabled={!user.roles?.find((role) => role === "ROLE_ADMIN")}
            />
          </Col>
          <Col className="elem-field">
            <Label className="text-sm-right">Торговый представитель</Label>
            <Select
              name="salesRep"
              placeholder="Торговый представитель"
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(val) => setFieldValue("salesRep", val)}
              onBlur={handleBlur}
              options={admins}
              value={!values.id ? { label: `${user.lastName} ${user.firstName}`, value: user.id } : values?.salesRep}
              isDisabled={!user.roles?.find((role) => role === "ROLE_ADMIN")}
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Лимит запросов артикулов</Label>
            <Input
              name="queryLimit"
              placeholder="Лимит запросов артикулов"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.queryLimit}
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Настройки пакетной печати документов</Label>

            {PRINT_DOCUMENTS.map((item) => <Row key={item.value}>
              <Col className="printDoc">
                <Checkbox
                  id={item.value}
                  checked={values.printDoc.find(v => v === item.value)}
                  name="printDoc"
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue("printDoc", [...values.printDoc, item.value])
                    } else {
                      setFieldValue("printDoc", values.printDoc.filter(v => v !== item.value));
                    }
                  }}
                  onBlur={handleBlur}
                >
                  {item.label}
                </Checkbox >
              </Col>
            </Row>)}
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Правило наценки</Label>
            <div className="markup">
              {values.markup ?
                <>{markupRender(values.markup)}</>
                :
                <>
                  По умолчанию
                </>
              }
              <Button onClick={() => setOpenModal(true)} color="primary">
                Изменить
              </Button>
            </div>

          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Оптовый клиент</Label>
            <Checkbox
              checked={values.wholesale}
              name="wholesale"
              onChange={e => setFieldValue("wholesale", e.target.checked)}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Комментарий</Label>
            <Input
              type="textarea"
              name="comment"
              placeholder="Комментарий"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.comment}
            />
          </Col>
        </Row>

        <MarkupModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          onClick={markupHandler}
        />
      </CardBody >
    </Card >
  );
};
