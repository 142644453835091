import react, { useEffect, useState } from "react";
import classNames from "classnames";
import { Formik } from "formik";
import { Helmet } from "react-helmet";
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";

import { HeaderPage } from "components/HeaderPage";
import { getSettings, updateSettings } from "services/api";

import "./settings.scss";


registerLocale("ru", ru);

export const SettingsPage = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitHandler = (values) => {
    updateSettings(values)
  }

  const getAndSetSettings = () => {
    setLoading(true);
    getSettings(1)
      .then((res) => {
        setSettings(res);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetSettings();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container fluid className="settings p-0">
    <Helmet>
      <title>Настройки</title>
    </Helmet>

    <Formik
      initialValues={{
        id: settings?.id || "",
        index: settings?.index || "",
        dateProhibition: settings?.dateProhibition || ""
      }}
      onSubmit={(values, { setSubmitting, resetForm }, formikBag) => {
        submitHandler(values);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>

          <HeaderPage title="Настройки" />

          <Card className={classNames("settingsCard", "mb-0")}>
            <CardBody>
              <Row>
                <Col className="elem-field">
                  <Label className="text-sm-right">
                    Дата запрета
                  </Label>

                  <div className="d-flex justify-content-between">
                    <DatePicker
                      placeholderText="__.__.____"
                      selected={values.dateProhibition}
                      onChange={(val) => setFieldValue("dateProhibition", val)}
                      dateFormat="dd.MM.yyyy"
                      locale="ru"
                      className="form-control date"
                      customInput={<ReactInputMask mask={"99.99.9999"} />}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div className="settings__save">
            <Button
              type="submit"
              color="primary"
              className="button-wide"
            >
              Сохранить
            </Button>
          </div>
        </form>
      )}
    </Formik>

  </Container>;
}