import React from "react";
import { Checkbox } from "components/Checkbox";

import "./activeResource.scss";

export const ActiveResource = ({ active, checkboxHandler }) => {
  return (
    <div className="active-resource">
      <Checkbox checked={active} name="active" onChange={checkboxHandler} />

      {active ? (
        <span className="active-resource__active">Активен</span>
      ) : (
        <span className="active-resource__inactive">Неактивен</span>
      )}
    </div>
  );
};
