import React, { useEffect, useState } from "react";
import { Container, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import { getOrderPicking } from "services/api";
import { MySpinner } from "components/MySpinner";
import upak from "assets/img/upak.svg";

import "./orderPicking.scss";

export const OrdersPickingPage = () => {
  const [orderPicking, setOrderPicking] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAndSetOrderPicking = () => {
    getOrderPicking()
      .then((res) => {
        setOrderPicking(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetOrderPicking();
  }, []);

  return (
    <Container fluid className="p-0 order-picking">
      {loading && <MySpinner overlay />}
      <Card>
        <CardBody>
          <h1 className="h3 mb-3">Заявки на сборку</h1>
        </CardBody>
      </Card>

      <Card>
        <CardBody className="order-picking__wrapper">
          {orderPicking.map((item) => (
            <Link
              className="order-picking__item"
              key={item.id}
              to={`/stock/orderPicking/${item.id}`}
            >
              <div className="order-picking__number">{item.number}</div>
              <div className="order-picking__customer">{item.customer}</div>
              <div className="order-picking__icon">
                <img src={upak} />
              </div>
            </Link>
          ))}
        </CardBody>
      </Card>
    </Container>
  );
};
