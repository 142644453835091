import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat } from "utils/format";
import { useHistory } from "react-router-dom";
import { CheckCircle } from "react-feather";
import { Cut } from "components/Cut";
import user from "assets/img/user.svg";

import "./misgradingsTable.scss";

export const MisgradingsTable = ({ misgradings }) => {
  const history = useHistory();
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      headerStyle: () => {
        return { width: "3%" };
      },
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "createdAt",
      text: "Дата",
      formatter: (cell, row) => dateFormat(cell),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "admin",
      text: "Кто создал",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
    {
      dataField: "reason",
      text: "Причина",
      headerStyle: () => {
        return { width: "15%" };
      },
			formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "comment",
      text: "Коммнтарий",
      headerStyle: () => {
        return { width: "20%" };
      },
			formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/stock/misgradings/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={misgradings}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
