import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Plus } from "react-feather";
import { SelectSearch } from "components/SelectSearch";

import { MySpinner } from "components/MySpinner";
import { brandsSuggestion, createNomenclature } from "services/api";
import { validHandler } from "utils/validation";
import { CloseButton } from "components/CloseButton";

export const CreateNomenclatureModal = ({
  row = null,
  articleHandler = null,
  brandHandler = null,
  nameHandler = null,
  nomenclatureHandler = null,
  callback = null,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState([]);
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState(null);
  const [name, setName] = useState("");
  const [needValid, setNeedValid] = useState(false);

  useEffect(() => {
    if (!row) return;
    setArticle(row.article);
    setBrand({ label: row.brand, value: row.brand });
    setName(row.name);
  }, [row]);

  useEffect(() => {
    const { errors } = validHandler({
      article,
      brand,
      name,
    });
    setErrors(errors);
  }, [article, brand, name]);

  const createNomenclatureHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      article,
      brand,
      name,
    });

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    createNomenclature({
      article,
      brand: brand?.value,
      name,
    })
      .then((res) => {
        setArticle("");
        setBrand("");
        setName("");
        if (articleHandler) {
          articleHandler(res.article);
        }
        if (brandHandler) {
          brandHandler(res.brand);
        }
        if (nameHandler) {
          nameHandler(res.name);
        }
        if (nomenclatureHandler) {
          nomenclatureHandler(res.id);
        }

        setOpen(false);
        setNeedValid(false);
        setError(null);
        if (callback) {
          callback();
        }
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          setError(e.response.data.message);
        } else {
          setError("Ошибка создания номенклатуры");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        <Plus size={14} />
      </Button>
      <Modal isOpen={open} toggle={() => setOpen(false)}>
        {error && <Alert color="danger">{error}</Alert>}
        {loading && <MySpinner overlay />}
        <ModalHeader>
          <div className="modal-title-text">Добавить номенклатуру</div>
          <CloseButton onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label>Артикул</Label>
                <Input
                  name="article"
                  placeholder="Артикул"
                  onChange={(e) => setArticle(e.target.value)}
                  value={article}
                  invalid={needValid && errors.hasOwnProperty("article")}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Бренд</Label>
                <SelectSearch
                  name="brand"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brand}
                  setValue={setBrand}
                  needValid={needValid}
                  errors={errors}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <Label>Наименование</Label>
                <Input
                  name="name"
                  placeholder="Наименование"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  invalid={needValid && errors.hasOwnProperty("name")}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-between">
            <Button onClick={() => setOpen(false)} color="secondary">
              Закрыть
            </Button>
            <Button onClick={createNomenclatureHandler} color="primary">
              Сохранить
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
