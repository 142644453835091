import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useOrders } from "../OrdersContext";
import { providersSuggestion, updateProduct } from "services/api";

export const ChangeProvider = ({ row }) => {
  const { setLoading, setProducts } = useOrders();
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [provider, setProvider] = useState({
    label: row.provider?.name,
    value: row.provider?.id,
  });

  const providerMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name };
    });

  useEffect(() => {
    if (search.length < 3) {
      setSuggestions([]);
      return;
    }
    setSearchLoading(true);
    providersSuggestion(search)
      .then((res) => setSuggestions(providerMap(res)))
      .finally(() => setSearchLoading(false));
  }, [search]);

  const providerHandler = (data) => {
    setProvider(data);
    setLoading(true);
    updateProduct({ ...row, provider: data.value, warehouse: row.warehouse?.id  })
      .then((res) => setProducts(res.products))
      .finally(() => setLoading(false));
  };

  return (
    <Select
      name="provider"
      placeholder="Выберите поставщика"
      className="react-select-container"
      classNamePrefix="react-select"
      options={suggestions}
      isSearchable
      isLoading={searchLoading}
      inputValue={search}
      onInputChange={setSearch}
      value={provider}
      defaultValue={[{ value: "", label: "" }]}
      onChange={providerHandler}
    />
  );
};
