import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat, priceFormat } from "utils/format";

import { Cut } from "components/Cut";

export const AnaliticsPriceTable = ({ analiticsPrice }) => {
  const tableColumns = [
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "18%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "costPrice",
      text: "Себ-ть",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "marketplacePrice",
      text: "Площ-ки",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "price",
      text: "Мин цена 1",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "exist",
      text: "Ост 1",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "provider",
      text: "Поставщик 1",
      headerStyle: () => {
        return { width: "12%" };
      },
      formatter: (cell) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "priceTwo",
      text: "Мин цена 2",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "existTwo",
      text: "Ост 2",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "providerTwo",
      text: "Поставщик 2",
      headerStyle: () => {
        return { width: "12%" };
      },
      formatter: (cell) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "percent",
      text: "Разница",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <>{cell} %</>,
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "createdAt",
      text: "Дата",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell) => dateFormat(cell),
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => {
        return { width: "10%" };
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={analiticsPrice}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
      />
    </>
  );
};
