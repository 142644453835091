import React, { useEffect, useRef, useState } from "react";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import DatePicker, { registerLocale } from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat, priceFormat } from "utils/format";
import { Trash2 } from "react-feather";
import classNames from "classnames";
import { toast } from "react-toastify";

import { Cut } from "components/Cut";

import "react-datepicker/dist/react-datepicker.css";

import { MySpinner } from "components/MySpinner";
import { TableButton } from "components/TableButton";
import {
  removeOrderToProviderProducts,
  supplyFileImportUpload,
  updateOrderToProviderProduct,
} from "services/apiSupply";

import "./ordersToProviderProductTable.scss";
import { BarChart } from "components/BarChart";
import { SkladPopup } from "features/Orders/SkladPopup";
import { SelectDate } from "../SelectDate";

export const OrdersToProviderProductTable = ({
  id,
  products,
  setProducts,
  carriedOut,
  provider,
}) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const inputSupplyFile = useRef(null);
  const [supplyFile, setSupplyFile] = useState(null);
  const [template, setTemplate] = useState(null);

  const removeProductsHandler = (id = null) => {
    if (!window.confirm("Вы действительно хотите удалить выбранные товары?")) {
      return;
    }

    setLoading(true);

    removeOrderToProviderProducts({ ids: id ? [id] : selected })
      .then((res) => {
        setProducts(res.products);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setSelected([]);
      });
  };

  useEffect(() => {
    if (!supplyFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", supplyFile);
    formData.append("id", id || null);
    supplyFileImportUpload(formData)
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => {
        setLoading(false);
        inputSupplyFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyFile]);

  const productHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);

    updateOrderToProviderProduct({
      id: row.id,
      price: row.price,
      count: row.count,
      dateArrival: dateFormat(row.dateArrival),
    })
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => setLoading(false));
  };

  const dateArrivalHandler = (cell, row, dateArrival) => {
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;

    if (regex.test(dateFormat(dateArrival))) {
      row.dateArrival = new Date(dateArrival);
      productHandler(dateFormat(cell), dateFormat(dateArrival), row);
    }
    return;
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(products.map((val) => val.id));
      }
    },
  };

  const importHandler = (val) => {
    setTemplate(val);

    if (inputSupplyFile) {
      inputSupplyFile.current.click();
    }
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      editable: false,
      footer: () => null,
      clickToSelect: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <Cut>{row.nomenclature?.name}</Cut>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => classNames("right bold"),
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => classNames("right", { editable: !carriedOut }),
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
        return total;
      },
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      footerClasses: () => "right bold",
      classes: () => "right bold",
      editable: false,
      footer: (columnData) => {
        const total = columnData.reduce(
          (acc, item) => acc + parseFloat(item),
          0
        );
        return total && priceFormat(total);
      },
    },
    {
      dataField: "marketplacePrice",
      text: "Площадки",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(row.nomenclature?.marketplacePrice),
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "sold",
      text: "Продано",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "inStockAll",
      text: "Ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <div className="warehouse-container">
          {(cell || row.inStockAll) && (
            <SkladPopup row={row}>
              <BarChart
                color={cell > 0 ? "green" : "red"}
                count={row.inStockAll}
              />
            </SkladPopup>
          )}
        </div>
      ),
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "ordered",
      text: "Заказано всего",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "dateArrival",
      text: "Дата поступления",
      formatter: (cell, row) => (
        <SelectDate
          date={cell}
          setDate={(val) => dateArrivalHandler(cell, row, val)}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      footer: () => null,
      editable: false,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <TableButton
          Icon={Trash2}
          onClick={() => removeProductsHandler(row.id)}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      footer: () => null,
      editable: false,
    },
  ];

  return (
    <>
      {loading && <MySpinner overlay />}
      <BootstrapTable
        wrapperClasses="orders-to-provider-product"
        keyField="id"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
        selectRow={selectRow}
      />
    </>
  );
};
