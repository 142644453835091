import axios from "axios";
export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_DEV_URL;

export const baseUrl2 =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL2
    : process.env.REACT_APP_BASE_DEV_URL2;

export const baseUrl3 =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL3
    : process.env.REACT_APP_BASE_DEV_URL;

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" },
});

export const axiosInstance2 = axios.create({
  baseURL: baseUrl2,
  headers: { "Content-Type": "application/json" },
});

export const controller = new AbortController();

axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("accessToken") != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "accessToken"
      )}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (config) {
    return config;
  },
  async (e) => {
    const originalRequest = e.config;
    if (e.response?.status === 401 && e.config && !originalRequest._isRetry) {
      originalRequest._isRetry = true;
      try {
        const data = new URLSearchParams();
        data.append("grantType", "refreshToken");
        data.append("refreshToken", localStorage.getItem("refreshToken"));
        const res = await axios.post(`${baseUrl}/requestAdminToken`, data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        return axiosInstance.request(originalRequest);
      } catch (e) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/sign-in";
      }
    }
    throw e;
  }
);

export const login = async (props) => {
  const data = new URLSearchParams();
  data.append("grantType", "password");
  data.append("username", props.email);
  data.append("password", props.password);

  try {
    const res = await axios.post(`${baseUrl}/requestAdminToken`, data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return res.data;
  } catch (e) {
    console.error(e.message);
    if (e.message === "Network Error") {
      return "Network Error";
    }
    if (e.response) {
      return e.response.status;
    }
  }
};

export const suggestion = async ({ article }) => {
  const params = {
    article,
  };
  const res = await axiosInstance.get("/price.suggestion", { params });
  return res.data;
};

export const priceMailing = async (props) => {
  const res = await axiosInstance.post("/price.mailingCsv", props);
  return res.data;
};

export const suggestionNomenclatrue = async ({ article }) => {
  const params = {
    article,
  };
  const res = await axiosInstance.get("/nomenclature.suggestion", { params });
  return res.data;
};

export const suggestionNomenclatrue2 = async ({ article }) => {
  const res = await axiosInstance2.get(`/v3/suggestion/${article}`);
  return res.data;
};

export const createNomenclature = async (props) => {
  const res = await axiosInstance.post("/nomenclature.create", props);
  return res.data;
};

export const getNomenclature = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  if (props.sort) {
    params["sort[by]"] = props.sort.by;
    params["sort[dir]"] = props.sort.dir;
  }

  const res = await axiosInstance.get("/nomenclature.get", { params });
  return res.data;
};

export const getNomenclatureOne = async (id) => {
  const params = { id };
  const res = await axiosInstance.get("/nomenclature.getOne", { params });
  return res.data;
};

export const nomenclatureAddImages = async (props) => {
  const res = await axiosInstance.post("/nomenclature.addImages", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const nomenclatureRemoveImages = async (props) => {
  const res = await axiosInstance.post("/nomenclature.removeImages", props);
  return res.data;
};

export const updateNomenclature = async (props) => {
  const res = await axiosInstance.post("/nomenclature.update", props);
  return res.data;
};

export const generateBarcode = async (props) => {
  const res = await axiosInstance.post("/nomenclature.generateBarcode", props);
  return res.data;
};

export const removeCellFromNomenclature = async (props) => {
  const res = await axiosInstance.post("/nomenclature.removeCell", props);
  return res.data;
};

export const cellSuggestion = async (search) => {
  const res = await axiosInstance.get(`/api/v2/cell/suggestion/${search}`);
  return res.data;
};

export const addCell = async (props) => {
  const res = await axiosInstance.post("/api/v2/cell", props);
  return res.data;
};

export const importNomenclature = async (props) => {
  const res = await axiosInstance.post("/nomenclature.import", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const addRelatedProduct = async (props) => {
  const res = await axiosInstance.post(
    "/nomenclature.addRelatedProduct",
    props
  );
  return res.data;
};

export const removeRelatedProduct = async (props) => {
  const res = await axiosInstance.post(
    "/nomenclature.removeRelatedProduct",
    props
  );
  return res.data;
};

export const getAvitoAds = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/api/v2/avito", { params });
  return res.data;
};

export const getAvitoAd = async (id) => {
  const res = await axiosInstance.get(`/api/v2/avito/one/${id}`);
  return res.data;
};

export const createAvitoAd = async (props) => {
  const res = await axiosInstance.post("/api/v2/avito", props);
  return res.data;
};

export const updateAvitoAd = async (props) => {
  const res = await axiosInstance.post(`/api/v2/avito/one/${props.id}`, props);
  return res.data;
};

export const bulkAvitoUpdate = async (props) => {
  const res = await axiosInstance.patch(`/api/v2/avito/bulkUpdate`, props);
  return res.data;
};

export const getCharacteristics = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/characteristics.get", { params });
  return res.data;
};

export const getCharacteristicsList = async () => {
  const res = await axiosInstance.get("/characteristics.getList");
  return res.data;
};

export const createCharacteristic = async (props) => {
  const res = await axiosInstance.post("/characteristics.create", props);
  return res.data;
};

export const removeCharacteristic = async (props) => {
  const res = await axiosInstance.post("/characteristics.remove", props);
  return res.data;
};

export const updateCharacteristic = async (props) => {
  const res = await axiosInstance.post("/characteristics.update", props);
  return res.data;
};

export const addCharacteristicValue = async (props) => {
  const res = await axiosInstance.post("/characteristics.addValue", props);
  return res.data;
};

export const updateCharacteristicValue = async (props) => {
  const res = await axiosInstance.post("/characteristics.updateValue", props);
  return res.data;
};

export const removeCharacteristicValue = async (props) => {
  const res = await axiosInstance.post("/characteristics.removeValue", props);
  return res.data;
};

export const searchPrice = async ({ article, brand, provider, customer }) => {
  const params = {
    article,
    brand,
    provider,
    customer,
  };
  const res = await axiosInstance.get("/price.search", {
    signal: controller.signal,
    params,
  });
  return res.data;
};

export const searchPrice2 = async ({ article, brand = null, userId, provider, adminId }) => {
  const url = brand
    ? `/v3/search/${article}/${brand}`
    : `/v3/search/${article}`;

  const res = await axiosInstance2.get(url, {
    params: { uid: userId, adminId, provider },
  });
  return res.data;
};

export const createProduct = async (props) => {
  const res = await axiosInstance.post("/price.createProduct", props);
  return res.data;
};

export const getEstimateFromFile = async ({ fileName }) => {
  const params = {
    fileName,
  };
  const res = await axiosInstance.get("/price.getEstimateFromFile", { params });
  return res.data;
};

export const clarificationBrand = async (article) => {
  const params = {
    article,
  };
  const res = await axiosInstance.get("/price.clarificationBrand", { params });
  return res.data;
};

export const estimateFileUpload = async (props) => {
  const res = await axiosInstance.post("/price.estimateUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const orderFileUpload = async (props) => {
  const res = await axiosInstance.post("/orders.orderUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const cartFileUpload = async (props) => {
  const res = await axiosInstance.post("/cart.orderUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const cartAdd = async (props) => {
  const res = await axiosInstance.post("/cart.add", props);
  return res.data;
};

export const cartUpdate = async (props) => {
  const res = await axiosInstance.post("/cart.update", props);
  return res.data;
};

export const updatePriceCartByCustomer = async (props) => {
  const res = await axiosInstance.post("/cart.updatePriceByCustomer", props);
  return res.data;
};

export const cartClean = async (props) => {
  const res = await axiosInstance.post("/cart.clean", props);
  return res.data;
};

export const cartGet = async () => {
  const res = await axiosInstance.get("/cart.get");
  return res.data;
};

export const createOrder = async (props) => {
  const res = await axiosInstance.post("/orders.create", props);
  return res.data;
};

export const carryOutOrder = async (props) => {
  const res = await axiosInstance.post("/orders.carryOut", props);
  return res.data;
};

export const copyOrder = async (props) => {
  const res = await axiosInstance.post("/orders.copy", props);
  return res.data;
};

export const getReturnsCustomer = async ({
  paginationCount,
  paginationOffset,
  search,
  number,
  provider,
  supply,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
    search,
    number,
    provider,
    supply,
  };

  const res = await axiosInstance.get("/returnsFromCustomer.get", { params });
  return res.data;
};

export const getReturnCustomerOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/returnsFromCustomer.getOne", {
    params,
  });
  return res.data;
};

export const createReturnCustomer = async (props) => {
  const res = await axiosInstance.post("/returnsFromCustomer.create", props);
  return res.data;
};

export const carryOutReturnCustomer = async (props) => {
  const res = await axiosInstance.post("/returnsFromCustomer.carryOut", props);
  return res.data;
};

export const userSuggestion = async (search) => {
  const res = await axiosInstance.get(`/api/v2/customers/suggestion/${search}`);
  return res.data;
};

export const getAddresses = async (customer) => {
  const params = {
    customer,
  };
  const res = await axiosInstance.get("/addresses.get", { params });
  return res.data;
};

export const addAddress = async (props) => {
  const res = await axiosInstance.post("/addresses.add", props);
  return res.data;
};

export const updateAddress = async (props) => {
  const res = await axiosInstance.post("/addresses.update", props);
  return res.data;
};

export const setDefaultAddress = async (props) => {
  const res = await axiosInstance.post("/addresses.setDefault", props);
  return res.data;
};

export const getDefaultAddress = async (customer) => {
  const params = {
    customer,
  };
  const res = await axiosInstance.get("/addresses.getDefault", { params });
  return res.data;
};

export const removeAddress = async (props) => {
  const res = await axiosInstance.post("/addresses.remove", props);
  return res.data;
};

export const getCustomers = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  if (props.sort) {
    params["sort[by]"] = props.sort?.by;
    params["sort[dir]"] = props.sort?.dir;
  }

  const res = await axiosInstance.get("/api/v2/customers", { params });
  return res.data;
};

export const getUser = async (id) => {
  const res = await axiosInstance.get(`/api/v2/customers/${id}`);
  return res.data;
};

export const getCurrentUser = async () => {
  const res = await axiosInstance.get("/users.getCurrent");
  return res.data;
};

export const updateCustomer = async (props) => {
  const res = await axiosInstance.patch(`/api/v2/customers/${props.id}`, props);
  return res.data;
};

export const createCustomer = async (props) => {
  const res = await axiosInstance.post("/api/v2/customers", props);
  return res.data;
};

export const getAccount = async (id) => {
  const params = {
    id
  };
  const res = await axiosInstance.get("/users.getAccount", { params });
  return res.data;
};

export const getAccounts = async (id) => {
  const params = {
    id
  };
  const res = await axiosInstance.get("/users.getAccounts", { params });
  return res.data;
};

export const updateAccount = async (props) => {
  const res = await axiosInstance.post("/users.updateAccount", props);
  return res.data;
};

export const createAccount = async (props) => {
  const res = await axiosInstance.post("/users.createAccount", props);
  return res.data;
};

export const orgSuggestion = async (search, validation) => {
  const params = {
    search,
    validation
  };
  const res = await axiosInstance.get("/users.orgSuggestion", { params });
  return res.data;
};

export const bankSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/users.bankSuggestion", { params });
  return res.data;
};

export const getUsersToExcel = async (props) => {
  const res = await axiosInstance.post("/users.getUsersToExcel", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getUsers = async (customer) => {
  const params = {
    customer,
  };
  const res = await axiosInstance.get("/api/v2/contract", { params });
  return res.data;
};


export const getContracts = async (customer) => {
  const params = {
    customer,
  };
  const res = await axiosInstance.get("/api/v2/contract", { params });
  return res.data;
};

export const createContract = async (props) => {
  const res = await axiosInstance.post("/api/v2/contract", props);
  return res.data;
};

export const removeContract = async (props) => {
  const res = await axiosInstance.delete(`/api/v2/contract/${props.id}`, props);
  return res.data;
};

export const updateContract = async (props) => {
  const res = await axiosInstance.patch(`/api/v2/contract/${props.id}`, props);
  return res.data;
};

export const createOrUpdateContract = async (props) => {
  if (props.id) {
    const res = await axiosInstance.patch(`/api/v2/contract/${props.id}`, props);
    return res.data;
  }
  const res = await axiosInstance.post("/api/v2/contract", props);
  return res.data;
};

export const setDefaultContract = async (props) => {
  const res = await axiosInstance.post(`api/v2/contract/setDefault/${props.id}`, props);
  return res.data;
};

export const getToWordContract = async (props) => {
  const res = await axiosInstance.post(`/api/v2/contract/getToWord/${props.id}`, props, {
    responseType: "blob",
  });
  return res.data;
};

export const downloadContractsToExcel = async () => {
  const res = await axiosInstance.get("/api/v2/contract/downloadToExcel", {
    responseType: "blob",
  });
  return res.data;
};

export const uploadContract = async (id, props) => {
  const res = await axiosInstance.post(`/api/v2/contract/upload/${id}`, props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const getToWordAdditionalAgreement = async (props) => {
  const res = await axiosInstance.post(`/api/v2/additionalAgreement/getToWord/${props.contractId}`, props, {
    responseType: "blob",
  });
  return res.data;
};

export const uploadAdditionalAgreement = async (idAdditionalAgreement, id, props) => {
  const res = await axiosInstance.post(`/api/v2/additionalAgreement/upload/${id}/${idAdditionalAgreement}`, props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const getProviders = async ({
  paginationCount,
  paginationOffset,
  search,
  price,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }
  if (price) {
    params.price = price;
  }
  const res = await axiosInstance.get("/providers.get", { params });
  return res.data;
};

export const updateProvider = async (props) => {
  const res = await axiosInstance.post("/providers.update", props);
  return res.data;
};

export const createProvider = async (props) => {
  const res = await axiosInstance.post("/providers.create", props);
  return res.data;
};

export const updatePassword = async (props) => {
  const res = await axiosInstance.post("/users.updatePassword", props);
  return res.data;
};

export const getOrders = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/orders.get", { params });
  return res.data;
};

export const getOrder = async (id = null, number = null) => {
  const params = {
    id,
    number
  };
  const res = await axiosInstance.get("/orders.getOne", { params });
  return res.data;
};

export const getOrderByNumber = async (number) => {
  const params = {
    number,
  };
  const res = await axiosInstance.get("/orders.getOneByNumber", { params });
  return res.data;
};

export const getStatuses = async () => {
  const res = await axiosInstance.get("/orders.getStatuses");
  return res.data;
};

export const setStatus = async (props) => {
  const res = await axiosInstance.post("/orders.setStatus", props);
  return res.data;
};

export const updateOrder = async (props) => {
  const res = await axiosInstance.post("/orders.updateOrder", props);
  return res.data;
};

export const createReserve = async (props) => {
  const res = await axiosInstance.post("/orders.createReserve", props);
  return res.data;
};

export const removeFromReserve = async (props) => {
  const res = await axiosInstance.post("/orders.removeFromReserve", props);
  return res.data;
};

export const removeProduct = async (props) => {
  const res = await axiosInstance.post("/orders.removeProduct", props);
  return res.data;
};

export const removeProducts = async (props) => {
  const res = await axiosInstance.post("/orders.removeProducts", props);
  return res.data;
};

export const addProduct = async (props) => {
  const res = await axiosInstance.post("/orders.addProduct", props);
  return res.data;
};

export const moveProduct = async (props) => {
  const res = await axiosInstance.post("/orders.moveProduct", props);
  return res.data;
};

export const updateProduct = async (props) => {
  const res = await axiosInstance.post("/orders.updateProduct", props);
  return res.data;
};

export const sendToDiadoc = async (props) => {
  const res = await axiosInstance.post("/orders.sendToDiadoc", props);
  return res.data;
};

export const sendTo1S = async (props) => {
  const res = await axiosInstance.post("/orders.sendTo1S", props);
  return res.data;
};

export const getGtd = async (props) => {
  const res = await axiosInstance.post("/orders.getGtd", props);
  return res.data;
};

export const getUpd = async (props) => {
  const res = await axiosInstance.post("/orders.getUpd", props, {
    responseType: props.json ? "json" : "blob",
  });
  return res.data;
};

export const getCheck = async (props) => {
  const res = await axiosInstance.post("/orders.getCheck", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getInvoice = async (props) => {
  const res = await axiosInstance.post("/orders.getInvoice", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getOffer = async (props) => {
  const res = await axiosInstance.post("/orders.getOffer", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getTorg1T = async (props) => {
  const res = await axiosInstance.post("/orders.getTorg1T", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getOrdersToExcel = async (props) => {
  const res = await axiosInstance.post("/orders.getToExcel", props, {
    responseType: "blob",
  });
  return res.data;
};

export const sendInvoice = async (props) => {
  const res = await axiosInstance.post("/orders.sendInvoice", props);
  return res.data;
};

export const sendUtd = async (props) => {
  const res = await axiosInstance.post("/orders.sendUtd", props);
  return res.data;
};

export const sendToDiadocFromUpd = async (props) => {
  const res = await axiosInstance.post("/orders.sendToDiadocFromUpd", props);
  return res.data;
};

export const updFileUpload = async (props) => {
  const res = await axiosInstance.post("/orders.updFileUpload", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const addBonus = async (props) => {
  const res = await axiosInstance.post("/orders.setBonus", props);
  return res.data;
};

export const updateComment = async (props) => {
  const res = await axiosInstance.post("/orders.updateComment", props);
  return res.data;
};

export const getReportChangedOrders = async (props) => {
  const res = await axiosInstance.post("/orders.getReportChanged", props, {
    responseType: "blob",
  });
  return res.data;
};

export const changeWarehouse = async (props) => {
  const res = await axiosInstance.post("/orders.productChangeWarehouse", props);
  return res.data;
};

export const updateDeliveryType = async (props) => {
  const res = await axiosInstance.post("/orders.updateDeliveryType", props);
  return res.data;
};

export const updateOrderAddress = async (props) => {
  const res = await axiosInstance.post("/orders.updateAddress", props);
  return res.data;
};

export const updateDateDelivery = async (props) => {
  const res = await axiosInstance.post("/orders.updateDateDelivery", props);
  return res.data;
};

export const mergeOrders = async (props) => {
  const res = await axiosInstance.post("/orders.mergeOrders", props);
  return res.data;
};

export const getOrderPicking = async (number) => {
  const params = {
    number,
  };
  const res = await axiosInstance.get("/orderPicking.get", { params });
  return res.data;
};

export const endPicking = async (props) => {
  const res = await axiosInstance.post("/orderPicking.end", props);
  return res.data;
};

export const getChangeLogOrder = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/changeLog.getOrder", { params });
  return res.data;
};

export const getChangeLogSupply = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/changeLog.getSupply", { params });
  return res.data;
};

export const getChangeLogNomenclature = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/changeLog.getNomenclature", { params });
  return res.data;
};

export const updConvertUpload = async (props) => {
  const res = await axiosInstance.post("/updConvert.upload", props);
  return res.data;
};

export const updConvertUpdateData = async (props) => {
  const res = await axiosInstance.post("/updConvert.update", props);
  return res.data;
};

export const updConvertDownload = async (props) => {
  const res = await axiosInstance.post("/updConvert.get", props, {
    headers: { "Content-Type": "multipart/form-data" },
    responseType: "blob",
  });
  return res.data;
};

export const providersSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/providers.suggestion", { params });
  return res.data;
};

export const getProvider = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/providers.getOne", { params });
  return res.data;
};

export const getBrands = async ({
  paginationCount,
  paginationOffset,
  search,
  sort,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  if (sort) {
    params["sort[by]"] = sort.by;
    params["sort[dir]"] = sort.dir;
  }

  const res = await axiosInstance.get("/brands.get", { params });
  return res.data;
};

export const createBrand = async (props) => {
  const res = await axiosInstance.post("/brands.createBrand", props);
  return res.data;
};

export const updateBrand = async (props) => {
  const res = await axiosInstance.post("/brands.updateBrand", props);
  return res.data;
};

export const setBrand = async (props) => {
  const res = await axiosInstance.post("/nomenclature.setBrand", props);
  return res.data;
};

export const getBrandSynonyms = async ({
  paginationCount,
  paginationOffset,
  search,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  const res = await axiosInstance.get("/brands.getSynonyms", { params });
  return res.data;
};

export const updateSynonym = async (props) => {
  const res = await axiosInstance.post("/brands.updateSynonym", props);
  return res.data;
};

export const createSynonym = async (props) => {
  const res = await axiosInstance.post("/brands.createSynonym", props);
  return res.data;
};

export const removeSynonym = async (props) => {
  const res = await axiosInstance.post("/brands.removeSynonym", props);
  return res.data;
};

export const brandsSuggestion = async (search) => {
  const params = {
    search,
  };
  const res = await axiosInstance.get("/brands.suggestion", { params });
  return res.data;
};

export const getAdminsForSelect = async (props) => {
  const res = await axiosInstance.get("/admins.getForSelect", {
    params: props,
  });
  return res.data;
};

export const getAdmins = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/admins.get", { params });
  return res.data;
};

export const getAdminOne = async (id) => {
  const res = await axiosInstance.get("/admins.getOne", { params: { id } });
  return res.data;
};

export const createAdmin = async (props) => {
  const res = await axiosInstance.post("/admins.create", props);
  return res.data;
};

export const updateAdmin = async (props) => {
  const res = await axiosInstance.post("/admins.update", props);
  return res.data;
};

export const updateAdminPassword = async (props) => {
  const res = await axiosInstance.post("/admins.updatePassword", props);
  return res.data;
};

export const updateAdminSettings = async (props) => {
  const res = await axiosInstance.post("/admins.setSettings", props);
  return res.data;
};

export const getPriceRequestLog = async ({
  paginationCount,
  paginationOffset,
  search,
  sort,
}) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  if (search) {
    params.search = search;
  }

  if (sort) {
    params.sort = sort;
  }

  const res = await axiosInstance.get("/priceRequestLog.get", { params });
  return res.data;
};

export const getCross = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  if (props?.sort) {
    params["sort[by]"] = props?.sort.by;
    params["sort[dir]"] = props?.sort.dir;
  }

  const res = await axiosInstance.get("/cross.get", { params });
  return res.data;
};

export const updateCross = async (props) => {
  const res = await axiosInstance.post("/cross.update", props);
  return res.data;
};

export const uploadCross = async (props) => {
  const res = await axiosInstance.post("/cross.uploadFile", props, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data;
};

export const getCrossFiles = async ({ paginationCount, paginationOffset }) => {
  const params = {
    "pagination[count]": paginationCount,
    "pagination[offset]": paginationOffset,
  };

  const res = await axiosInstance.get("/cross.getFiles", { params });
  return res.data;
};

export const getCrossByArticleBrand = async (props) => {
  const res = await axiosInstance.get("/cross.getByArticleBrand", {
    params: props,
  });
  return res.data;
};

export const removeCrossFile = async (props) => {
  const res = await axiosInstance.post("/cross.removeFile", props);
  return res.data;
};

export const removeCross = async (props) => {
  const res = await axiosInstance.post("/cross.remove", props);
  return res.data;
};

export const createCross = async (props) => {
  const res = await axiosInstance.post("/cross.create", props);
  return res.data;
};

export const getDelivery = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/api/v2/delivery", { params });
  return res.data;
};

export const updateDelivery = async (props) => {
  const res = await axiosInstance.post(`/api/v2/delivery/${props.id}`, props);
  return res.data;
};

export const getDeliveryToExcel = async () => {
  const res = await axiosInstance.post("/api/v2/delivery/excel", null, {
    responseType: "blob",
  });
  return res.data;
};

export const getBalance = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/balance.get", { params });
  return res.data;
};

export const getMarkups = async (props) => {
  const params = {
    ...props
  };

  const res = await axiosInstance.get("/api/v2/markup", { params });
  return res.data;
};

export const getOneMarkup = async (id) => {
  const res = await axiosInstance.get(`/api/v2/markup/${id}`);
  return res.data;
};

export const createMarkup = async (props) => {
  const res = await axiosInstance.post(`/api/v2/markup`, props);
  return res.data;
};

export const updateMarkup = async (props) => {
  const res = await axiosInstance.patch(`/api/v2/markup/${props.id}`, props);
  return res.data;
};

export const getSendingPriceList = async (props) => {
  const params = {
    ...props
  };

  const res = await axiosInstance.get("/api/v2/sendingPriceList", { params });
  return res.data;
};

export const getSendingPriceListOne = async (id) => {
  const res = await axiosInstance.get(`/api/v2/sendingPriceList/${id}`);
  return res.data;
};

export const createOrUpdateSendingPriceList = async (props) => {
  if (props.id) {
    const res = await axiosInstance.patch(`/api/v2/sendingPriceList/${props.id}`, props);
    return res.data;
  }
  const res = await axiosInstance.post(`/api/v2/sendingPriceList`, props);
  return res.data;
};

export const updateSendingPriceList = async (props) => {
  const res = await axiosInstance.patch(`/api/v2/sendingPriceList/${props.id}`, props);
  return res.data;
};

export const updateSettings = async (props) => {
  const res = await axiosInstance.patch(`/api/v2/branch/${props.index}`, props);
  return res.data;
};

export const getSettings = async (index) => {
  const res = await axiosInstance.get(`/api/v2/branch/${index}`);
  return res.data;
};

