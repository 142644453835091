
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row } from "reactstrap";
import { Formik } from "formik";
import { number, object, string, setLocale } from "yup";

import { locale } from "utils/validationLocale";
import { MySpinner } from "components/MySpinner";
import { createMarkup, getOneMarkup, updateMarkup } from "services/api";
import { Helmet } from "react-helmet";
import { HeaderPage } from "components/HeaderPage";
import { ActiveResource } from "components/ActiveResource";
import { toast } from "react-toastify";
import { Checkbox } from "components/Checkbox";
import { priceFormat } from "utils/format";
import { Trash } from "react-feather";
import { TableButton } from "components/TableButton";

setLocale(locale);

const MarkupSchema = object().shape({
  name: string().min(2).required()
});

export const MarkupOne = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [markup, setMarkup] = useState([]);
  const [newMarkups, setNewMarkups] = useState([]);

  const getAndSetMarkup = () => {
    getOneMarkup(id)
      .then((res) => setMarkup(res))
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetMarkup();


    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (values) => {
    setLoading(true);

    const newMarkup = { ...values.markup };

    if (!newMarkup.list) {
      newMarkup.list = {};
    }

    for (const [price, percent] of newMarkups) {
      newMarkup.list = { ...newMarkup.list, [price]: parseInt(percent) };
    }

    if (values.id) {
      updateMarkup({ ...values, markup: newMarkup })
        .then((res) => {
          setMarkup(res);
          toast.success("Шаблон наценки успешно сохранен");
          setNewMarkups([]);
        })
        .catch((e) => {
          if (e.response?.status) {
            toast.error(e.response?.data?.detail);
          } else {
            toast.error(e.response?.data?.message);
          }

        })
        .finally(() => setLoading(false));
      return;
    }

    createMarkup({ ...values, markup: newMarkup })
      .then((res) => {
        setMarkup(res);
        toast.success("Шаблон наценки успешно создан");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const addNewMarkups = () => {
    setNewMarkups(prev => [...prev, ["", ""]]);
  }

  return <Container fluid className="p-0 markup">
    {loading && <MySpinner overlay />}

    <Helmet>
      <title>Шаблон наценки {markup.name || "новый"}</title>
    </Helmet>

    <Formik
      initialValues={{
        id: markup?.id || "",
        name: markup?.name || "",
        active: markup?.active === "0" ? false : true,
        markup: markup?.markup || null,
        fullPrice: markup?.markup?.full_price || false,
        percent: markup?.markup?.percent || 0,
      }}
      validationSchema={MarkupSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        submitHandler(values, resetForm);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>

          <HeaderPage title={`Шаблон наценки ${values.name || "новый"}`}>
            <ActiveResource
              active={values.active}
              checkboxHandler={handleChange}
            />
          </HeaderPage>

          {loading && <MySpinner overlay />}

          <Card className="main-form mb-0">
            <CardBody>
              <Row>
                <Col>
                  <Label>Наименование шаблона</Label>
                  <Input
                    id="name"
                    label="Наименование шаблона"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    disabled={values.name === "Default"}
                    invalid={
                      errors.name && touched.name
                        ? errors.name
                        : null
                    }
                  />
                </Col>
              </Row>

              {values.fullPrice ?
                <Row>
                  <Col>
                    <Label>Наценка</Label>
                    <Input
                      id="percent"
                      label="Наценка"
                      onChange={(e) => {
                        setFieldValue("markup", { percent: parseInt(e.target.value) })
                      }}
                      value={values.markup?.percent}
                      invalid={
                        errors.percent && touched.percent
                          ? errors.percent
                          : null
                      }
                    />
                  </Col>
                </Row>
                :
                <Row>
                  <Col>

                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Цена, р</th>
                          <th>Наценка, %</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>

                        {values.markup?.list && Object.entries(values.markup.list).map(([price, percent]) => <tr key={price}>
                          <td>до {priceFormat(price)}</td>
                          <td>
                            <Input
                              type="number"
                              label="Процент"
                              onChange={(e) => {
                                setFieldValue("markup", { ...values.markup, list: { ...values.markup.list, [price]: parseInt(e.target.value) } })
                              }}
                              value={percent}
                            />
                          </td>
                          <td>
                            <TableButton
                              onClick={() => {
                                delete values.markup?.list[price];
                                setFieldValue("markup", values.markup);
                              }}
                              Icon={Trash}
                            />
                          </td>
                        </tr>
                        )}

                        {newMarkups.map((val, i) => <tr key={i}>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="mr-1">до</span> <Input
                                type="number"
                                id="percent"
                                label="Процент"
                                onChange={(e) => setNewMarkups(prev => {
                                  const newValue = [...prev];
                                  newValue[i][0] = e.target.value;
                                  return newValue;
                                })}
                                value={newMarkups[i][0]}
                              />
                            </div>
                          </td>
                          <td>
                            <Input
                              type="number"
                              id="percent"
                              label="Процент"
                              onChange={(e) => setNewMarkups(prev => {
                                const newValue = [...prev];
                                newValue[i][1] = e.target.value;
                                return newValue;
                              })}
                              value={newMarkups[i][1]}
                            />
                          </td>
                          <td>
                            <TableButton
                              onClick={() => {
                                setNewMarkups(prev => {
                                  const newValue = [...prev];
                                  newValue.splice(
                                    i,
                                    1
                                  );
                                  return newValue;
                                });
                              }}
                              Icon={Trash}
                            />
                          </td>
                        </tr>)}

                        <tr>
                          <td>{(Object.keys(values.markup?.list || {}).length > 0 || newMarkups.length > 0) ? "... и выше" : "Единая наценка"}</td>
                          <td>
                            <Input
                              type="number"
                              label="Процент"
                              onChange={(e) => {
                                setFieldValue("markup", { ...values.markup, "~": parseInt(e.target.value) })
                              }}
                              value={values.markup ? values.markup["~"] : ""}
                            />
                          </td>
                          <td></td>
                        </tr>

                      </tbody>
                    </table>
                    <Row>
                      <Col>
                        <Button color="primary" onClick={() => addNewMarkups()}>
                          Добавить
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

              <div className="d-flex justify-content-end mt-3">
                <Button type="submit" color="primary">
                  Сохранить шаблон
                </Button>
              </div>
            </CardBody>
          </Card>

        </form>

      )}
    </Formik>

  </Container>
}