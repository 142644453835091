import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { getRelatedDocuments } from "services/apiWarehouse";
import { toast } from "react-toastify";
import { dateFormat } from "utils/format";

export const GetRelatedDocumentsModal = ({
  openModal,
  setOpenModal,
  row,
  setStockId,
  setOpenModalResetDocuments,
  warehouse,
  nomenclature,
}) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAndSetRelatedDocuments = () => {
    setLoading(true);
    getRelatedDocuments({
      id: row.id,
      warehouse,
      nomenclature: null,
    })
      .then((res) => setDocuments(res))
      .catch((e) => toast.error(e.responce?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (openModal === false) return;
    getAndSetRelatedDocuments();
  }, [openModal]);

  const summ = () => {
    return documents.reduce((acc, val) => acc + val.count, 0);
  };

  const openModalHandler = (id) => {
    setOpenModalResetDocuments(true);
    setStockId(id);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">
          Связанные документы
          <span className="modal-title-small">ID: {row.id}</span>
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        {loading && <MySpinner overlay />}

        <Button onClick={getAndSetRelatedDocuments}>Обновить</Button>

        {documents.map((val) => (
          <Row key={val.id}>
            <Col lg="3">{val.document}</Col>
            <Col lg="2">{dateFormat(val.date)}</Col>
            <Col lg="1">{val.count}</Col>
            <Col lg="6">
              <span
                className="link"
                onClick={() => openModalHandler(val.stockId)}
              >
                {val.stockId}
              </span>
            </Col>
          </Row>
        ))}
        <Row>
          <Col lg="5">Итого: </Col>
          <Col lg="7">{summ()}</Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
