import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { PriceListsTable } from "features/PriceLists/PriceListsTable";
import { getPriceLists, updatePriceList } from "services/apiSupply";

import "./priceLists.scss";

export const PriceListsPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [priceLists, setPriceLists] = useState([]);
  const [priceListsCount, setPriceListsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [id, setId] = useState(null);

  const getAndSetPriceLists = () => {
    getPriceLists({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
    })
      .then((res) => {
        setPriceLists(res.items);
        setPriceListsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search]);

  useEffect(() => {
    setLoading(true);
    getAndSetPriceLists();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetPriceLists();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const searchHandler = () => {
    if (!search) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetPriceLists();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const isSearch = () => {
    if (search) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
  };

  const setForUpdatePriceList = (id) => {
    setLoading(true);
    updatePriceList({
      id,
      forUpdate: true
    })
      .then((res) => {
        getAndSetPriceLists();
        toast.success("Прайс лист отправлен на изменение");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <Container fluid className="p-0 priceLists">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Прайс листы</h1>
          <Form className="mb-3">
            <Row>
              <Col md="4">
                <Label>Поиск по наименованию, email</Label>
                <Input
                  name="search"
                  placeholder="По наименованию, email"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
          <Row className="mb-2">
            <Col md="2">
              <Link
                to={`/receipt/priceLists/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить прайс
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <PriceListsTable
        priceLists={priceLists}
        setForUpdatePriceList={setForUpdatePriceList}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={priceListsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
