import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { validHandler } from "utils/validation";
import { CloseButton } from "components/CloseButton";

export const AddProductModal = ({ openModal, setOpenModal, setProducts }) => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [count, setCount] = useState("");
  const [nomenclature, setNomenclature] = useState(null);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);
    }
  };

  const addProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      nomenclature,
      count,
    });

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    setProducts((products) => [
      ...products,
      {
        index: products.length + 1,
        nomenclature: { id: nomenclature, article, brand, name },
        count: parseInt(count),
      },
    ]);

    setNomenclature(null);
    setArticle("");
    setBrand("");
    setName("");
    setCount("");

    setLoading(false);
    setNeedValid(false);
    setOpenModal(false);

    // addProduct({
    //   nomenclature,
    //   count,
    // })
    //   .then((res) => {
    //     setArticle("");
    //     setBrand("");
    //     setName("");
    //     setCount("");
    //   })
    //   .catch((e) => console.error(e))
    //   .finally(() => {
    //     setLoading(false);
    //     setNeedValid(false);
    //     setOpenModal(false);
    //   });
  };

  useEffect(() => {
    const { errors } = validHandler({
      nomenclature,
      count,
    });
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, name, count]);

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Добавить товар в перемещение</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Form>
          <Row>
            <Col lg="6">
              <Label>Артикул</Label>
              <AutocompleteArticle
                article={article}
                brand={brand}
                setArticle={setArticle}
                setBrand={({ id, name }) => setBrand(name)}
                setName={setName}
                setNomenclature={setNomenclature}
                needValid={needValid}
                errors={errors}
              />
            </Col>
            <Col lg="6">
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-3">
            <Button onClick={() => setOpenModal(false)} color="secondary">
              Закрыть
            </Button>
            <Button onClick={addProductHandler} color="primary">
              Добавить
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};
