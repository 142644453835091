import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { object, string, setLocale } from "yup";
import InputMask from "react-input-mask";

import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  createAccount,
  getAccount,
  updateAccount
} from "services/api";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { locale } from "utils/validationLocale";
import { Checkbox } from "components/Checkbox";

import { Password } from "../Password";

const AccountSchema = object().shape({
  title: string().min(2).required(),
  firstName: string().min(2).required(),
  email: string().email().required(),
  phone: string().required(),
});

setLocale(locale);

export const AccountModal = ({
  openModal,
  setOpenModal,
  getAndSetCustomer,
  customer,
  accountId,
  setAccountId
}) => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    if (!accountId) {
      setAccount(null);
      return;
    }
    setLoading(true);
    getAccount(accountId)
      .then((res) => {
        setAccount(res);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }, [accountId]);

  useEffect(() => {
    return () => {
      setAccountId(null);
    }
  }, []);

  const submitHandler = (values, resetForm) => {
    setLoading(true);

    if (!values?.id) {
      createAccount(values)
        .then((res) => {
          getAndSetCustomer();
          toast.success("Аккаунт успешно добавлен");
          resetForm();
          setOpenModal(false);
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      updateAccount(values)
        .then((res) => {
          getAndSetCustomer();
          toast.success("Аккаунт успешно добавлен");
          resetForm();
          setOpenModal(false);
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    }


  };

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="admin-modal"
    >
      {loading && <MySpinner overlay />}
      <ModalHeader>
        <div className="modal-title-text">
          {account ? <>{account?.title} ({account?.lastName} {account?.firstName})</> : "Новое подразделение"}
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Formik
          initialValues={{
            id: account?.id || "",
            title: account?.title || "",
            customer: customer?.id || "",
            firstName: account?.firstName || "",
            lastName: account?.lastName || "",
            middleName: account?.middleName || "",
            email: account?.email || "",
            phone: account?.phone || "",
            orgKpp: account?.orgKpp || "",
            consigneeName: account?.consigneeName || "",
            consigneeAddress: account?.consigneeAddress || "",
            password: account?.password || "",
            active: account?.active || false,
            queryLimit: account?.queryLimit || 0,
          }}
          validationSchema={AccountSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            submitHandler(values, resetForm);
            setSubmitting(false);
          }}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {account?.id && (
                <Row>
                  <Col md="4">
                    <Label>Активен</Label>
                    <Checkbox
                      id="accountActive"
                      name="active"
                      value="active"
                      checked={values.active}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md="4">
                  <Label>Название</Label>
                  <Input
                    id="title"
                    label="Название"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    invalid={
                      errors.title && touched.title
                        ? errors.title
                        : null
                    }
                  />
                </Col>

              </Row>
              <Row>
                <Col md="4">
                  <Label>Фамилия</Label>
                  <Input
                    id="lastName"
                    label="Фамилия"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    invalid={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : null
                    }
                  />
                </Col>
                <Col md="4">
                  <Label>Имя</Label>
                  <Input
                    id="firstName"
                    label="Имя"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    invalid={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : null
                    }
                  />
                </Col>
                <Col md="4">
                  <Label>Отчество</Label>
                  <Input
                    id="middleName"
                    label="Отчество"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middleName}
                    invalid={
                      errors.middleName && touched.middleName
                        ? errors.middleName
                        : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>Email</Label>
                  <Input
                    id="email"
                    type="email"
                    label="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    invalid={
                      errors.email && touched.email ? errors.email : null
                    }
                  />
                </Col>
                <Col md="6">
                  <Label>Телефон</Label>
                  <InputMask
                    id="phone"
                    name="phone"
                    type="tel"
                    placeholder="Телефон"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    mask={"+7(999) 999-99-99"}
                    className="form-control"
                    invalid={
                      errors.phone && touched.phone ? true : false
                    }
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>КПП</Label>
                  <Input
                    id="orgKpp"
                    label="КПП"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.orgKpp}
                    invalid={
                      errors.orgKpp && touched.orgKpp ? errors.orgKpp : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Наименование грузополучателя</Label>
                  <Input
                    id="consigneeName"
                    label="Наименование грузополучателя"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.consigneeName}
                    invalid={
                      errors.consigneeName && touched.consigneeName ? errors.consigneeName : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Адрес грузополучателя</Label>
                  <Input
                    id="consigneeAddress"
                    label="Адрес грузополучателя"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.consigneeAddress}
                    invalid={
                      errors.consigneeAddress && touched.consigneeAddress ? errors.consigneeAddress : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Лимит запросов артикулов</Label>
                  <Input
                    id="queryLimit"
                    label="Лимит запросов артикулов"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.queryLimit}
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-end mt-3">
                <Button type="submit" color="primary">
                  Сохранить
                </Button>
              </div>
            </form>
          )}
        </Formik>
        {account?.id && (
          <Password user={account} />
        )}
      </ModalBody>
    </Modal>
  );
};
