import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getOrders, mergeOrders } from "services/api";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { priceFormat } from "utils/format";
import { TableButton } from "components/TableButton";
import { Trash } from "react-feather";

export const CopyOrderModal = ({
  ids,
  openModal,
  setOpenModal,
  getAndSetOrders,
  setSelected,
  setSelectType
}) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [selectOrder, setSelectOrder] = useState(false);

  const getAndSetSelectedOrders = () => {
    setLoading(true);
    getOrders({
      ids
    })
      .then((res) => {

        for (const order of res.items) {
          if (["sold", "canceled", "assembly", "readyDelivery"].includes(order.status.alias)) {
            toast.error(`Нельзя выбрать заказ со статусом ${order.status.name}`);
            return;
          }
        }

        if (!selectOrder) {
          setOrders(res.items);
          setSelectOrder(true);
        } else {
          setOrder(res.items.length > 0 ? res.items[0] : null);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (ids.length <= 0) return;
    getAndSetSelectedOrders();
  }, [openModal]);

  const selectOrderHandler = () => {
    setOpenModal(false);
    setSelected([]);
    setSelectType("radio");
  };

  const mergeOrderHandler = () => {
    if (!order) return;
    setLoading(true);
    mergeOrders({
      id: order.id,
      orderIds: orders.map(order => order.id)
    })
      .then((res) => {
        toast.success("Заказы успешно обьединены");
        getAndSetOrders();
        setSelected([]);
        setOrder(null);
        setOrders([]);
        setOpenModal(false);
        setSelectType(null);
        setSelectOrder(false);
      })
      .catch((e) => toast.error(e.response?.data?.message));
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Объединить заказы</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        {loading && <MySpinner overlay />}
        <Form>

          <Row>
            <table className="table table-hover">
              <tbody>
                {orders.map((order, i) => (
                  <tr key={order.id}>
                    <td>{order.number}</td>
                    <td>
                      {order.customer}
                    </td>
                    <td>
                      {order.status.name}
                    </td>
                    <td>
                      {order.admin}
                    </td>
                    <td>
                      <b>{order.count}</b>{' '}
                      на сумму{' '}
                      <b>{priceFormat(order.summ)} р.</b>
                    </td>
                    <td>
                      <TableButton
                        Icon={Trash}
                        onClick={() => {
                          setOrders(prev => {
                            const newOrders = [...prev];
                            newOrders.splice(i, 1);
                            return newOrders;
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>

          <Row>
            <Col md="4">
              <FormGroup>
                <Label>Выберите заказ, куда перенести товары</Label>
                <InputGroup>
                  <Button color="primary" onClick={selectOrderHandler}>
                    Выбрать
                  </Button>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                {order?.id &&
                  <>
                    <Row>
                      <Col md="3">
                        <Label className="text-sm-right">
                          Заказ №
                        </Label>
                      </Col>
                      <Col md="9">
                        <b>{order.number}</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Label className="text-sm-right">
                          Клиент
                        </Label>
                      </Col>
                      <Col md="9">
                        <b>{order.customer}</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Label className="text-sm-right">
                          Товаров
                        </Label>
                      </Col>
                      <Col md="9">
                        <b>{order.count}</b>{' '}
                        на сумму{' '}
                        <b>{priceFormat(order.summ)} р.</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Label className="text-sm-right">
                          Статус
                        </Label>
                      </Col>
                      <Col md="9">
                        <b>{order.status.name}</b>
                      </Col>
                    </Row>
                  </>
                }
              </FormGroup>
              <FormGroup>
                <Button color="primary" size="lg" onClick={mergeOrderHandler} disabled={orders?.length <= 0 || !order?.id}>
                  Обьединить
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        <div className="d-flex justify-content-end">

          <Button onClick={() => setOpenModal(false)} color="secondary" >
            Закрыть
          </Button>
        </div>
      </ModalBody>
    </Modal >
  );
};
