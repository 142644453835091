import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Trash } from "react-feather";
import { Button, Col, Input, InputGroup, Label, Row } from "reactstrap";
import {
  brandsSuggestion,
  createCross,
  getCrossByArticleBrand,
  removeCross,
} from "services/api";
import { toast } from "react-toastify";

import { TableButton } from "components/TableButton";
import { SelectSearch } from "components/SelectSearch";

export const Oem = ({ cross, getAndSetCross, setLoading, article, brand }) => {
  const [articleAnalog, setArticleAnalog] = useState("");
  const [brandAnalog, setBrandAnalog] = useState(null);
  const [searchArticle, setSearchArticle] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [crossFiltered, setCrossFiltered] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const removeCrossHandler = (id) => {
    setLoading(true);
    removeCross({ id })
      .then(() => {
        toast.success("Кросс успешно удален");
        getAndSetCross();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const createCrossHandler = () => {
    setLoading(true);
    createCross({
      article,
      brand: brand?.label,
      articleAnalog,
      brandAnalog: brandAnalog?.label,
    })
      .then(() => {
        toast.success("Кросс успешно добавлен");
        getAndSetCross();
        setArticleAnalog("");
        setBrandAnalog("");
        setShowForm(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterByArticleBrand = (item) => {
    if (!searchArticle && !searchBrand) {
      return true;
    }
    if (
      searchArticle &&
      item.article.toUpperCase().includes(searchArticle.toUpperCase())
    ) {
      return true;
    }
    if (
      searchBrand &&
      item.brand.toUpperCase().includes(searchBrand.toUpperCase())
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!cross) return;
    setCrossFiltered(cross.filter(filterByArticleBrand));
  }, [cross, searchArticle, searchBrand]);

  const cancel = () => {
    setArticleAnalog("");
    setBrandAnalog("");
    setShowForm(false);
  };

  return (
    <>
      <div className="mb-3">
        <Row>
          <Col md={5}>
            <Button onClick={() => setShowForm(true)} color="primary">
              Добавить
            </Button>
          </Col>
        </Row>

        {showForm && (
          <>
            <Row>
              <Col md={6}>
                <Input
                  value={articleAnalog}
                  onChange={(e) => setArticleAnalog(e.target.value)}
                  placeholder="Артикул"
                />
              </Col>
              <Col md={6}>
                <SelectSearch
                  name="brand"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brandAnalog}
                  setValue={setBrandAnalog}
                />
              </Col>
            </Row>
            <Row className="mb-1">
              <Col md="12" className="d-flex justify-content-end mt-1">
                <Button onClick={cancel}>Отменить</Button>
                <Button
                  onClick={createCrossHandler}
                  color="primary"
                  className="ml-1"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Row className="mb-1">
        <Col md={5}>
          <Input
            value={searchArticle}
            onChange={(e) => setSearchArticle(e.target.value)}
            placeholder="Артикул"
          />
        </Col>
        <Col md={5}>
          <Input
            value={searchBrand}
            onChange={(e) => setSearchBrand(e.target.value)}
            placeholder="Бренд"
          />
        </Col>
      </Row>
      <table className="table table-hover">
        <tbody>
          {crossFiltered.map((item) => (
            <tr key={item.id}>
              <td>
                {item.nomenclature ? (
                  <Link
                    to={`/reference/nomenclature?search=${item.article}`}
                    target="_blank"
                  >
                    {item.article}
                  </Link>
                ) : (
                  <>{item.article}</>
                )}
              </td>
              <td>{item.brand}</td>
              <td>
                <TableButton
                  Icon={Trash}
                  onClick={() => removeCrossHandler(item.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
