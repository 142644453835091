import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { priceFormat } from "utils/format";
import classNames from "classnames";
import { Trash2 } from "react-feather";

import { TableButton } from "components/TableButton";

import "./ordersToProviderProductTableNew.scss";

export const OrdersToProviderProductTableNew = ({ products, setProducts }) => {
  const removeProductsHandler = (index) => {
    setProducts((prev) => {
      prev.splice(
        prev.findIndex((val) => val.index === index),
        1
      );
      return [...prev];
    });
  };

  const productHandler = (oldData, newData, row) => {
    setProducts((prev) => {
      const index = prev.findIndex((val) => val.index === row.index);
      prev[index].summ = row.count * row.price;
      return [...prev];
    });
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
      clickToSelect: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => <span className="cute">{cell}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => classNames("right bold"),
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
        return total;
      },
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      footerClasses: () => "right bold",
      classes: () => "right bold",
      editable: false,
      footer: (columnData) => {
        const total = columnData.reduce(
          (acc, item) => acc + parseFloat(item),
          0
        );
        return total && priceFormat(total);
      },
    },
    {
      dataField: "dateArrival",
      text: "Дата поступления",
      formatter: (cell, row) => <>{cell}</>,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      footer: () => null,
      editable: false,
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => (
        <TableButton
          Icon={Trash2}
          onClick={() => removeProductsHandler(row.index)}
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      footer: () => null,
      editable: false,
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="orders-to-provider-product"
        keyField="id"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
      />
    </>
  );
};
