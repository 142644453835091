import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { addProduct, providersSuggestion } from "services/api";
import { SelectSearch } from "components/SelectSearch";
import { validHandler } from "utils/validation";
import { articleNormalize } from "utils/articleNormalizer";

import { useOrders } from "../OrdersContext";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";

export const AddProductModal = ({ orderId, openModal, setOpenModal }) => {
  const { setLoading, products, setProducts } = useOrders();
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [count, setCount] = useState("");
  const [nomenclature, setNomenclature] = useState(null);
  const [provider, setProvider] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);
  const [warning, setWarning] = useState("");

  const priceInputHandler = (e) => {
    const price = e.target.value.replace(",", ".").replace(" ", "");
    if (price === "" || /^[0-9\b\.]+$/.test(price)) {
      setPrice(price);
    }
  };

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);
    }
  };

  const addProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      nomenclature,
      price,
      count,
      provider: provider.value,
    });

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    addProduct({
      nomenclature,
      orderId,
      price,
      count,
      provider: provider.value,
    })
      .then((res) => {
        setProducts(res.products);
        setArticle("");
        setBrand("");
        setName("");
        setPrice("");
        setCount("");
        setProvider("");
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
        setNeedValid(false);
        setOpenModal(false);
      });
  };

  const checkDuplicates = () => {
    const exist = products.filter(
      (product) => product.articleSearch === articleNormalize(article)
    );

    if (exist.length) {
      const duplicate = exist.map((product) => product.index);
      setWarning(
        `Артикул ${article} присутствует в позиции ${duplicate.join(", ")}`
      );
      return;
    }
    addProductHandler();
    return null;
  };

  useEffect(() => {
    const { errors } = validHandler({
      nomenclature,
      price,
      count,
      provider: provider.value,
    });
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, name, price, count, provider]);

  const selectedBrand = (brand) => {
    setBrand(brand?.name);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Добавить товар в заказ</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>
              <ButtonGroup style={{ width: "100%" }}>
                <AutocompleteArticle
                  article={article}
                  brand={brand}
                  setArticle={setArticle}
                  setBrand={({ id, name }) => setBrand(name)}
                  setName={setName}
                  setNomenclature={setNomenclature}
                  setPrice={setPrice}
                  needValid={needValid}
                  errors={errors}
                  style={{ width: "218px" }}
                />
                <CreateNomenclatureModal
                  articleHandler={setArticle}
                  brandHandler={selectedBrand}
                  nameHandler={setName}
                  nomenclatureHandler={setNomenclature}
                />
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Цена</Label>
              <Input
                name="price"
                placeholder="Цена"
                onChange={priceInputHandler}
                value={price}
                invalid={needValid && errors.hasOwnProperty("price")}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Поставщик</Label>

              <SelectSearch
                name="provider"
                placeholder="Выберите поставщика"
                functionSuggestion={providersSuggestion}
                value={provider}
                setValue={setProvider}
                needValid={needValid}
                errors={errors}
              />
            </FormGroup>
          </Col>
        </Row>
        {warning && (
          <Alert color="warning" className="d-flex justify-content-between">
            <div>{warning}</div>
            <div>
              <Button
                onClick={() => setOpenModal(false)}
                color="secondary"
                className="mr-1 mb-1"
              >
                Отменить
              </Button>
              <Button onClick={addProductHandler} color="primary">
                Продолжить
              </Button>
            </div>
          </Alert>
        )}

        <div className="d-flex justify-content-between">
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Закрыть
          </Button>
          <Button onClick={checkDuplicates} color="primary">
            Добавить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
