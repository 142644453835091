import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useQuery from "utils/useQuery";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import { dateFormatU } from "utils/format";
import { getDelivery, getDeliveryToExcel, getStatuses } from "services/api";
import { colorStyles, selectedStatuses } from "utils/select2";
import { DeliveryListTable } from "features/DeliveryList/DeliveryListTable";
import { useUser } from "features/Users/UserContext";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { ROUTES } from "features/Users/Addresses";

registerLocale("ru", ru);

const DELIVERY_TYPES = [
  {
    value: 2,
    label: "Доставка курьером",
  },
  {
    value: 3,
    label: "Доставка до ТК",
  },
];

const DELIVERED = [
  {
    value: false,
    label: "Активные",
  },
  {
    value: true,
    label: "Закрытые",
  },
];

export const DeliveryListPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [loading, setLoading] = useState(false);
  const { admins, user } = useUser();
  const [deliveryList, setDeliveryList] = useState([]);
  const [deliveryListCount, setDeliveryListCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [number, setNumber] = useState("");
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [delivered, setDelivered] = useState(
    query.get("delivered")
      ? {
        label: query.get("deliveredName"),
        value: query.get("delivered"),
      }
      : null
  );
  const [driver, setDriver] = useState(
    query.get("driver")
      ? {
        label: query.get("driverName"),
        value: query.get("driver"),
      }
      : null
  );
  const [isShowReset, setIsShowReset] = useState(false);
  const [deliveryType, setDeliveryType] = useState(
    query.get("deliveryType")
      ? {
        label: query.get("deliveryTypeName"),
        value: query.get("deliveryType"),
      }
      : null
  );
  const [statuses, setStatuses] = useState([]);
  const [filterStatus, setFilterStatus] = useState(
    localStorage.getItem("filterStatus")
      ? JSON.parse(localStorage.getItem("filterStatus"))
      : []
  );

  const [storeKeeper, setStoreKeeper] = useState(
    query.get("deliveryType")
      ? {
        label: query.get("storeKeeperName"),
        value: query.get("storeKeeper"),
      }
      : null
  );

  const [manager, setManager] = useState(
    query.get("manager")
      ? {
        label: query.get("managerName"),
        value: query.get("manager"),
      }
      : null
  );

  const [customer, setCustomer] = useState(
    query.get("customer")
      ? {
        label: query.get("customerName"),
        value: query.get("customer"),
      }
      : null
  );

  const [route, setRoute] = useState(
    query.get("route")
      ? {
        label: query.get("routeName"),
        value: query.get("route"),
      }
      : null
  );

  const getAndSetDeliveryList = () => {
    setLoading(true);
    getDelivery({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      status: filterStatus.map((val) => val.value),
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      deliveryType: deliveryType?.value,
      number,
      delivered: delivered?.value,
      driver: driver?.value,
      storeKeeper: storeKeeper?.value,
      manager: manager?.value,
      customer: customer?.value,
      route: route?.value
    })
      .then((res) => {
        setDeliveryList(res.items);
        setDeliveryListCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally((e) => setLoading(false));
  };

  const getAndSetStatuses = () => {
    getStatuses()
      .then((res) => {
        setStatuses(res);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (number) {
      url.append("number", number);
    }

    if (deliveryType) {
      url.append("deliveryType", deliveryType?.value);
      url.append("deliveryTypeName", deliveryType?.label);
    }

    if (driver) {
      url.append("driver", driver?.value);
      url.append("driverName", driver?.label);
    }

    if (delivered) {
      url.append("delivered", delivered?.value);
      url.append("deliveredName", delivered?.label);
    }

    if (storeKeeper) {
      url.append("storeKeeper", storeKeeper?.value);
      url.append("storeKeeperdName", storeKeeper?.label);
    }

    if (manager) {
      url.append("manager", manager?.value);
      url.append("managerName", manager?.label);
    }

    if (customer) {
      url.append("customer", customer?.value);
      url.append("customerName", customer?.label);
    }

    if (route) {
      url.append("route", route?.value);
      url.append("routeName", route?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    dateStart,
    dateEnd,
    number,
    filterStatus,
    deliveryType,
    delivered,
    driver,
    storeKeeper,
    manager,
    customer,
    route
  ]);

  useEffect(() => {
    // setLoading(true);
    // getAndSetDeliveryList();
    getAndSetStatuses();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    getAndSetDeliveryList();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const isSearch = () => {
    if (dateStart || dateEnd || number || deliveryType || delivered || driver || storeKeeper || manager || customer || route) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetDeliveryList();
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetDeliveryList();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, number, filterStatus, deliveryType, delivered, driver, storeKeeper, manager, customer, route]);

  const numberHandler = (e) => {
    setNumber(e.target.value.replace(/[^+\d]/g, ""));
  };

  const statusHandler = (val, res) => {
    localStorage.setItem("filterStatus", JSON.stringify(val));
    setFilterStatus(val);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const resetFilter = () => {
    setDateStart("");
    setDateEnd("");
    setNumber("");
    setDeliveryType(null);
    setDelivered(null);
    setDriver(null);
    setStoreKeeper(null);
    setManager(null);
    setCustomer(null);
    setRoute(null);
  };

  const printLinkRender = () => {
    const url = new URLSearchParams();
    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (driver) {
      url.append("driver", driver?.value);
      url.append("driverName", driver?.label);
    }

    return (
      process.env.REACT_APP_BASE_URL +
      `/api/v2/delivery/print?admin=${user?.id}&` +
      url.toString()
    );
  };

  const downloadDeliveryToExcel = () => {
    setLoading(true);
    getDeliveryToExcel()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Лист доставок.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="p-0 corr-orders">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Список доставок</h1>
          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Дата доставки</Label>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <Label>Заказ №</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={numberHandler}
                  onKeyUp={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <Label>Тип доставки</Label>
                <Select
                  name="deliveryType"
                  placeholder="Тип доставки"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={DELIVERY_TYPES}
                  value={deliveryType}
                  onChange={(val) => setDeliveryType(val)}
                  isClearable
                />
              </Col>
              <Col md={2}>
                <Label>Статус доставки</Label>
                <Select
                  name="delivered"
                  placeholder="Статус доставки"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={DELIVERED}
                  value={delivered}
                  onChange={(val) => setDelivered(val)}
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col md={5}>
                <Label>Статус заказа</Label>
                <Select
                  isMulti
                  name="status"
                  placeholder="Статус"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statuses.map((val) => ({
                    label: val.name,
                    value: val.id,
                    color: val.color,
                  }))}
                  value={selectedStatuses(statuses, filterStatus)}
                  onChange={statusHandler}
                  styles={colorStyles}
                />
              </Col>
              <Col md={3}>
                <Label>Клиент</Label>
                <SelectCustomer customer={customer} setCustomer={setCustomer} />
              </Col>
              <Col md={2}>
                <Label>Водитель</Label>
                <Select
                  name="driver"
                  placeholder="Водитель"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins.filter((item) => item.department === "drivers")}
                  isSearchable
                  value={driver}
                  onChange={setDriver}
                />
              </Col>

              <Col md={2}>
                <Label>Менеджер</Label>
                <Select
                  name="manager"
                  placeholder="Менеджер"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  isSearchable
                  value={manager}
                  onChange={setManager}
                />
              </Col>

              <Col md={2}>
                <Label>Сборщик</Label>
                <Select
                  name="storeKeeper"
                  placeholder="Сборщик"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins.filter((item) => item.department === "warehouse")}
                  value={storeKeeper}
                  onChange={setStoreKeeper}
                />
              </Col>
              <Col md={3}>
                <Label>Маршрут доставки</Label>
                <Select
                  name="route"
                  placeholder="Маршрут доставки"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ROUTES}
                  value={route}
                  onChange={(val) => setRoute(val)}
                  isClearable
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="d-flex">
                <Button
                  onClick={searchHandler}
                  className="button-wide mt-1"
                  href={printLinkRender()}
                  target="_blank"
                  rel="noreferrer"
                >
                  Печать
                </Button>
                <Button
                  onClick={downloadDeliveryToExcel}
                  className="button-wide mt-1 ml-2"
                >
                  Скачать
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <div>
        <DeliveryListTable
          setLoading={setLoading}
          deliveryList={deliveryList}
          getAndSetDeliveryList={getAndSetDeliveryList}
          loading={loading}
        />

        <Card>
          <CardBody>
            <Pagination
              totalCount={deliveryListCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
