import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Type } from "react-bootstrap-table2-editor";
import { Download } from "react-feather";

import { dateTimeFormat } from "utils/format";
import { Cut } from "components/Cut";
import { TableButton } from "components/TableButton";

import "./priceListsTable.scss";

export const PriceListsTable = ({ priceLists, setForUpdatePriceList }) => {
  const history = useHistory();
  const [ctrl, setCtrl] = useState(false);

  const tableColumns = [
    {
      dataField: "provider",
      text: "Поставщик",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
    },
    {
      dataField: "title",
      text: "Наименование",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
    },
    {
      dataField: "delivery",
      text: "Срок доставки",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
    },
    {
      dataField: "deliveryGarant",
      text: <Cut>Вероятность поставки, %</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
    },
    {
      dataField: "dateLastUpdate",
      text: <Cut>Дата последнего обновления</Cut>,
      formatter: (cell) => dateTimeFormat(cell),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
    },
    {
      dataField: "rows",
      text: "Кол-во",
      formatter: (cell, row) => (
        <>
          {row.rows} из {row.fileRows}
        </>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
    },
    {
      dataField: "status",
      text: "Статус загрузки",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      editable: false,
    },
    {
      dataField: "log",
      text: "Лог",
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell) => (cell && cell.length) > 0 ? <Cut>{cell.at(-1).message}</Cut> : null,
      editable: false,
    },
    {
      dataField: "forUpdate",
      text: "Обновить",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell, row) => <TableButton Icon={Download} onClick={() => setForUpdatePriceList(row.id)} disabled={cell} />,
      editable: false,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "active",
      text: "Активен",
      classes: (cell) =>
        cell === "Нет" ? "editable inactive" : "editable active",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      editor: {
        type: Type.CHECKBOX,
        value: "Да:Нет",
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
    },
  ];

  useEffect(() => {
    const onKeyDown = (e) => e.keyCode === 17 && setCtrl(true);
    const onKeyUp = (e) => e.keyCode === 17 && setCtrl(false);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      if (ctrl) {
        window.open(`/receipt/priceLists/${row.id}`, "_blank");
        return;
      }

      history.push(`/receipt/priceLists/${row.id}`);
    },
  };

  return (
    <BootstrapTable
      keyField="id"
      data={priceLists}
      columns={tableColumns}
      bootstrap4
      bordered={false}
      hover={true}
      rowEvents={rowEvents}
      classes="pointer"
    />
  );
};
