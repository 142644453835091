import { useHistory, useLocation, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";

import { MySpinner } from "components/MySpinner";
import useQuery from "utils/useQuery";
import { useEffect, useState } from "react";
import { Pagination } from "components/Pagination";
import { SendingPriceListTable } from "features/SendingPriceList/SendingPriceListTable";
import { getSendingPriceList } from "services/api";

export const SendingPriceListPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [sendingPriceLists, setSendingPriceLists] = useState([]);
  const [sendingPriceListsCount, setSendingPriceListsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [number, setNumber] = useState(query.get("number") || "");

  // const [provider, setProvider] = useState(
  //   query.get("provider")
  //     ? { label: query.get("providerName"), value: query.get("provider") }
  //     : null
  // );

  // const [isShowReset, setIsShowReset] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const getAndSetSendeingPriceList = () => {
    getSendingPriceList({
      count: pageSize,
      offset: (currentPage - 1) * pageSize,
    })
      .then((res) => {
        setSendingPriceLists(res.items);
        setSendingPriceListsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetSendeingPriceList();
  }, [currentPage, pageSize]);

  return (
    <Container fluid className="p-0 sending-price-list">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Рассылки прайс листов</h1>
          <Form className="mb-3">
          </Form>

          <Row>
            <Col md="2">
              <Link
                to={`/sales/sendingPriceList/new`}
                color="primary"
                className="button-wide btn btn-primary"
              >
                Добавить рассылку
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div>

        <SendingPriceListTable sendingPriceLists={sendingPriceLists} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={sendingPriceListsCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}