import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { scrollToInvalid } from "utils/scrollToInvalid";

const deepEquals = function (x, y) {
  if (x === y) {
    return true;
  }
  else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
    if (Object.keys(x).length != Object.keys(y).length)
      return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEquals(x[prop], y[prop]))
          return false;
      }
      else
        return false;
    }

    return true;
  }
  else
    return false;
}

const defaultShouldTriggerErrors = (errors, nextErrors) => !deepEquals(errors, nextErrors);

export const ErrorListener = ({ onError, shouldTriggerErrors }) => {
  const shouldTriggerErrors1 = shouldTriggerErrors || defaultShouldTriggerErrors;
  const formik = useFormikContext();
  const [errors, setErrors] = useState(formik.errors);

  useEffect(() => {
    if (shouldTriggerErrors1(errors, formik.errors)) {

      console.log(formik.errors);

      onError(formik.errors);

      setErrors(errors);
    }
  }, [formik.errors]);

  return null;
}