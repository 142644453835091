export const TYPES = [
  "Двигатели и комплектующие",
  "Трансмиссия",
  "Подвеска и рулевое управление",
  "Кабина",
  "Рамы, кузова и надстройки",
  "Электроника и свет",
  "Гидравлические и пневмосистемы",
  "Для навесного оборудования",
  "Тормозная система",
  "Для прицепной техники"
];

export const TECHNIC_TYPES = {
  "Двигатели и комплектующие": [
    "Топливная система",
    "Система выпуска",
    "Система охлаждения ДВС",
    "Блоки цилиндров, ГБЦ и картеры",
    "Крепление и защита двигателя",
    "Поршни, шатуны, кольца, вкладыши",
    "Система смазки",
    "Коленвалы, распредвалы и маховики",
    "ГРМ",
    "Турбины, компрессоры",
    "Система зажигания",
    "Двигатели в сборе",
    "Система впуска",
    "Система кондиционирования и отопления",
    "Стартеры и генераторы"
  ],
  "Трансмиссия": [
    "Сцепление",
    "КПП в сборе",
    "Детали КПП",
    "Механизмы отбора мощности",
    "Карданный вал, привод и полуось",
    "Редуктор, муфта и дифференциалы",
    "Подшипники и крестовины",
    "Раздаточная коробка"
  ],
  "Подвеска и рулевое управление": [
    "Рулевые рейки и усилители",
    "Рычаги и шаровые опоры",
    "Пальцы и шкворни",
    "Амортизаторы и пружины",
    "Рессоры и листы",
    "Балки, стабилизаторы и балансиры",
    "Рулевые тяги и наконечники",
    "Кулаки и ступицы",
    "Втулки и сайлентблоки",
    "Запчасти для гусеничного хода"
  ],
  "Кабина": [
    "Кабины в сборе",
    "Окна и стёкла",
    "Внешние элементы кабины",
    "Подвеска кабины",
    "Зеркала",
    "Двери",
    "Крылья",
    "Капот",
    "Бампера",
    "Детали салона",
    "Сиденья и спальники"
  ],
  "Рамы, кузова и надстройки": [
    "Надстройки и платформы в сборе",
    "Борта и элементы кузова",
    "Тенты",
    "Топливные баки",
    "Рамы и лонжероны"
  ],
  "Электроника и свет": [
    "Фонари и фары",
    "Электронные блоки управления",
    "Электропроводка",
    "Контроллеры, реле и датчики",
    "Аккумуляторы",
    "Телематика, тахографы и рации",
    "Электродвигатели и актуаторы"
  ],
  "Гидравлические и пневмосистемы": [
    "Пневматика сцепления и тормозов",
    "Пневмоподвеска",
    "Компрессоры, клапаны и воздухоподготовка",
    "Шланги, трубки, патрубки",
    "Гидромоторы и гидронасосы",
    "Гидроцилиндры и баки",
    "Гидрораспределители",
    "Фильтры и фильтроэлементы"
  ],
  "Для навесного оборудования": [],
  "Тормозная система": [
    "Диски и барабаны",
    "Колодки и накладки",
    "Ремкомплекты суппортов",
    "Суппорта в сборе"
  ],
  "Для грузовиков и спецтехники": []
};