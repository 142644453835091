import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";

import { validHandler } from "utils/validation";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { addOrderToProviderProduct } from "services/apiSupply";
import { dateFormat } from "utils/format";
import { SelectDate } from "../SelectDate";

export const AddProductModal = ({
  orderToProviderId,
  openModal,
  setOpenModal,
  setProducts,
}) => {
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [summ, setSumm] = useState(0);
  const [count, setCount] = useState(1);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const [dateArrival, setDateArrival] = useState(currentDate);
  const [nomenclature, setNomenclature] = useState(null);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const priceInputHandler = (e) => {
    const price = e.target.value;
    if (price === "" || /^[0-9\b.]+$/.test(price)) {
      setPrice(price);

      setSumm((parseFloat(price) * count * 100) / 100);
    }
  };

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);

      setSumm((parseFloat(price) * count * 100) / 100);
    }
  };

  const addProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      nomenclature,
      summ,
      count,
      price,
    });

    setErrors(errors);

    if (!valid) return;

    if (orderToProviderId) {
      setLoading(true);
      addOrderToProviderProduct({
        id: orderToProviderId,
        nomenclature,
        count,
        price,
        dateArrival: dateFormat(dateArrival),
      })
        .then((res) => {
          setProducts(res.products);
          setOpenModal(false);
          setArticle("");
          setBrand("");
          setNomenclature("");
          setName("");
          setCount(1);
          setPrice(0);
          setSumm(0);
          setErrors([]);
          setNeedValid(false);
        })
        .catch((e) => toast.error(e.response?.data?.message))
        .finally(() => setLoading(false));
    } else {
      setProducts((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          index: prev.length + 1,
          nomenclature,
          article,
          brand,
          name,
          count,
          price,
          summ,
          dateArrival: dateFormat(dateArrival),
        },
      ]);
      setOpenModal(false);
      setArticle("");
      setBrand("");
      setNomenclature("");
      setName("");
      setCount(1);
      setPrice(0);
      setSumm(0);
      setErrors([]);
      setNeedValid(false);
    }
  };

  useEffect(() => {
    const { errors } = validHandler({
      nomenclature,
      summ,
      count,
      price,
    });
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, summ, name, count, price]);

  const selectedBrand = (brand) => {
    setBrand(brand?.name);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">
          Добавить товар в заказ поставщику
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>

      {loading && <MySpinner overlay />}

      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>

              <AutocompleteArticle
                article={article}
                brand={brand}
                setArticle={setArticle}
                setBrand={({ id, name }) => setBrand(name)}
                setName={setName}
                setNomenclature={setNomenclature}
                setPrice={setPrice}
                needValid={needValid}
                errors={errors}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Цена</Label>
              <Input
                name="price"
                placeholder="Цена"
                onChange={priceInputHandler}
                value={price}
                invalid={needValid && errors.hasOwnProperty("price")}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Сумма</Label>
              <Input
                name="summ"
                placeholder="Сумма"
                value={summ}
                invalid={needValid && errors.hasOwnProperty("summ")}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Дата поступления</Label>
              <SelectDate
                date={dateArrival}
                setDate={(val) => setDateArrival(val)}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button
            onClick={addProductHandler}
            color="primary"
            className="button-wide"
          >
            Добавить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
