import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import cellEditFactory from "react-bootstrap-table2-editor";

import { getBrandSynonyms, removeSynonym, updateSynonym } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";

import { CreateSynonymModal } from "features/Brands/CreateSynonymModal";

import { tableColumns } from "./tableData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

export const BrandSynonymsPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [brands, setBrands] = useState([]);
  const [brandsCount, setBrandsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const getAndSetBrandSynonyms = () => {
    getBrandSynonyms({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
    })
      .then((res) => {
        setBrands(res.items);
        setBrandsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const removeSynonymHandler = () => {
    setLoading(true);
    removeSynonym({ ids: selected })
      .then((res) => {
        getAndSetBrandSynonyms();
        setSelected([]);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search]);

  useEffect(() => {
    setLoading(true);
    getAndSetBrandSynonyms();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    setLoading(true);
    getAndSetBrandSynonyms();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetBrandSynonyms();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const searchHandler = () => {
    if (!search) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetBrandSynonyms();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const isSearch = () => {
    if (search) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
  };

  const editHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateSynonym({ id: row.id, synonym: row.synonym })
      .then(() => getAndSetBrandSynonyms())
      .finally(() => { });
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(brands.map((val) => val.id));
      }
    },
  };

  return (
    <Container fluid className="p-0 brandSynonyms">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Синонимы брендов</h1>

          <Form className="mb-3">
            <Row>
              <Col lg="4">
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По бренду и синониму"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Button onClick={() => setOpenModal(true)} color="primary">
            Добавить синоним
          </Button>

          {selected.length > 0 && (
            <Button
              onClick={removeSynonymHandler}
              className="mb-3 ml-3"
              color="primary"
            >
              Удалить выбранные
            </Button>
          )}
        </CardBody>
      </Card>

      <BootstrapTable
        keyField="id"
        data={brands}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            editHandler(oldData, newData, row),
        })}
        selectRow={selectRow}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={brandsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />

          <CreateSynonymModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            action={getAndSetBrandSynonyms}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
