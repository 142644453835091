import { CloseButton } from "components/CloseButton";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { moveProduct } from "services/api";
import { useOrders } from "../OrdersContext";

//import { addProduct, getProvider, providersSuggestion } from "services/api";

export const MoveToOrderModal = ({ ids, orderId, openModal, setOpenModal }) => {
  const { setLoading, setProducts } = useOrders();
  const [number, setNumber] = useState("");

  const moveProductHandler = () => {
    setLoading(true);
    moveProduct({
      ids,
      orderId,
      number,
    })
      .then((res) => {
        setProducts(res.products);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message)
      })
      .finally(() => {
        setLoading(false);
        setOpenModal(false);
      });
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Перенести товары в заказ</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Номер заказа (оставить пустым для создания нового заказа)</Label>
              <Input
                name="number"
                placeholder="Номер заказа"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-between">
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Закрыть
          </Button>
          <Button onClick={moveProductHandler} color="primary">
            Перенести
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
