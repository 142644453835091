import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { CloseButton } from "components/CloseButton";
import { updateOrder } from "services/api";
import { toast } from "react-toastify";
import { MySpinner } from "components/MySpinner";

export const LabelModal = ({
  id,
  openModal,
  setOpenModal,
  getAndSetDeliveryList,
  setQuantityLabels,
  quantityLabels,
  setShowPrintInvoice,
  setShowPrintLabels,
  loading
}) => {

  const printHandler = (complect = false) => {
    if (getAndSetDeliveryList) {
      getAndSetDeliveryList();
    }

    setOpenModal(false);

    setTimeout(() => {
      if (complect) {
        setShowPrintInvoice(true);
      } else {
        setShowPrintInvoice(false);
        setShowPrintLabels(true);
      }
    }, 500);

    updateOrder({
      id,
      placeQuantity: quantityLabels
    })
      .then(() => {
        getAndSetDeliveryList();
        toast.success("Кол-во мест успешно сохранено");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  const onClose = () => {
    setOpenModal(false);
    setQuantityLabels(0);
  }

  return (
    <Modal isOpen={openModal} toggle={onClose}>
      <ModalHeader>
        <div className="modal-title-text">Печать этикеток</div>
        <CloseButton onClick={onClose} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Form>
          {loading && <MySpinner overlay />}
          <Input type="hidden" name="order" value={id} />

          <FormGroup>
            <Label>Кол-во мест</Label>
            <Input
              type="number"
              name="quantityLabels"
              value={quantityLabels}
              onChange={(e) => setQuantityLabels(() => {
                return e.target.value
                  ? +e.target.value < 0 ? 0 : +e.target.value
                  : "";
              })}
            />
          </FormGroup>
          <FormGroup>
            <Button color="primary" size="lg" onClick={() => printHandler()} className="mr-2">
              Печать этикеток
            </Button>
            <Button color="primary" size="lg" onClick={() => printHandler(true)}>
              Печать комплекта документов
            </Button>
          </FormGroup>
        </Form>

        <div className="d-flex justify-content-end">
          <Button type="button" onClick={onClose} color="secondary">
            Закрыть
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
