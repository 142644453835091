import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import { getAdmins } from "services/api";
import { MySpinner } from "components/MySpinner";
import { AdminsTable } from "features/Admins/AdminsTable";
import { AdminModal } from "features/Admins/AdminModal";
import { Checkbox } from "components/Checkbox";
import { useUser } from "features/Users/UserContext";

export const DEPARTMENTS = [
  { label: "Бухгалтерия", value: "accounting" },
  { label: "Склад", value: "warehouse" },
  { label: "Водители", value: "drivers" },
  { label: "Отдел продаж", value: "sales" },
  { label: "Отдел закупок", value: "purchasing" },
  { label: "Руководство", value: "management" },
  { label: "Разработка", value: "development" },
];

export const BRANCHES = [
  { label: "Москва", value: 1 },
  { label: "Воронеж", value: 2 },
]

export const AdminsPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useUser();
  const query = useQuery(useLocation);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [adminsCount, setAdminsCount] = useState(0);
  const [search, setSearch] = useState(query.get("search") || "");
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const [isShowReset, setIsShowReset] = useState(false);
  const [id, setId] = useState(null);
  const [department, setDepartment] = useState(
    query.get("department")
      ? { label: query.get("departmentName"), value: query.get("department") }
      : null
  );
  const [active, setActive] = useState(query.get("active") || "");

  const getAndSetAdmins = () => {
    getAdmins({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      active,
      department: department?.value,
      branch: localStorage.getItem("branch")
    })
      .then((res) => {
        setAdmins(res.items);
        setAdminsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetAdmins();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (department) {
      url.append("department", department?.value);
      url.append("departmentName", department?.label);
    }

    if (active !== "") {
      url.append("active", active);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search, department?.value, active]);

  const isSearch = () => {
    if (search || department?.value || active !== "") {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetAdmins();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetAdmins();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, department?.value, active]);

  const resetFilter = () => {
    setSearch("");
    setDepartment(null);
    setActive("");
  };

  return (
    <Container fluid className="p-0 admins">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Пользователи</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Поиск</Label>
                <Input
                  name="search"
                  placeholder="По фио, email, тел"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={2}>
                <Label>Отдел</Label>
                <Select
                  name="department"
                  placeholder="Отдел"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={DEPARTMENTS}
                  value={department}
                  onChange={setDepartment}
                  isClearable
                />
              </Col>
              <Col md={1}>
                <Label>Активен</Label>
                <Checkbox
                  onChange={(e) => setActive(e.target.checked)}
                  checked={active}
                >
                  Активен
                </Checkbox>
              </Col>
              <Col md="2">
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row className="mb-2">
            <Col md="2">
              <Button onClick={() => setOpenModal(true)} color="primary">
                Добавить пользователя
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <AdminsTable
        admins={admins}
        getAndSetAdmins={getAndSetAdmins}
        setLoading={setLoading}
        setOpenModal={setOpenModal}
        setId={setId}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={adminsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>

      <AdminModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        id={id}
        setId={setId}
        getAndSetAdmins={getAndSetAdmins}
      />
    </Container>
  );
};
