import React, { useState, useEffect } from "react";
import { Trash2 } from "react-feather";
import {  Input, Spinner } from "reactstrap";
import { useCart } from "../../Cart/CartContext";
import { cartUpdate } from "../../../services/api";
import { TableButton } from "components/TableButton";

export const ButtonChangeCart = ({ row }) => {
  const { setCart } = useCart();
  const [count, setCount] = useState(row.count || 1);
  const [loading, setLoading] = useState(false);

  const removeHandler = () => {
    setLoading(true);
    cartUpdate({ id: row.id, count: 0 })
      .then((res) => {
        setCart(res.cart);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setCount(row.count);
  }, [row.count]);

  const countValid = (e) => {
    const reg = new RegExp("^[0-9]*$");
    if (reg.test(count) === false) return 1;
    if (+e.target.value < 1) return 1;
    if (+e.target.value > 9999) return 9999;
    return +e.target.value;
  };

  const countHandler = (e) => {
    setCount(() => countValid(e));
    setLoading(true);
    cartUpdate({ id: row.id, count: countValid(e) })
      .then((res) => {
        setCart(res.cart);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="countGroup">
      <Input
        className="count"
        type="number"
        id={row.id}
        name="count"
        min={0}
        max={9999}
        value={count}
        onChange={countHandler}
      />

      {loading ? (
        <div className="spinner-wrapper">
          <Spinner color="danger" size="sm" />
        </div>
      ) : (
        <TableButton
          Icon={Trash2}
          onClick={() => removeHandler(row, count)}
          className="size-big"
        />
      )}
    </div>
  );
};
