import React from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { CloseButton } from "components/CloseButton";

export const PlanFactModal = ({ openModal, setOpenModal, planFact }) => {
  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">План - факт приемки товара</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>

      <ModalBody className="m-3">
        <Row>
          <Col lg="6">Товар</Col>
          <Col lg="3">План</Col>
          <Col lg="3">Факт</Col>
        </Row>

        {planFact.map((item) => (
          <Row key={item.index}>
            <Col lg="6">
              {item.nomenclature?.article} {item.nomenclature?.brand}
              {item.nomenclature?.name}
            </Col>
            <Col lg="3">{item.plan}</Col>
            <Col lg="3">{item.fact}</Col>
          </Row>
        ))}

        <div className="d-flex justify-content-end">
          <Button
            onClick={() => setOpenModal(false)}
            color="primary"
            className="button-wide"
          >
            Закрыть
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
