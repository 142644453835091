import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";

import { validHandler } from "utils/validation";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";

export const AddProductModal = ({ openModal, setOpenModal, setProducts }) => {
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [count, setCount] = useState("");
  const [nomenclature, setNomenclature] = useState(null);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);
    }
  };

  const addProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      nomenclature,
      count,
    });

    setErrors(errors);

    if (!valid) return;

    setProducts((prev) => [
      ...prev,
      {
        index: prev.length + 1,
        nomenclature,
        article,
        brand,
        name,
        count,
      },
    ]);

    setOpenModal(false);

    toast.success("Товар успешно добавлен в списание");
  };

  useEffect(() => {
    const { errors } = validHandler({
      nomenclature,
      count,
    });
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, name, price, count]);

  const selectedBrand = (brand) => {
    setBrand(brand?.name);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Добавить товар в списание</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>
              <ButtonGroup style={{ width: "100%" }}>
                <AutocompleteArticle
                  article={article}
                  brand={brand}
                  setArticle={setArticle}
                  setBrand={({ id, name }) => setBrand(name)}
                  setName={setName}
                  setNomenclature={setNomenclature}
                  setPrice={setPrice}
                  needValid={needValid}
                  errors={errors}
                  style={{ width: "218px" }}
                  invalid={needValid && errors.hasOwnProperty("nomenclature")}
                />
                <CreateNomenclatureModal
                  articleHandler={setArticle}
                  brandHandler={selectedBrand}
                  nameHandler={setName}
                  nomenclatureHandler={setNomenclature}
                />
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button
            onClick={addProductHandler}
            color="primary"
            className="button-wide"
          >
            Добавить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
