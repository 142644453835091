import react, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactInputMask from "react-input-mask";
import { useParams, useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import RichTextEditor from "react-rte";
import { toast } from "react-toastify";
import Select from "react-select";

import { HeaderPage } from "components/HeaderPage";
import { MySpinner } from "components/MySpinner";
import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import { createAvitoAd, getAvitoAd, getNomenclatureOne, updateAvitoAd } from "services/api";
import { dateFormat, priceFormat } from "utils/format";
import { NomenclatureModal } from "features/Nomenclature/NomenclatureModal";
import { ActiveResource } from "components/ActiveResource";

import "./avitoOne.scss";
import { TECHNIC_TYPES, TYPES } from "pages/avito/types";

export const AvitoOnePage = () => {
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(id ? false : true);
  const [createdAt, setCreatedAt] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(RichTextEditor.createEmptyValue());
  const [article, setArticle] = useState("");
  const [avitoId, setAvitoId] = useState("");
  const [active, setActive] = useState(false);
  const [brand, setBrand] = useState("");
  const [nomenclature, setNomenclature] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceCorona, setBalanceCorona] = useState(0);
  const [avitoAdLink, setAvitoAdLink] = useState("");
  const [statusAvitoAd, setStatusAvitoAd] = useState("");
  const [type, setType] = useState(null);
  const [technicTypes, setTechnicTypes] = useState([]);
  const [technicType, setTechnicType] = useState(null);

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  const generateDescription = (nomenclature) => {
    return `<p><strong>Наименование</strong>: ${nomenclature.name}</p>
    <p><strong>Артикул</strong>: ${nomenclature.articleSearch} </p>
    <p><strong>Бренд</strong>: ${nomenclature.brand.name}</p>
    <p><strong>Номера аналогов:</strong></p>
    ${nomenclature.cross.map(item => `<p>${item.article} ${item.brand}</p>`).join('')}
    <p><strong>Самовывоз: </strong>г. Москва, ул. Нижние Поля, 31с4</p>
    <p><strong>Доставка до ТК - БЕСПЛАТНО!</strong></p>`;
  }

  const getAndSetNomenclature = (id) => {
    setLoading(true);
    getNomenclatureOne(id)
      .then((res) => {
        toast.success("Номенклатура успешно подгружена");
        setNomenclature({
          id: res.id,
          name: res.name,
          article: res.article,
          brand: res.brand.name,
          marketplacePrice: res.marketplacePrice
        });
        setTitle(`${res?.name} / ${res?.brand?.name} / ${res?.article}`);
        setDescription(RichTextEditor.createValueFromString(generateDescription(res), 'html'));
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  const getAndSetAvitoAd = () => {
    setLoading(true);
    getAvitoAd(id)
      .then((res) => {
        console.log(res);
        setNomenclature(res?.nomenclature);
        setTitle(res?.title);
        setDescription(RichTextEditor.createValueFromString(res?.description, 'html'));
        setCreatedAt(dateFormat(res?.createdAt));
        setAvitoId(res.avitoId);
        setActive(res.active);
        setBalance(res.balance);
        setBalanceCorona(res.balanceCorona);
        setAvitoAdLink(res.avitoAdLink);
        setStatusAvitoAd(res.statusAvitoAd);
        selectType({ label: res.sparePartType[0], value: res.sparePartType[0] });
        setTechnicType({ label: res.sparePartType[1], value: res.sparePartType[1] });
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response?.data?.message || "Ошибка при загрузке объявления");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (!id) return;
    getAndSetAvitoAd();
  }, []);

  const createAvitoAdHandler = () => {
    if (!type || (type && (technicTypes?.length > 0 && !technicType))) {
      toast.error("Нужно заполнить тип и вид товара");
      return;
    };
    setLoading(true);
    createAvitoAd({
      nomenclature: nomenclature?.id,
      title,
      description: description.toString('html'),
      sparePartType: [type?.value, technicType?.value]
    })
      .then((res) => {
        toast.success("Объявление успешно добавлено");
        setNomenclature(res);
        history.push(res.id);
      })
      .catch((e) => {
        toast.error(e.response?.data?.detail || "Ошибка при добавлении объявления");
      })
      .finally(() => setLoading(false));
  }

  const updateAvitoAdHandler = () => {
    if (!type || (type && (technicTypes?.length > 0 && !technicType))) {
      toast.error("Нужно заполнить тип и вид товара");
      return;
    };
    if (!id) return;
    setLoading(true);
    updateAvitoAd({
      id,
      nomenclature: nomenclature?.id,
      title,
      description: description.toString('html'),
      sparePartType: [type?.value, technicType?.value]
    })
      .then((res) => {
        toast.success("Объявление успешно обновлено");
        setNomenclature(res?.nomenclature);
        setTitle(res?.title);
        setDescription(RichTextEditor.createValueFromString(res?.description, 'html'));
        setCreatedAt(dateFormat(res?.createdAt));
        setAvitoId(res.avitoId);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const activeHandler = (e) => {
    setLoading(true);
    setActive(e.target.checked);

    updateAvitoAd({
      id,
      active: e.target.checked
    })
      .then((res) => {
        toast.success(`Объявление успешно ${res.active ? 'активировано' : 'деактивировано'}`);
        setActive(res.active);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  const selectType = (val) => {
    setType(val);

    setTechnicType(null);

    const technicTypesTmp = TECHNIC_TYPES[val?.value];

    if (technicTypesTmp) {
      setTechnicTypes(technicTypesTmp.map(val => ({ label: val, value: val })));
    }
  };


  const renderTitle = () => {
    if (isNew) {
      return `Новое объявление`;
    }

    return `Объявление ${title || ""}`;
  };

  return <Container fluid className="p-0 avito-one">
    <Helmet>
      <title>{renderTitle()}</title>
    </Helmet>
    {loading && <MySpinner overlay />}

    <HeaderPage title={renderTitle()}>
      <>
        {!isNew && (
          <>
            <ActiveResource
              active={active}
              checkboxHandler={activeHandler}
            />

            <div className="avito-one__date-block">
              <Label>Дата</Label>
              <ReactInputMask
                id="date"
                name="date"
                type="text"
                onChange={() => { }}
                value={createdAt}
                mask={"99.99.9999"}
                className="form-control date"
                disabled
              />
            </div>
          </>
        )}
      </>
    </HeaderPage>

    <Card className="avito-one__form mb-0">
      <CardBody className="pb-3">
        {isNew && (<>
          <Row>
            <Col md="6">
              <Label className="text-sm-right">
                Номенклатура
              </Label>
              <AutocompleteArticle
                article={article}
                brand={brand}
                setArticle={setArticle}
                setBrand={({ id, name }) => setBrand(name)}
                setNomenclature={getAndSetNomenclature}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <b>{brand}</b>
            </Col>
          </Row>
        </>
        )}

        {nomenclature?.id && (
          <>
            <Row>
              <Col>
                <Button className="link" onClick={() => setOpenModal(true)}>{nomenclature?.article} {nomenclature?.brand} {nomenclature?.name}</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                цена: {priceFormat(nomenclature?.marketplacePrice)} р
              </Col>
            </Row>
            <Row>
              <Col>
                Склад ЛИДЕР ТРАК: {balance}
              </Col>
            </Row>
            <Row>
              <Col>
                Склад КОРОНА: {balanceCorona}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Заголовок объявления</Label>
                <Input
                  name="title"
                  placeholder="Заголовок объявления"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Вид и тип товара</Label>
                <Row className="mb-2">
                  <Col md="6">
                    <Select
                      name="sparePartType"
                      placeholder="Вид детали"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={TYPES.map(val => ({ label: val, value: val }))}
                      value={type}
                      onChange={(val) => selectType(val)}
                    />
                  </Col>
                  {technicTypes?.length > 0 &&
                    <Col md="6">
                      <Select
                        name="technicSparePartType"
                        placeholder="Тип детали"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={technicTypes}
                        value={technicType}
                        onChange={setTechnicType}
                      />
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Ссылка на объявление Авито</Label>
                <p><a href={avitoAdLink} target="_blank" rel="noreferrer">{avitoAdLink || `---------`}</a></p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Статус объявления на Авито</Label>
                <p>{statusAvitoAd || `---------`}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <Label>Текст объявления</Label>
                <RichTextEditor
                  value={description}
                  onChange={setDescription}
                  toolbarConfig={toolbarConfig}
                />
              </Col>
            </Row>
          </>)}

      </CardBody>
    </Card>
    <Card>
      <CardBody>
        {isNew ? (
          <Button
            color="primary"
            onClick={createAvitoAdHandler}
            className="button-wide"
            type="button"
          >
            Создать
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={updateAvitoAdHandler}
            className="button-wide"
            type="button"
          >
            Сохранить
          </Button>
        )}
      </CardBody>
    </Card>
    <NomenclatureModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      id={nomenclature?.id}
    />
  </Container>
}