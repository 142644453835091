import React, { useState } from "react";
import { ShoppingCart } from "react-feather";
import { Input, Spinner } from "reactstrap";
import { toast } from "react-toastify";

import { useCart } from "../../Cart/CartContext";
import { cartAdd } from "../../../services/api";
import { TableButton } from "components/TableButton";

export const ButtonAddToCart = ({ row }) => {
  const { setCart } = useCart();

  const [count, setCount] = useState(row.count || 1);
  const [loading, setLoading] = useState(false);

  const cartHandler = (row, count) => {
    setLoading(true);
    cartAdd({ ...row, count })
      .then((res) => {
        setCart(res.cart);
        toast.success(`${row.brand} ${row.article} добавлен в корзину`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countValid = (e, max) => {
    const reg = new RegExp("^[0-9]*$");
    if (reg.test(count) === false) return 1;
    if (+e.target.value < 1) return 1;
    if (+e.target.value > 9999) return 9999;
    if (!max.toString().includes(">")) {
      if (+e.target.value > max) return max;
    }
    return +e.target.value;
  };

  return (
    <div className="countGroup">
      <Input
        className="count"
        type="number"
        id={row.id}
        name="count"
        value={count}
        min={1}
        max={9999}
        onChange={(e) => setCount(() => countValid(e, row.exist))}
      />

      {loading ? (
        <div className="spinner-wrapper">
          <Spinner color="danger" size="sm" />
        </div>
      ) : (
        <TableButton
          Icon={ShoppingCart}
          onClick={() => cartHandler(row, count)}
          className="size-big"
        />
      )}
    </div>
  );
};
