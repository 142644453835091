import React from "react";

import Routes from "./routes/Routes";
import { CartProvider } from "./features/Cart/CartContext";
import { UserProvider } from "./features/Users/UserContext";
import { OrdersProvider } from "./features/Orders/OrdersContext";
import './assets/scss/classic.scss';

const App = () => {
  return (
    <UserProvider>
      <CartProvider>
        <OrdersProvider>
          <Routes />
        </OrdersProvider>
      </CartProvider>
    </UserProvider>
  );
}

export default App;
