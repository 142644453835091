import { toast } from "react-toastify";

export const scrollToInvalid = () => {
  setTimeout(() => {
    if (document.querySelectorAll('.is-invalid').length) {
      toast.error("Заполните все необходимые поля");
      const elem = document.querySelectorAll('.is-invalid')[0];
      const rect = elem.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const distanceFromTop = rect.top + scrollTop;
      window.scrollTo({ top: distanceFromTop - 25, behavior: 'smooth' });
    }
  }, 300);

  return;
}

