import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat, priceFormat } from "utils/format";

import { Cut } from "components/Cut";

export const SalaryReportTable = ({ salaryReport }) => {
  const tableColumns = [
    {
      dataField: "number",
      text: "№ Зак",
      headerStyle: () => {
        return { width: "6%" };
      },
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "8%" };
      },
			formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "8%" };
      },
			formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "16%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "document",
      text: "Приход",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "costPrice",
      text: "Себ-ть",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "addExpenses",
      text: "Доп.расх",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "margin",
      text: "Маржа",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "corr",
      text: "Возвраты",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "percent",
      text: "Проц.",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <>{cell} %</>,
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "salary",
      text: "ЗП",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "date",
      text: "Дата",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell) => dateFormat(cell),
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => {
        return { width: "5%" };
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={salaryReport}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
      />
    </>
  );
};
