import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { object, string, setLocale } from "yup";
import Select from "react-select";

import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  createAdmin,
  getAdminOne,
  updateAdmin,
  updateAdminPassword,
} from "services/api";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { locale } from "utils/validationLocale";
import { Checkbox } from "components/Checkbox";
import { BRANCHES, DEPARTMENTS } from "pages/admins";
import { useUser } from "features/Users/UserContext";

import "./adminModal.scss";

const AdminSchema = object().shape({
  firstName: string().min(2).required(),
  lastName: string().min(2).required(),
  email: string().email().required(),
});

const NewAdminSchema = object().shape({
  firstName: string().min(2).required(),
  lastName: string().min(2).required(),
  email: string().email().required(),
  password: string().min(8, "Минимальная длина пароля 8 символов").required(),
});

const PasswordSchema = object().shape({
  password: string().min(8, "Минимальная длина пароля 8 символов").required(),
});

setLocale(locale);

export const AdminModal = ({
  openModal,
  setOpenModal,
  id,
  setId,
  getAndSetAdmins,
}) => {
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const { user } = useUser();

  const getAndSetAdmin = () => {
    if (!id) return;
    setLoading(true);
    getAdminOne(id)
      .then((res) => {
        setAdmin(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setAdmin(null);
    if (openModal === false) {
      setId(null);
      setIsNew(true);
    } else {
      if (id) {
        setIsNew(false);
      }
    }
  }, [openModal]);

  useEffect(() => {
    if (!id) return;
    setAdmin(null);
    getAndSetAdmin();
  }, [id]);

  const submitHandler = (values) => {
    setLoading(true);

    if (values.id) {
      updateAdmin(values)
        .then((res) => {
          getAndSetAdmin();
          toast.success("Пользователь успешно сохранен");

          if (getAndSetAdmins) {
            getAndSetAdmins();
          }
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
      return;
    }

    createAdmin(values)
      .then((res) => {
        getAndSetAdmin();
        toast.success("Пользователь успешно создан");

        if (getAndSetAdmins) {
          getAndSetAdmins();
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const submitPasswordHandler = (values) => {
    setLoading(true);
    updateAdminPassword(values)
      .then((res) => {
        getAndSetAdmin();
        toast.success("Пароль успешно обновлен");

        if (getAndSetAdmins) {
          getAndSetAdmins();
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="admin-modal"
    >
      {loading && <MySpinner overlay />}
      <ModalHeader>
        <div className="modal-title-text">
          {admin?.lastName} {admin?.firstName}
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Formik
          initialValues={{
            id: admin?.id || "",
            firstName: admin?.firstName || "",
            lastName: admin?.lastName || "",
            middleName: admin?.middleName || "",
            email: admin?.email || "",
            phone: admin?.phone || "",
            password: admin?.password || "",
            active: admin?.active || "",
            department: admin?.department || "",
            branch: admin?.branch || user?.branch,
          }}
          validationSchema={isNew ? NewAdminSchema : AdminSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            submitHandler(values, resetForm);
            setSubmitting(false);
          }}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Input id="id" type="hidden" value={values.id} />
              {!isNew && (
                <Row>
                  <Col md="4">
                    <Label>Активен</Label>
                    <Checkbox
                      name="active"
                      value="active"
                      checked={values.active}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md="4">
                  <Label>Фамилия</Label>
                  <Input
                    id="lastName"
                    label="Фамилия"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    invalid={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : null
                    }
                  />
                </Col>
                <Col md="4">
                  <Label>Имя</Label>
                  <Input
                    id="firstName"
                    label="Имя"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    invalid={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : null
                    }
                  />
                </Col>
                <Col md="4">
                  <Label>Отчество</Label>
                  <Input
                    id="middleName"
                    label="Отчество"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.middleName}
                    invalid={
                      errors.middleName && touched.middleName
                        ? errors.middleName
                        : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>Email</Label>
                  <Input
                    id="email"
                    type="email"
                    label="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    invalid={
                      errors.email && touched.email ? errors.email : null
                    }
                  />
                </Col>
                <Col md="6">
                  <Label>Телефон</Label>
                  <Input
                    id="phone"
                    type="tel"
                    label="Телефон"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    invalid={
                      errors.phone && touched.phone ? errors.phone : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>Отдел</Label>
                  <Select
                    name="department"
                    placeholder="Отдел"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={DEPARTMENTS}
                    value={DEPARTMENTS.find(
                      (val) => val.value === values.department
                    )}
                    onChange={(val) => setFieldValue("department", val.value)}
                  />
                </Col>
                <Col md="6">
                  <Label>Филиал</Label>
                  <Select
                    name="branch"
                    placeholder="Филиал"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={BRANCHES}
                    value={BRANCHES.find(
                      (val) => val.value === values.branch
                    )}
                    onChange={(val) => setFieldValue("branch", val.value)}
                  />
                </Col>
              </Row>
              {isNew && (
                <Row>
                  <Col md="12">
                    <Label>Пароль</Label>
                    <Input
                      id="password"
                      label="Пароль"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      invalid={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                    <span>{errors.password}</span>
                  </Col>
                </Row>
              )}

              <div className="d-flex justify-content-end mt-3">
                <Button type="submit" color="primary">
                  Сохранить пользователя
                </Button>
              </div>
            </form>
          )}
        </Formik>
        {!isNew && (
          <Formik
            initialValues={{
              id: admin?.id || "",
              password: "",
            }}
            validationSchema={PasswordSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              submitPasswordHandler(values, resetForm);
              setSubmitting(false);
            }}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Input id="id" type="hidden" value={values.id} />

                <Row>
                  <Col md="12">
                    <Label>Пароль</Label>
                    <Input
                      id="password"
                      label="Пароль"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      invalid={
                        errors.password && touched.password
                          ? errors.password
                          : null
                      }
                    />
                    <span>{errors.password}</span>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end mt-3">
                  <Button type="submit" color="primary">
                    Сменить пароль
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </ModalBody>
    </Modal>
  );
};
