import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";

import { Cut } from "components/Cut";

import "./corrOrderProductTable.scss";

export const CorrOrderProductTableNew = ({ id, products, setIsCorrect }) => {
  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "provider",
      text: "Поставщик",
      formatter: (cell, row) => <Cut>{cell.name}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <Cut>{row.nomenclature?.name}</Cut>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "countBefore",
      text: "Кол-во (было)",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right",
      editable: false,
    },
    {
      dataField: "countAfter",
      text: "Кол-во (стало)",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => {
        if (row.countBefore !== row.countAfter) {
          return "editable right green-bg";
        }
        return "editable right";
      },
      validator: (newValue, row) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Должны быть только цифры",
          };
        }
        if (newValue > row.countBefore) {
          return {
            valid: false,
            message: "Не должно быть больше чем кол-во",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Кол-во не может быть меньше 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "warehouse",
      text: "Склад",
      formatter: (cell, row) => <Cut>{cell.name}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "28%" };
      },
      editable: false,
      footer: (columnData) => <></>,
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="corr-order-product"
        keyField="id"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: () => {
            setIsCorrect(true);
          },
        })}
      />
    </>
  );
};
