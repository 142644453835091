import React from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import { Cut } from "components/Cut";
import { dateFormat, priceFormat } from "utils/format";
import { SOURCES_USERS } from "pages/users";

export const UsersTable = ({ users, onSort }) => {
  const history = useHistory();

  const tableColumns = [
    {
      dataField: "name",
      text: "Имя",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
    },
    {
      dataField: "active",
      text: "Активен",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell) => (cell ? "Да" : "Нет"),
    },
    {
      dataField: "type",
      text: "Тип",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell) => {
        switch (cell) {
          case 1:
            return "Физ";
          case 2:
            return "Юр";
          default:
            return "Физ";
        }
      },
    },
    {
      dataField: "orgInn",
      text: "ИНН",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      dataField: "markup",
      text: "Наценка",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      }
    },
    {
      dataField: "balance",
      text: "Баланс",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell) => (
        <b className={cell < 0 ? "red" : ""}>{priceFormat(cell)}</b>
      ),
      headerClasses: () => "right",
      classes: () => "right",
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "createdAt",
      text: "Дата рег.",
      formatter: (cell) => dateFormat(cell),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
    },
    {
      dataField: "source",
      text: "Источник",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell) => {
        const source = SOURCES_USERS.find((val) => val.value === cell);
        return source?.label || null;
      },
    },
    {
      dataField: "typology",
      text: "Типология",
      formatter: (cell) => <Cut>{cell}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "phone",
      text: "Тел",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/sales/users/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={users}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
      />
    </>
  );
};
