import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { baseUrl, getAddresses } from "services/api";
import { MySpinner } from "components/MySpinner";

export const LabelsPage = () => {
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (!customer.value) return;
    setLoading(true);
    getAddresses(customer.value)
      .then((res) =>
        setAddresses(
          res.map((item) => {
            return { label: item.address, value: item.id };
          })
        )
      )
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [customer]);

  return (
    <Container fluid className="p-0">
      {loading && <MySpinner overlay />}

      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Печать этикеток</h1>
          <Row>
            <Col lg="3">
              <Form action={`${baseUrl}/labels.print`}>
                <Input
                  type="hidden"
                  name="customer"
                  value={customer.value}
                  onChange={(e) => setCustomer(e.target.value)}
                />
                <SelectCustomer
                  customer={customer}
                  setCustomer={setCustomer}
                  invalid={true}
                />
                <FormGroup>
                  <Label>Номер заказа</Label>
                  <Input
                    name="order"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Выберите адрес</Label>
                  <Select
                    placeholder="Выберите адрес"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={addresses}
                    onChange={(val) => setAddress(val.label)}
                    value={addresses.find((item) => item.label === address)}
                    isSearchable={false}
                    defaultValue={[{ value: "", label: "" }]}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>или введите</Label>
                  <Input
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Кол-во мест</Label>
                  <Input
                    type="number"
                    name="count"
                    value={count}
                    onChange={(e) => setCount(e.target.value)}
                  />
                </FormGroup>

                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      id="a4"
                      name="format"
                      label="a4"
                      value="a4"
                    />
                    a4
                  </Label>
                </FormGroup>

                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      id="a5"
                      name="format"
                      label="a5"
                      value="a5"
                    />
                    a5
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Button type="submit" color="primary">
                    Печать
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
