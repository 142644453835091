import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ChangeLogModal } from "components/ChangeLogModal";
import { getChangeLogSupply } from "services/api";

import "./changeLog.scss";

export const ChangeLogSupply = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [changeLog, setChangeLog] = useState([]);

  const getAndSetChangeLog = () => {
    setLoading(true);
    getChangeLogSupply(id)
      .then((res) => {
        setChangeLog(res);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!open) return;
    getAndSetChangeLog();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ChangeLogModal
      open={open}
      setOpen={setOpen}
      changeLog={changeLog}
      loading={loading}
    />
  );
};
