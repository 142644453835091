import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";

import {
  getMisgradingOne,
  createMisgrading,
  carryOutMisgrading,
} from "services/apiWarehouse";
import { dateFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import { MisgradingProductTableNew } from "features/Misgradings/MisgradingProductTableNew";
import { MisgradingProductTable } from "features/Misgradings/MisgradingProductTable";

import "./misgradingOne.scss";

export const MisgradingOnePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(!id ? true : false);
  const [number, setNumber] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const [admin, setAdmin] = useState({});
  const [carriedOut, setCarriedOut] = useState(false);
  const [products, setProducts] = useState([]);

  const getAndSetMisgradingOne = () => {
    getMisgradingOne(id)
      .then((res) => {
        setNumber(res.number);
        setProducts(res.products);
        setCreatedAt(res.createdAt);
        setComment(res.comment);
        setReason(res.reason);
        setAdmin(res.admin);
        setCarriedOut(res.carriedOut);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetMisgradingOne();
  }, []);

  const createMisgradingHandler = () => {
    setLoading(true);
    createMisgrading({
      comment,
      reason,
      products,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Пересортица успешно создана");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutMisgrading({
      id,
      carryOut: !carriedOut,
    })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        if (res.carriedOut) {
          toast.success("Пересортица успешно проведена");
        } else {
          toast.success("Пересортица успешно распроведена");
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новая пересортица`;
    }

    return `Пересортица № ${number || ""}`;
  };

  return (
    <Container fluid className="p-0 misgrading-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        <>
          {!isNew && (
            <>
              <div className="misgrading-one__date-block">
                <Label>Дата</Label>
                <InputMask
                  id="date"
                  name="date"
                  type="text"
                  onChange={() => {}}
                  value={dateFormat(createdAt)}
                  mask={"99.99.9999"}
                  className="form-control date"
                  disabled
                />
              </div>
              <div className="misgrading-one__carried-out-button">
                {carriedOut && <CheckCircle color="green" />}

                <Button
                  color="primary"
                  onClick={carryOutHandler}
                  className="button-wide ml-1"
                >
                  {carriedOut ? `Распровести` : `Провести`}
                </Button>
              </div>
            </>
          )}
        </>
      </HeaderPage>

      <Card className="misgrading-one__form mb-0">
        <CardBody className="pb-3">
          {!isNew && (
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Автор</Label>
              </Col>
              <Col md="4">
                <b>{admin.name}</b>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <Label className="text-sm-right">Причина</Label>
              <Input
                name="reason"
                placeholder="Причина"
                value={reason || ""}
                onChange={(e) => setReason(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment || ""}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {isNew ? (
        <MisgradingProductTableNew
          products={products}
          setProducts={setProducts}
        />
      ) : (
        <MisgradingProductTable
          products={products}
          setProducts={setProducts}
          loading={loading}
          setLoading={setLoading}
          carriedOut={carriedOut}
        />
      )}
      <Card>
        <CardBody>
          {isNew && (
            <Button
              color="primary"
              onClick={createMisgradingHandler}
              className="button-wide"
            >
              Создать
            </Button>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
