import React, { useEffect, useRef, useState } from "react";

import { ProductHead } from "../ProductHead";

import { priceFormat } from "utils/format";
import { PAGE_HEAD_HEIGHT, PAGE_HEIGHT, Row } from "../Row";

import "./products.scss";
import Moment from "react-moment";

const PAGE_BOTTOM_HEIGHT = 286;

export const Products = ({ utd, setPage, page, parent }) => {
  const ref = useRef(null);

  const [productLast, setProductLast] = useState(null);
  const [top, setTop] = useState(null);
  const [bottom, setBottom] = useState(null);

  useEffect(() => {
    // console.log(ref);

    if (ref.current && parent) {
      // console.log('ref.current.getBoundingClientRect().top', ref.current.getBoundingClientRect().top - parent);
      setTop(ref.current.getBoundingClientRect().top - parent);
      setBottom(ref.current.getBoundingClientRect().bottom + PAGE_BOTTOM_HEIGHT - parent);
    }

  }, [ref, parent]);


  // useEffect(() => {
  //   setProductLast(utd.products[utd.products.length - 1]);

  // }, [utd]);


  const separator = () => {

    const pageHeight = page > 1 ? PAGE_HEIGHT - PAGE_HEAD_HEIGHT : PAGE_HEIGHT;

    if (!top && !bottom) return null;

    console.log('top', top);
    console.log('bottom', bottom);
    console.log('page', page);
    console.log('PAGE_HEIGHT + (pageHeight * (page - 1)', PAGE_HEIGHT + (pageHeight * (page - 1)));

    if (
      bottom >= (PAGE_HEIGHT + (pageHeight * (page - 1)))
      &&
      top <= (PAGE_HEIGHT + (pageHeight * (page - 1)))
    ) {
      return <>
        <tr className="pageSeparator"><td colSpan={21}></td></tr>
        <tr className="headTitle pageBreakBefore">
          <td colSpan={21}>
            Универсальный передаточный документ № {utd.number} от {utd.shippingDate && <Moment format="D MMMM Y">{utd.shippingDate}</Moment>} г.
          </td>
        </tr>
        <ProductHead />
      </>
    }

    return null;
  }

  const lastRowRender = () => {
    const productLast = utd.products[utd.products.length - 1];
    return <tr ref={ref} className="product">
      <td>{productLast?.index}</td>
      <td>{productLast?.article}</td>
      <td>{productLast?.index}</td>
      <td>{productLast?.name}</td>
      <td>--</td>
      <td>796</td>
      <td>шт</td>
      <td className="right">{productLast?.count}</td>
      <td className="right">{priceFormat(productLast?.priceWithoutNds, 2)}</td>
      <td className="right">{priceFormat(productLast?.summ - productLast?.nds, 2)}</td>
      <td>без<br />акциза</td>
      <td>20%</td>
      <td className="right">{priceFormat(productLast?.nds, 2)}</td>
      <td className="right">{priceFormat(productLast?.summ, 2)}</td>
      <td>{productLast?.countryCode || "-"}</td>
      <td>{productLast?.countryName || "-"}</td>
      <td>{productLast?.gtd || "-"}</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td></td>
    </tr>
  }

  return (
    <>
      <table className="products">
        <tbody>
          <ProductHead />

          {utd.products.slice(0, -1).map(product =>
            <Row product={product} utd={utd} setPage={setPage} parent={parent} />
          )}



          {separator()}




          {/* <Row product={utd.products[utd.products.length - 1]} utd={utd} lastRow={true} ref={ref} /> */}


          {lastRowRender()}




          <tr>
            <td colSpan="9"><b>Всего к оплате</b></td>
            <td className="right">{priceFormat(utd.products.reduce((acc, product) => acc + (product.summ - product.nds), 0), 2)}</td>
            <td colSpan="2" className="center">Х</td>
            <td className="right">{priceFormat(utd.nds, 2)}</td>
            <td className="right">{priceFormat(utd.summ, 2)}</td>
            <td colSpan="6"></td>
            <td></td>
          </tr>


        </tbody>
      </table>
    </>
  );
};