import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Content from "../components/Content";
import { ToastContainer } from "react-toastify";

const Dashboard = ({ children, title = "" }) => {
  const history = useHistory();

  useEffect(() => {
    if (
      !localStorage.getItem("accessToken") ||
      !localStorage.getItem("refreshToken")
    ) {
      history.push("/sign-in");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Wrapper>
        {title && (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
        <Sidebar />
        <Main>
          <Content>{children}</Content>
        </Main>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Wrapper>
    </React.Fragment>
  );
};

export default Dashboard;
