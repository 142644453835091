import React, { useContext, useReducer } from "react";

export const OrdersContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, statuses: action.statuses }
    case 'status':
      return { ...state, status: action.status }
    case 'loading':
      return { ...state, loading: action.loading }
    case 'products':
      return { ...state, products: action.products }
    case 'removeProduct':
      return { ...state, id: action.id }
    default: return state;
  }
}

export const useOrders = () => {
  return useContext(OrdersContext);
}

export const OrdersProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, {
    statuses: [],
    status: '',
    statusLoading: false,
    products: []
  });

  const setStatuses = statuses => dispatch({ type: 'set', statuses });

  const statusUpdate = status => dispatch({ type: 'status', status });

  const setLoading = loading => dispatch({ type: 'loading', loading });

  const setProducts = products => dispatch({ type: 'products', products });

  const removeProduct = id => dispatch({ type: 'removeProduct', id });

  return (
    <OrdersContext.Provider value={{
      statuses: state.statuses,
      status: state.status,
      loading: state.loading,
      products: state.products,
      setStatuses,
      statusUpdate,
      setLoading,
      setProducts,
      removeProduct
    }}>
      {children}
    </OrdersContext.Provider>
  )
}