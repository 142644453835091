import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateTimeFormat } from "utils/format";
import { Button } from "reactstrap";
import { Trash } from "react-feather";

import "./crossTable.scss";

export const CrossTable = ({ cross, remove, onSort }) => {

  const tableColumns = [
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "10%" };
      }
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "10%" };
      }
    },
    {
      dataField: "articleAnalog",
      text: "Артикул",
      headerStyle: () => {
        return { width: "10%" };
      }
    },
    {
      dataField: "brandAnalog",
      text: "Бренд",
      headerStyle: () => {
        return { width: "10%" };
      }
    },
    {
      dataField: "analogueCode",
      text: "Код аналога",
      headerStyle: () => {
        return { width: "10%" };
      }
    },
    {
      dataField: "createdAt",
      text: "Дата",
      formatter: (cell, row) => dateTimeFormat(cell),
      headerStyle: () => {
        return { width: "7%" };
      },
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      }
    },
    {
      dataField: "source",
      text: "Источник",
      headerStyle: () => {
        return { width: "7%" };
      },
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      }
    },
    {
      dataField: "file",
      text: "Файл",
      headerStyle: () => {
        return { width: "10%" };
      }
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => <Button
        color="link"
        onClick={() => remove(row.id)}
      >
        <Trash color="red" size={20} />
      </Button>,
    }
  ];

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={cross}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        classes="pointer"
      />
    </>
  );
};
