export const VALIDATION = [
  "title",
  "article",
  "brand",
  "name",
  "price",
  "exist",
  "provider",
  "delivery",
  "deliveryGarant",
  "period",
];
