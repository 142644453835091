import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";

import "./status.scss";

export const StatusOrderToProvider = ({
  children,
  status,
  statuses,
  statusHandler,
}) => {
  const [hover, setHover] = useState(false);

  const renderDropdown = () =>
    statuses.map((item) => (
      <DropdownItem
        key={item.id}
        style={{ backgroundColor: item?.color }}
        className="status__item"
        onClick={() => statusHandler(item)}
      >
        {item?.name}
      </DropdownItem>
    ));

  const styles = () => {
    if (hover) {
      return {
        backgroundColor: status?.color,
        color: "white",
        borderColor: status?.color,
      };
    }

    return {
      color: status?.color,
      borderColor: status?.color,
    };
  };

  if (!children) return null;

  return (
    <UncontrolledButtonDropdown className="status">
      <DropdownToggle
        size="sm"
        style={styles()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {children}
      </DropdownToggle>
      {renderDropdown() && <DropdownMenu>{renderDropdown()}</DropdownMenu>}
    </UncontrolledButtonDropdown>
  );
};
