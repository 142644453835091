import { ButtonAddToCart } from "features/Dashboard/ButtonAddToCart";
import { priceFormat } from "utils/format";

export const tableColumns = [
  {
    dataField: "article",
    text: "Артикул",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    editable: false,
  },
  {
    dataField: "brand",
    text: "Бренд",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    editable: false,
  },
  {
    dataField: "name",
    text: "Наименование",
    headerStyle: (colum, colIndex) => {
      return { width: "27%" };
    },
    editable: false,
  },
  {
    dataField: "pricePurchase",
    text: "Закупка",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    classes: () => "right",
    headerClasses: () => "right",
    formatter: (cell) => {
      return `${priceFormat(cell)} р`;
    },
    editable: false,
  },
  {
    dataField: "price",
    text: "Продажа",
    headerStyle: (colum, colIndex) => {
      return { width: "6%" };
    },
    formatter: (cell) => `${priceFormat(cell)} р`,
    classes: () => "editable right",
    headerClasses: () => "right",
    validator: (newValue) => {
      if (isNaN(newValue)) {
        return {
          valid: false,
          message: "Должны быть только цифры",
        };
      }
      if (newValue > 1000000) {
        return {
          valid: false,
          message: "Слишком большая цена",
        };
      }
      if (newValue < 1) {
        return {
          valid: false,
          message: "Цена не может быть меньше 1",
        };
      }
      return true;
    },
  },
  {
    dataField: "exist",
    text: "Наличие",
    headerStyle: (colum, colIndex) => {
      return { width: "6%" };
    },
    headerClasses: () => "right",
    classes: (cell, row) => (row.selected ? "right selected" : "right"),
    editable: false,
  },
  {
    dataField: "existReserve",
    text: "Резерв",
    headerStyle: (colum, colIndex) => {
      return { width: "6%" };
    },
    headerClasses: () => "right",
    classes: (cell) => (cell ? "right red-bg" : "right"),
    formatter: (cell, row) => (
      <div>
        {cell}
        {row.dateReserve && (
          <div className="reserve">
            {JSON.parse(row.dateReserve).join(", ")}
          </div>
        )}
      </div>
    ),
    editable: false,
  },
  {
    dataField: "providerName",
    text: "Поставщик",
    headerStyle: (colum, colIndex) => {
      return { width: "12%" };
    },
    editable: false,
  },
  {
    dataField: "action",
    text: "В корзину",
    headerStyle: (colum, colIndex) => {
      return { width: "12%" };
    },
    formatter: (_, row) => (
      <div className="d-flex justify-content-between">
        <ButtonAddToCart row={row} />
      </div>
    ),
    editable: false,
  },
];

export const tableBrandColumns = [
  {
    dataField: "brand",
    text: "Бренд",
    classes: () => "editable",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    },
  },
  {
    dataField: "partNumber",
    text: "Артикул",
    classes: () => "editable",
    headerStyle: (colum, colIndex) => {
      return { width: "25%" };
    },
  },
  {
    dataField: "name",
    text: "Наименование",
    classes: () => "editable",
  },
];

export const tableEstimateColumns = [
  {
    dataField: "brand",
    text: "Бренд",
    headerStyle: (colum, colIndex) => {
      return { width: "50%" };
    },
    classes: () => "pointer",
  },
  {
    dataField: "article",
    text: "Артикул",
    headerStyle: (colum, colIndex) => {
      return { width: "50%" };
    },
  },
];
