import React from "react";

import "./productHead.scss";

export const ProductHead = () => {
  return (
    <>
      <tr className="productHead">
        <td rowSpan="2" style={{ width: "16px" }}>
          №<br />п/п
        </td>
        <td rowSpan="2" style={{ width: "70px" }}>
          Код товара/<br />работ,<br />услуг
        </td>
        <td rowSpan="2" style={{ width: "16px" }}>
          №<br />п/п
        </td>
        <td rowSpan="2" style={{ width: "120px" }}>
          Наименование товара (описание выполненных работ, оказанных услуг), имущественного права
        </td>
        <td rowSpan="2" style={{ width: "30px" }}>
          Код<br />вида<br />товара
        </td>
        <td colSpan="2" style={{ width: "80px" }}>
          Единица<br />
          измерения
        </td>
        <td rowSpan="2" style={{ width: "40px" }}>
          Коли-<br />
          чество
          (объем)
        </td>
        <td rowSpan="2" style={{ width: "60px" }}>
          Цена <br />(тариф)<br />
          за<br />
          единицу<br />измерения
        </td>
        <td rowSpan="2" style={{ width: "60px" }}>
          Стоимость<br />товаров<br />(работ,<br />услуг),<br />имущест-<br />
          венных прав без налога - всего
        </td>
        <td rowSpan="2" style={{ width: "40px" }}>
          В том<br />
          числе<br />
          сумма<br />
          акциза
        </td>
        <td rowSpan="2" style={{ width: "30px" }}>
          Нало-<br />
          говая ставка
        </td>
        <td rowSpan="2" style={{ width: "60px" }}>
          Сумма<br />налога,<br />предъяв-<br />
          ляемая<br />покупателю
        </td>
        <td rowSpan="2" style={{ width: "80px" }}>
          Стоимость<br />товаров<br />(работ,<br />услуг),<br />имущест-<br />
          венных прав с налогом -<br />всего
        </td>
        <td colSpan="2" style={{ width: "100px" }}>
          Страна<br />
          происхождения<br />товара
        </td>
        <td rowSpan="2" style={{ width: "90px" }}>
          Регистрационный<br />номер<br />декларации<br />на товары или<br />регистрационный
          номер партии<br />товара,<br />подлежащий
          прослеживаемости
        </td>
        <td colSpan="2" style={{ width: "50px" }}>
          Количественная единица измерения товара, используемая в целях прослеживаемости
        </td>
        <td rowSpan="2" style={{ width: "50px" }}>
          Количество товара, подлежащего прослеживаемости, в количественной единице измерения товара
        </td>
        <td rowSpan="2" style={{ width: "50px" }}>
          Стоимость товара, подлежащего прослеживаемости, без налога
        </td>
      </tr>
      <tr className="productHead">
        <td style={{ width: "30px" }}>
          код
        </td>
        <td style={{ width: "50px" }}>
          условное обозна-
          чение (нацио-
          нальное)
        </td>
        <td style={{ width: "30px" }}>
          циф-<br />
          ро-<br />
          вой<br />код
        </td>
        <td style={{ width: "60px" }}>
          краткое<br />наиме-<br />
          нование
        </td>
        <td style={{ width: "20px" }}>
          код
        </td>
        <td style={{ width: "40px" }}>
          условное обозначение
        </td>
      </tr >
      <tr className="productHead">
        <td>А</td>
        <td>Б</td>
        <td className="small">1</td>
        <td className="small">1а</td>
        <td className="small">1б</td>
        <td className="small">2</td>
        <td className="small">2а</td>
        <td className="small">3</td>
        <td className="small">4</td>
        <td className="small">5</td>
        <td className="small">6</td>
        <td className="small">7</td>
        <td className="small">8</td>
        <td className="small">9</td>
        <td className="small">10</td>
        <td className="small">10а</td>
        <td className="small">11</td>
        <td className="small">12</td>
        <td className="small">12а</td>
        <td className="small">13</td>
        <td className="small">14</td>
      </tr>
    </>
  );
};