import { dateFormat } from "utils/format";

export const tableColumns = [
  {
    dataField: "article",
    text: "Артикул",
    classes: () => "editable",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    },
  },
  {
    dataField: "brand",
    text: "Бренд",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
  },
  {
    dataField: "createdAt",
    text: "Дата",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    },
    formatter: (cell) => dateFormat(cell),
  },
  {
    dataField: "site",
    text: "С клиентского сайта",
    headerStyle: (colum, colIndex) => {
      return { width: "40%" };
    },
    formatter: (cell) => (cell ? "Да" : ""),
  },
];
