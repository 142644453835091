import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import { getStoreKeeperReport } from "services/apiAnalitics";
import { dateFormatU, priceFormat } from "utils/format";
import useQuery from "utils/useQuery";
import { MySpinner } from "components/MySpinner";

registerLocale("ru", ru);

export const StoreKeeperPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [loading, setLoading] = useState(false);
  const [storeKeeperReport, setStoreKeeperReport] = useState([]);
  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );
  const [isShowReset, setIsShowReset] = useState(false);

  const getAndSetStoreKeeperReport = () => {
    setLoading(true);
    getStoreKeeperReport({
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
    })
      .then(setStoreKeeperReport)
      .catch((e) => {
        toast.success(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const resetFilter = () => {
    setDateStart("");
    setDateEnd("");
    localStorage.removeItem("filterAdmin");
  };

  useEffect(() => {
    const url = new URLSearchParams();

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd]);

  const isSearch = () => {
    if (dateStart || dateEnd) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    getAndSetStoreKeeperReport();
  };

  useEffect(() => {
    getAndSetStoreKeeperReport();
  }, []);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetStoreKeeperReport();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd]);

  return (
    <Container fluid className="p-0 storeKeeper">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Отчет по кладовщикам</h1>
          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Дата</Label>
                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <div class="react-bootstrap-table">
        <table class="table table-hover pointer">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Кладовщик</th>
              <th className="right" style={{ width: "8%" }}>
                Кол-во товара
              </th>
              <th className="right" style={{ width: "8%" }}>
                Сумма
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {storeKeeperReport.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td className="right">{item.count}</td>
                <td className="right bold">{priceFormat(item.summ)}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
};
