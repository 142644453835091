import React from 'react';
import { Spinner } from "reactstrap";
import classNames from 'classnames';

import './spinner.scss';

export const MySpinner = ({ overlay = null, width = '3rem', height = '3rem' }) => {
  return (
    <div className={
      classNames(
        'spinner',
        { 'overlay': overlay }
      )
    }>
      <Spinner color="danger" style={{ width, height }} />
    </div>
  );
};