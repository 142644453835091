import React, { useEffect, useState } from "react";

import "./printLabel.scss";
import classNames from "classnames";

export const PrintLabel = ({ order, utd, quantityLabels = 1 }) => {
  return (
    <>
      <style>
        {`@media print {
            @page {
              size: A4 portrait;
              margin: 0;
				    }}
          }`
        }
      </style>
      {[...Array(quantityLabels)].map((_, i) =>
        <div className={classNames("printLabel", { "pageBreakBefore": i % 2 === 0 && i !== 0 })}>
          <table>
            <tbody>
              {order.delivery?.id !== 1 &&
                <tr>
                  <td colspan="2" align="center">
                    <span className="title">ЛИДЕР ТРАК</span>
                  </td>
                </tr>
              }
              <tr>
                <td>
                  Отправитель:
                </td>
                <td>
                  ИНН {utd.seller?.inn} {utd.seller?.nameShort} <br />тел. {utd.seller?.phone}, {utd.seller?.phone}
                </td>
              </tr>
              <tr>
                <td>
                  Получатель:
                </td>
                <td>
                  <div className="customerName">
                    {order.customer?.name}
                  </div>
                  <div>
                    ИНН {order.customer?.orgInn}
                  </div>
                  <div>
                    тел. {order.customer?.phone}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  № заказа:
                </td>
                <td className="orderNumber">
                  {order.number}, Место: {i + 1} / {quantityLabels}
                </td>
              </tr>

              {order.delivery?.id === 1 ?
                <tr>
                  <td colspan="2">
                    <div class="pickup">САМОВЫВОЗ</div>
                  </td>
                </tr>
                :
                <>
                  <tr>
                    <td>
                      Город доставки:
                    </td>
                    <td>
                      {order.address?.city}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Адрес доставки:
                    </td>
                    <td>
                      {order.address?.address}
                    </td>
                  </tr>
                  <>{order.delivery?.id === 3 &&
                    <>
                      <tr>
                        <td>
                          Транспортная компания:
                        </td>
                        <td>
                          {order.address?.transportCompany}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Плательщик:
                        </td>
                        <td>
                          {order.deliverySettings.senderIsPayer ? 'ЛИДЕР ТРАК' : order.customer?.name}
                        </td>
                      </tr>
                    </>
                  }</>
                  <tr>
                    <td>
                      Контакты получателя:
                    </td>
                    <td>
                      {order.deliverySettings.contact}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Комментарий к отгрузке:
                    </td>
                    <td>
                      {order.deliverySettings.comment}
                    </td>
                  </tr>
                </>
              }
            </tbody>
          </table>
        </div>
      )}</>
  );
};