import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { CloseButton } from "components/CloseButton";
import { createCustomsDeclaration } from "services/apiSupply";
import { toast } from "react-toastify";
import { MySpinner } from "components/MySpinner";

export const CreateCustomDeclarationModal = ({ id, getAndSetSupply }) => {
  const [customsDuty, setCustomsDuty] = useState("");
  const [customsFine, setCustomsFine] = useState("");
  const [gtd, setGtd] = useState("");
  const [needValid, setNeedValid] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const createCustomsDeclarationHandler = () => {
    setLoading(true);
    createCustomsDeclaration({
      supply: id,
      customsDuty,
      customsFine,
      gtd,
    })
      .then((res) => {
        toast.success("Таможенная декларация успешно создана");
        setCustomsDuty("");
        setCustomsFine("");
        setGtd("");
        setOpenModal(false);
        getAndSetSupply();
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const customsDutyHandler = (e) => {
    const summ = e.target.value.replace(",", ".").replace(" ", "");

    if (summ === "" || /^[0-9\b\.]+$/.test(summ)) {
      setCustomsDuty(summ);
    }
  };

  const customsFineHandler = (e) => {
    const summ = e.target.value.replace(",", ".").replace(" ", "");

    if (summ === "" || /^[0-9\b\.]+$/.test(summ)) {
      setCustomsFine(summ);
    }
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpenModal(true)}
        className="button-wide mr-3"
      >
        Создать там. декл.
      </Button>

      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <div className="modal-title-text">Создать таможенную декларацию</div>
          <CloseButton onClick={() => setOpenModal(false)} />
        </ModalHeader>
        {loading && <MySpinner overlay />}
        <ModalBody className="m-3">
          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>Таможенный сбор</Label>
                <Input
                  name="customsDuty"
                  placeholder="Таможенный сбор"
                  value={customsDuty}
                  onChange={customsDutyHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>Таможенный штраф</Label>
                <Input
                  name="customsFine"
                  placeholder="Таможенный штраф"
                  value={customsFine}
                  onChange={customsFineHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label>Номер декларации</Label>
                <Input
                  name="gtd"
                  placeholder="Номер декларации"
                  onChange={(e) => setGtd(e.target.value)}
                  value={gtd}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              onClick={createCustomsDeclarationHandler}
              color="primary"
              className="button-wide"
            >
              Создать
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
