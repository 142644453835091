import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { Trash } from "react-feather";

import { getCrossFiles, removeCrossFile, uploadCross } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { dateTimeFormat } from "utils/format";

export const CrossUploadPage = () => {
  const query = useQuery(useLocation);
  const pathname = useLocation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [crossFiles, setCrossFiles] = useState([]);
  const [crossFilesCount, setCrossFilesCount] = useState(0);
  const [success, setSuccess] = useState(null);
  const inputFile = useRef();
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const getAndSetCrossFiles = () => {
    getCrossFiles({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
    })
      .then((res) => {
        setCrossFiles(res.items);
        setCrossFilesCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetCrossFiles();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, currentPage]);

  useEffect(() => {
    setLoading(true);
    getAndSetCrossFiles();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    uploadCross(formData)
      .then((res) => {
        getAndSetCrossFiles();
        setSuccess(
          "Файл успешно загружен на сервер. Заливка кроссов будет начата в течение 2 минут!"
        );
      })
      .catch((e) => setLoading(false))
      .finally();
    // eslint-disable-next-line
  }, [file]);

  const getFile = () => {
    if (inputFile) {
      inputFile.current.click();
    }
  };

  const remove = (id) => {
    setLoading(true);
    removeCrossFile({ id })
      .then((res) => {
        getAndSetCrossFiles();
        setSuccess("Файл и кроссы успешно удалены!");
      })
      .catch((e) => {});
  };

  return (
    <Container fluid className="p-0 crossUpload">
      <h1 className="h3 mb-3">Заливка кроссов</h1>
      <Card>
        <CardBody>
          <Form className="mb-3">
            <Link to="/reference/cross" className="btn btn-primary mb-2">
              Вернуться к списку кроссов
            </Link>

            <Row>
              <Col lg="2">
                <div className="with-link">
                  <ButtonGroup>
                    <Button color="primary" onClick={getFile} className="mr-2">
                      <FontAwesomeIcon icon={faUpload} /> Загрузить
                    </Button>
                  </ButtonGroup>

                  <Input
                    type="file"
                    id="file"
                    hidden
                    innerRef={inputFile}
                    onChange={(e) => setFile(e.target.files[0])}
                  />

                  <a href="https://api.lider-truck.ru/files/templates/cross.xlsx">
                    Скачать шаблон
                  </a>
                </div>
              </Col>
            </Row>
          </Form>

          {success && <Alert color="success">{success}</Alert>}
          {loading && <MySpinner overlay />}

          <div class="react-bootstrap-table">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>Название файла</th>
                  <th style={{ width: "25%" }}>Файл</th>
                  <th style={{ width: "10%" }}>Дата загрузки</th>
                  <th style={{ width: "7%" }}>Загружено</th>
                  <th style={{ width: "7%" }}>Строк в файле</th>
                  <th style={{ width: "10%" }}>Статус</th>
                  <th style={{ width: "5%" }}></th>
                </tr>
              </thead>
              <tbody>
                {crossFiles.map((item) => (
                  <tr key={item.id}>
                    <td style={{ width: "25%" }}>{item.name}</td>
                    <td style={{ width: "25%" }}>
                      <a href={item.fileLink}>{item.file}</a>
                    </td>
                    <td style={{ width: "10%" }}>
                      {dateTimeFormat(item.createdAt)}
                    </td>
                    <td style={{ width: "7%" }}>{item.count}</td>
                    <td style={{ width: "7%" }}>{item.countInFile}</td>
                    <td style={{ width: "10%" }}>{item.status}</td>
                    <td style={{ width: "5%" }}>
                      <Button
                        color="link"
                        size="sm"
                        onClick={() => remove(item.id)}
                      >
                        <Trash color="red" size={20} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            totalCount={crossFilesCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
