import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Trash } from "react-feather";
import { Col, Row } from "reactstrap";
import { addRelatedProduct, removeRelatedProduct } from "services/api";
import { toast } from "react-toastify";

import { TableButton } from "components/TableButton";
import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";

export const RelatedProducts = ({
  id,
  setLoading,
  relatedProducts,
  getAndSetNomenclatureOne,
}) => {
  const [article, setArticle] = useState("");
  const [nomenclature, setNomenclature] = useState(null);

  const removeRelatedProductHandler = (releatedProductId) => {
    setLoading(true);
    removeRelatedProduct({ id: releatedProductId })
      .then(() => {
        toast.success("Сопутствующий товар успешно удален");
        getAndSetNomenclatureOne();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!nomenclature) return;
    setLoading(true);
    addRelatedProduct({
      id,
      relatedProduct: nomenclature,
    })
      .then(() => {
        toast.success("Сопутствующий товар успешно добавлен");
        getAndSetNomenclatureOne();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  }, [nomenclature]);

  return (
    <>
      <div className="mb-3">
        <Row>
          <Col md={12}>
            <AutocompleteArticle
              article={article}
              setArticle={setArticle}
              setNomenclature={setNomenclature}
            />
          </Col>
        </Row>
      </div>
      <table className="table table-hover">
        <tbody>
          {relatedProducts.map((item) => (
            <tr key={item.id}>
              <td>
                <Link
                  to={`/reference/nomenclature?search=${item.nomenclature?.article}`}
                  target="_blank"
                >
                  {item.nomenclature?.article}
                </Link>
              </td>
              <td>{item.nomenclature?.brand}</td>
              <td>{item.nomenclature?.name}</td>
              <td>
                <TableButton
                  Icon={Trash}
                  onClick={() => removeRelatedProductHandler(item.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
