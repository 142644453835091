import React, { useEffect, useRef, useState } from "react";
import { X as XIcon } from "react-feather";
import Select from "react-select";

import "./selectDriver.scss";

export const SelectDriver = ({ row, admins, driverHandler }) => {
  const [driver, setDriver] = useState(null);
  const [shown, setShown] = useState(false);
  const rootEl = useRef(null);

  useEffect(() => {
    const onClick = (e) =>
      rootEl.current?.contains(e.target) || setShown(false);
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  return (
    <>
      <span onClick={() => setShown(true)} className="selectDriver">
        {row.driver || "Выбрать водителя"}
        {row.driver &&
          <div className="removeDriver" onClick={() => driverHandler(row.id, "")}>
            <XIcon size={12} />
          </div>
        }
      </span >
      {shown && (
        <div ref={rootEl}>
          <Select
            name="driver"
            placeholder="Выберите водителя"
            className="react-select-container select-driver"
            classNamePrefix="react-select"
            options={admins.filter((item) => item.department === "drivers")}
            isSearchable
            isClearable
            value={driver}
            onChange={(val) => {
              setDriver(val);
              driverHandler(row.id, val?.value);
              setShown(false);
            }}
          />
        </div>
      )}
    </>
  );
};
