import { dateFormat, priceFormat } from "utils/format";
import { Cut } from "components/Cut";
export const tableColumns = [
  {
    dataField: "type",
    text: "Документ",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    },
    formatter: (cell, row) => <Cut>{cell}</Cut>,
  },
  {
    dataField: "customer",
    text: "Клиент",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    },
    formatter: (cell, row) => <Cut>{cell}</Cut>,
  },
  {
    dataField: "date",
    text: "Дата документа",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    formatter: (cell, row) => dateFormat(cell),
  },

  {
    dataField: "number",
    text: "Номер документа",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    editable: false,
  },
  {
    dataField: "debet",
    text: "Ушло (товаров/денег)",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    headerClasses: () => "right",
    classes: () => "right",
    formatter: (cell) => cell === 0 ? "" : priceFormat(cell),
  },
  {
    dataField: "credit",
    text: "Пришло (товаров/денег)",
    headerStyle: (colum, colIndex) => {
      return { width: "10%" };
    },
    headerClasses: () => "right",
    classes: () => "right",
    formatter: (cell) => cell === 0 ? "" : priceFormat(cell),
  },
  {
    dataField: "",
    text: "",
    headerStyle: (colum, colIndex) => {
      return { width: "20%" };
    }
  },
];
