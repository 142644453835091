import React from "react";

import "./barChart.scss";

export const BarChart = ({ color, count }) => (
  <div className="bar-chart">
    <div className={`bar-chart-${color}`}></div>
    <div className={`bar-chart-count ${color}`}>{count}</div>
  </div>
);
