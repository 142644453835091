import React from "react";
import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-perfect-scrollbar/dist/css/styles.css";

import "./estimateTable.scss";

export const EstimateTable = ({
  estimateFromFile,
  selectedRef,
  article,
  estimateHandler,
  scrollRef,
}) => {
  return (
    <div className="estimate">
      <div className="estimate-table">
        <div className="estimate-table__head">
          <div className="estimate-table__head-row">
            <div>Бренд:</div>
            <div>Артикул:</div>
          </div>
        </div>
        <div className="estimate-table__body">
          <PerfectScrollbar
            containerRef={(ref) => {
              scrollRef.current = ref;
            }}
          >
            {estimateFromFile.map((val) => (
              <div
                key={val.id}
                ref={article === val.article ? selectedRef : null}
                className={classNames("estimate-table__body-row", {
                  selected: article === val.article,
                })}
                onClick={() =>
                  estimateHandler({
                    brand: val.brand,
                    article: val.article,
                  })
                }
              >
                {/* <td>{val.inCart &&
                            <CheckIcon color="#36999f" size={15} />
                          }
                          </td> */}
                <div>{val.brand}</div>
                <div>{val.article}</div>
              </div>
            ))}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};
