import { useEffect, useState } from "react";

import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getMarkups } from "services/api";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { MarkupsTable } from "features/Markups/MarkupsTable";
import { Pagination } from "components/Pagination";

import "./markupModal.scss";

export const MarkupModal = ({
  openModal,
  setOpenModal,
  onClick
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [markups, setMarkups] = useState([]);
  const [markupsCount, setMarkupsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getAndSetMarkups = () => {
    getMarkups({
      count: pageSize,
      offset: (currentPage - 1) * pageSize
    })
      .then((res) => {
        setMarkups(res.items);
        setMarkupsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (openModal !== true) return;
    setLoading(true);
    getAndSetMarkups();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    setLoading(true);
    getAndSetMarkups();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="markup-modal"
    >
      {loading && <MySpinner overlay />}
      <ModalHeader>
        <div className="modal-title-text">
          Выбрать правило наценки
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row className="main-info">
          <Col>
            <MarkupsTable markups={markups} onClick={onClick} />
          </Col>
        </Row>

        <Pagination
          totalCount={markupsCount}
          currentPage={currentPage}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          onChangePageSize={setPageSize}
        />
      </ModalBody>
    </Modal>
  );
};
