import { TableButton } from "components/TableButton";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { Trash } from "react-feather";
import { toast } from "react-toastify";
import { removeCharacteristic, updateCharacteristic } from "services/api";

export const TYPES = [
  {
    value: "string",
    label: "Строка",
  },
  {
    value: "integer",
    label: "Число",
  },
];

export const CharacteristicsTable = ({
  characteristics,
  getAndSetCharacteristics,
  setLoading,
}) => {
  const editHandler = (oldData, newData, row) => {
    if (oldData === newData) {
      return;
    }
    setLoading(true);
    updateCharacteristic(row)
      .then((res) => {
        toast.success("Характеристика успешно сохранена");
        getAndSetCharacteristics();
        
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const removeCharacteristicHandler = (id) => {
    setLoading(true);
    removeCharacteristic({ id })
      .then((res) => {
        toast.success("Характеристика успешно удалена");
        getAndSetCharacteristics();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const tableColumns = [
    {
      dataField: "name",
      text: "Наименование",
      classes: () => "editable",
      formatter: (cell) => <span className="cute">{cell}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "24%" };
      },
    },
    {
      dataField: "unit",
      text: "Единица измерения",
      classes: () => "editable",
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
    },
    {
      dataField: "type",
      text: "Тип данных",
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      formatter: (cell) => {
        const type = TYPES.find((v) => v.value === cell);
        return type?.label;
      },
      editor: {
        type: Type.SELECT,
        options: TYPES,
      },
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "56%" };
      },
      formatter: (cell, row) => (
        <TableButton
          Icon={Trash}
          onClick={() => removeCharacteristicHandler(row.id)}
        />
      ),
      editable: false,
    },
  ];

  return (
    <BootstrapTable
      keyField="id"
      data={characteristics}
      columns={tableColumns}
      bootstrap4
      bordered={false}
      hover={true}
      classes="pointer"
      cellEdit={cellEditFactory({
        mode: "click",
        blurToSave: true,
        afterSaveCell: (oldData, newData, row) =>
          editHandler(oldData, newData, row),
      })}
    />
  );
};
