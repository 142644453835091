import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useLocation } from "react-router-dom";

import {
  estimateFileUpload,
  getEstimateFromFile,
  cartFileUpload,
  updatePriceCartByCustomer,
  suggestionNomenclatrue2,
  searchPrice2,
} from "services/api";
import { tableBrandColumns } from "./tableData";
import { OrderForm } from "features/Dashboard/OrderForm";
import { CartTable } from "features/Cart/CartTable";
import useQuery from "utils/useQuery";
import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import { useCart } from "features/Cart/CartContext";
import { CreateProductModal } from "features/Dashboard/CreateProductModal";
import { MySpinner } from "components/MySpinner";
import { HeaderPage } from "components/HeaderPage";
import { SearchResultTable } from "features/Dashboard/SearchResultTable";
import { DashboardToolbar } from "features/Dashboard/DashboardToolbar";
import { EstimateTable } from "features/Dashboard/EstimateTable";

import "./dashboard.scss";
import { NomenclatureModal } from "features/Dashboard/NomenclatureModal";
import { articleNormalize } from "utils/articleNormalizer";
import { toast } from "react-toastify";
import { useUser } from "features/Users/UserContext";

export const PROVIDERS = [
  "omega",
  "corona",
  "armtek",
  "favorit",
  "forum",
];

export const DashboardPage = ({ title }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const inputEstimateFile = useRef();
  const inputOrderFile = useRef(null);
  const { user } = useUser();
  const [result, setResult] = useState([]);
  const [analogs, setAnalogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [headerLoading, setHeaderLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [brands, setBrands] = useState([]);
  const [article, setArticle] = useState(query.get("article") || "");
  const [brand, setBrand] = useState(query.get("brand") || "");
  const [estimateFromFile, setEstimateFromFile] = useState([]);
  const [estimateFile, setEstimateFile] = useState(null);
  const [orderFile, setOrderFile] = useState(null);
  const [fileName, setFileName] = useState(query.get("file") || "");
  const [customer, setCustomer] = useState(
    JSON.parse(localStorage.getItem("customer")) || null
  );
  const [account, setAccount] = useState(null);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const { setCart, cart } = useCart();
  const [orderUploadLoading, setOrderUploadLoading] = useState(false);
  const [openNomenclatureModal, setOpenNomenclatureModal] = useState(false);
  const [id, setId] = useState(null);
  const [errors, setErrors] = useState({});
  const [apiProviders, setApiProviders] = useState({});

  const scrollRef = useRef(null);
  const selectedRef = useRef(null);

  const sortResult = (items) => {
    return items.sort(function (a, b) {
      if (a.inStock === false && b.inStock === true) {
        return 1;
      }

      if (a.inStock === true && b.inStock === false) {
        return -1;
      }

      if (a.inTransit === false && b.inTransit === true) {
        return 1;
      }

      if (a.inTransit === true && b.inTransit === false) {
        return -1;
      }

      if (a.price && b.price) {
        if (a.price > b.price) {
          return 1;
        }
        if (a.price < b.price) {
          return -1;
        }
      }
      return 0;
    });
  };

  const searchResultConvert = (res) => {
    const output = [];
    Object.entries(res.data).map(([brand, value]) => {
      Object.entries(value).map(([article, value]) => {
        value.offer.map((item) => {
          output.push({
            id: item.id,
            article: item.article,
            articleSearch: article,
            name: item.name,
            brand,
            nomenclature: {
              id: value.nomenclatureId,
            },
            dateArrival: item.dateArrival || null,
            inTransit: item.inTransit || false,
            exist: item.exist || 0,
            price: item.price || 0,
            fixPrice: item.fixPrice || null,
            pricePurchase: item.cost,
            existReserve: item.existReserve,
            providerId: item.providerId,
            providerName: item.providerName,
            warehouseId: item.warehouseId,
            warehouseName: item.warehouseName,
            priceListId: item.priceListId,
            priceListName: item.priceListName,
            our: item.inStock,
            inStock: item.inStock,
            dateUpdate: item.priceListDateUpdate
          });
        });
        return null;
      });
    });
    return output;
  };

  const searchPriceByProvider = (provider, article, brand, callback) => {
    setApiProviders(prev => ({
      ...prev, [provider]: {
        loading: true
      }
    }));
    searchPrice2({ article, brand, userId: account?.value, provider, adminId: user?.id })
      .then((res) => {



        if (res.error) {
          setErrors(prev => ({ ...prev, ...res.error }));
        }

        if (res.group) {
          setBrands(res.group);
          setLoading(false);
          if (res.group.length === 0) {
            setApiProviders({});
          }

          setIsSearchResult(true);

          return;
        }

        if (!res.data) {
          setApiProviders({});
          setLoading(false);
          return;
        }

        if (!brand && res.info?.brand) {
          setBrand(res.info.brand);
        }

        setApiProviders(prev => ({
          ...prev, [provider]: {
            responceTime: res.responceTime || null,
            loading: false
          }
        }));


        setResult((prev) =>
          sortResult([...prev,
          ...searchResultConvert(res).filter(
            (item) => item.articleSearch === articleNormalize(article) && item.brand === (brand || res.info?.brand)
          )
          ]));

        setAnalogs((prev) =>
          sortResult([...prev,
          ...searchResultConvert(res).filter(
            (item) => item.articleSearch !== articleNormalize(article) || item.brand !== (brand || res.info?.brand)
          )
          ]));

        setBrands([]);

        if (callback) {
          callback();
        }
      })
      .catch(e => {
        if (e.response?.status) {
          toast.error(`Ошибка 500 ${provider}`);
        }
      })
      .finally(() => {
        setProgress(prev => (prev + 1));
      });
  }

  const searchProviders = (article, brand = null) => {
    if (!user?.id) return;
    setResult([]);
    setAnalogs([]);
    setLoading(true);
    setProgress(0);
    setIsSearchResult(false);

    searchPriceByProvider("", article, brand, function () {
      PROVIDERS.forEach((provider, i) => {
        searchPriceByProvider(provider, article, brand);
      });
    });
  };

  const getAndSetPrice = (article, brand = null) => {
    if (loading) return;
    setBrands([]);
    if (!article) return;

    searchProviders(article, brand);
  };

  useEffect(() => {
    if (progress > PROVIDERS.length) {
      setLoading(false);
    }
  }, [progress]);

  useEffect(() => {
    if (!orderSuccess) return;
    setBrands([]);
    setArticle("");
    setBrand("");
  }, [orderSuccess]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (fileName) {
      url.append("file", fileName);
    }
    if (article) {
      url.append("article", article);
    }
    if (brand) {
      url.append("brand", brand);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    if (article && article.length < 2) {
      return;
    }

    getAndSetPrice(article, brand);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName, article, brand, user?.id]);

  useEffect(() => {
    if (customer) {
      localStorage.setItem("customer", JSON.stringify(customer));
    } else {
      localStorage.removeItem("customer");
    }

    if (!cart.length) return;

    updatePriceCartByCustomer({ customer: customer?.value })
      .then((res) => {
        setCart(res.cart);
      })
      .finally(() => { });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const estimateHandler = ({ article, brand }) => {
    if (!loading) {
      setArticle(article);
      setBrand(brand);
    }
  };

  const brandHandler = {
    onClick: (_, row) => {
      if (!loading) {
        setArticle(row.partNumber);
        setBrand(row.brand);
      }
    },
  };

  const getEstimateFile = () => {
    if (inputEstimateFile) {
      inputEstimateFile.current.click();
    }
  };

  const getOrderFile = () => {
    if (inputOrderFile) {
      inputOrderFile.current.click();
    }
  };

  useEffect(() => {
    if (!fileName) return;
    getEstimateFromFile({ fileName })
      .then((res) => {
        setEstimateFromFile(res);
        if (scrollRef.current && selectedRef.current) {
          scrollRef.current.scrollTop = selectedRef.current.offsetTop - 40;
        }
      })
      .finally();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!estimateFile) return;
    const formData = new FormData();
    formData.append("file", estimateFile);
    estimateFileUpload(formData)
      .then((res) => {
        setEstimateFromFile(res.items);
        setFileName(res.file);
      })
      .finally();
  }, [estimateFile]);

  useEffect(() => {
    if (!orderFile) return;
    setOrderUploadLoading(true);
    const formData = new FormData();
    formData.append("file", orderFile);
    cartFileUpload(formData)
      .then((res) => {
        setCart(res.cart);
        setCustomer({ label: res.customer?.name, value: res.customer?.id });
        localStorage.setItem(
          "customer",
          JSON.stringify({
            label: res.customer?.name,
            value: res.customer?.value,
          })
        );
      })
      .finally(() => {
        setOrderUploadLoading(false);
        inputOrderFile.current.value = "";
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFile]);

  return (
    <Container fluid className="p-0 dashboard">
      <HeaderPage title="Поиск детали" Element={CreateProductModal} />

      <Card className="mb-0">
        <CardBody className="pb-0">
          {headerLoading && <MySpinner overlay />}
          <Row>
            <Col lg="5">
              <form>
                <div>
                  <Label>Поиск запчастей</Label>
                  <AutocompleteArticle
                    searchButton={true}
                    article={article}
                    brand={brand}
                    setArticle={setArticle}
                    setBrand={({ id, name }) => setBrand(name)}
                    getAndSetPrice={getAndSetPrice}
                    size
                    func={suggestionNomenclatrue2}
                  />
                  {brand && <h5 className="mt-2">{brand}</h5>}
                </div>
              </form>
            </Col>
            <Col lg="7" className="dashboard__buttons-block flex">
              <DashboardToolbar
                fileName={fileName}
                setFileName={setFileName}
                getEstimateFile={getEstimateFile}
                setEstimateFile={setEstimateFile}
                inputEstimateFile={inputEstimateFile}
                getOrderFile={getOrderFile}
                inputOrderFile={inputOrderFile}
                setOrderFile={setOrderFile}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              {brands.length > 0 && (
                <BootstrapTable
                  rowEvents={brandHandler}
                  keyField="brand"
                  data={brands}
                  hover={true}
                  columns={tableBrandColumns}
                  bootstrap4
                  bordered={false}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div className="dashboard__search-result">
        {fileName && (
          <Card className="dashboard__search-left">
            <CardBody>
              <h4 className="mb-4">Проценка из файла</h4>

              <EstimateTable
                estimateFromFile={estimateFromFile}
                selectedRef={selectedRef}
                article={article}
                estimateHandler={estimateHandler}
                scrollRef={scrollRef}
              />
            </CardBody>
          </Card>
        )}


        <Row className="dashboard__search-right">
          <Col lg="12">
            <div className="results">
              <Card className="mb-0">
                <CardBody className="pb-1">
                  <h3>Результаты поиска {loading && <MySpinner />}</h3>
                  {errors && <ul className="errors">
                    {Object.entries(errors).map(([key, error]) => <li key={key}>
                      {key}: {error}
                    </li>)}
                  </ul>}

                  <div className="apiResults">
                    {Object.entries(apiProviders).map(([provider, info]) =>
                      <div key={provider} className="apiResult">
                        {info?.loading && <MySpinner width="10px" height="10px" />}
                        {provider || "Склад + Тракмоторс"}{" "}
                        {info?.responceTime && `${info?.responceTime} c`}
                      </div>
                    )}
                  </div>

                  {isSearchResult && !result.length && !analogs.length && !brands.length && (
                    <h4>Ничего не найдено. Измените параметры поиска</h4>
                  )}
                </CardBody>
              </Card>
              <SearchResultTable
                result={result}
                setId={setId}
                setOpenNomenclatureModal={setOpenNomenclatureModal}
              />

              {analogs.length > 0 && (
                <>
                  <Card className="mb-0">
                    <CardBody className="pb-1">
                      <h3>Аналоги</h3>
                    </CardBody>
                  </Card>
                  <SearchResultTable
                    result={analogs}
                    setId={setId}
                    setOpenNomenclatureModal={setOpenNomenclatureModal}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <CartTable
        orderSuccess={orderSuccess}
        customer={customer}
        setCustomer={setCustomer}
      />

      <OrderForm
        customer={customer}
        setCustomer={setCustomer}
        account={account}
        setAccount={setAccount}
        getAndSetPrice={getAndSetPrice}
        setOrderSuccess={setOrderSuccess}
      />

      <NomenclatureModal
        openModal={openNomenclatureModal}
        setOpenModal={setOpenNomenclatureModal}
        id={id}
      />
    </Container >
  );
};
