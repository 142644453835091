import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  brandsSuggestion,
  updConvertDownload,
  updConvertUpdateData,
  updConvertUpload,
} from "services/api";
import { MySpinner } from "components/MySpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import "./convertUpd.scss";
import { SelectSearch } from "components/SelectSearch";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";

const TEMPLATES = [
  { label: "Стандартный УПД", value: "standard" },
  { label: "Устаревший УПД", value: "old" },
  { label: "Стандартный УПД 2 вариант", value: "standard2" },
  { label: "Омега", value: "omega" },
  { label: "Форум Авто", value: "forum" },
  { label: "Шате-М", value: "shate" },
  { label: "Траксервис", value: "trackservice" },
  { label: "Тракснаб", value: "tracksnab" },
  { label: "Амтел", value: "amtel" },
  { label: "Тракмоторс (tabload)", value: "trackmotors" },
  { label: "М-ГРУПП ЛОГИСТИК", value: "groupLogistic" },
  { label: "Столица", value: "stolitsa" },
];

export const ConvertUpdPage = () => {
  const inputFile = useRef();
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(false);
  const [updFile, setUpdFile] = useState(null);
  const [updData, setUpdData] = useState([]);

  const uploadFile = () => {
    if (!inputFile) return;
    inputFile.current.click();
  };

  useEffect(() => {
    if (!updFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", updFile);
    formData.append("template", template.value);
    updConvertUpload(formData)
      .then((res) => {
        setUpdData(res.products);
      })
      .finally(() => {
        setLoading(false);
        inputFile.current.value = null;
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updFile]);

  const getFile = () => {
    setLoading(true);
    updConvertDownload(updData)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "exportTo1c.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateData = (data) => {
    setLoading(true);

    updConvertUpdateData(data)
      .then((res) => {
        setUpdData(res.products);
      })
      .finally(() => {
        setLoading(false);
        inputFile.current.value = null;
      });
  };

  const changeArticle = (value, id) => {
    setUpdData((prev) => {
      const res = prev.map((i) => (i.id === id ? { ...i, article: value } : i));
      // updateData(res);
      return res;
    });
  };

  const selectBrand = (value, id) => {
    setUpdData((prev) => {
      const res = prev.map((i) => (i.id === id ? { ...i, brand: value } : i));
      updateData(res);
      return res;
    });
  };

  const renderBrands = ({ id = "", brands = [], brand = "" }) => {
    if (brand && brands.length <= 1) return brand;

    if (!brand) {
      return (
        <SelectSearch
          name="brand"
          placeholder="Выберите бренд"
          functionSuggestion={brandsSuggestion}
          value={[{ value: brand, label: brand }]}
          setValue={(res) => selectBrand(res.label, id)}
        />
      );
    }

    return (
      <select value={brand} onChange={(e) => selectBrand(e.target.value, id)}>
        {brands?.map((brand) => (
          <option value={brand.name} key={brand.id}>
            {brand.name}
          </option>
        ))}
      </select>
    );
  };

  const showDownload = () => (updData.find((v) => !v.brand) ? true : false);

  const renderUpd = () => (
    <>
      {updData.length > 0 && (
        <table className="table table-hover convert_upd">
          <thead>
            <tr>
              <th style={{ width: "2%" }}>№</th>
              <th style={{ width: "8%" }}>Артикул</th>
              <th style={{ width: "8%" }}>Бренд</th>
              <th style={{ width: "18%" }}>Наименование</th>
              <th style={{ width: "5%" }}>Заказы</th>
              <th style={{ width: "6%" }}>Цена</th>
              <th style={{ width: "6%" }}>Кол-во</th>
              <th style={{ width: "6%" }}>Сумма</th>
              <th style={{ width: "10%" }}>ID товара в 1C</th>
              <th style={{ width: "2%" }}></th>
            </tr>
          </thead>
          <tbody>
            {updData.map((product) => (
              <tr key={product.id}>
                <td style={{ width: "2%" }}>{product.id}</td>
                <td style={{ width: "8%" }}>
                  <input
                    type="text"
                    value={product.article}
                    onChange={(e) => changeArticle(e.target.value, product.id)}
                    onBlur={() => updateData(updData)}
                  />
                </td>
                <td style={{ width: "8%" }}>{renderBrands(product)}</td>
                <td style={{ width: "18%" }}>{product.name}</td>
                <td
                  style={{ width: "5%" }}
                  className={product.orderNumber ? "green-bg" : ""}
                >
                  {product.orderNumber}
                </td>
                <td style={{ width: "6%" }}>{product.price}</td>
                <td style={{ width: "6%" }}>{product.count}</td>
                <td style={{ width: "6%" }}>{product.summ}</td>
                <td style={{ width: "10%" }}>{product.id1C}</td>
                <td style={{ width: "2%" }}>
                  {!product.id1C && (
                    <CreateNomenclatureModal
                      row={product}
                      callBack={updateData}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );

  return (
    <>
      <Container fluid className="p-0">
        {loading && <MySpinner overlay />}
        <Card className="mb-0">
          <CardBody>
            <h1 className="h3 mb-3">Конвертация УПД</h1>

            <Row>
              <Col md="3">
                <Form>
                  <FormGroup>
                    <Label>Выберите шаблон</Label>
                    <Select
                      placeholder="Выберите шаблон"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={TEMPLATES}
                      onChange={setTemplate}
                      value={template}
                      isSearchable={false}
                      defaultValue={[{ value: "", label: "" }]}
                    />
                  </FormGroup>

                  <Button color="primary" onClick={uploadFile}>
                    <FontAwesomeIcon icon={faUpload} /> Загрузить УПД
                  </Button>
                  <Input
                    type="file"
                    id="file"
                    hidden
                    innerRef={inputFile}
                    onChange={(e) => setUpdFile(e.target.files[0])}
                  />
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {renderUpd()}

        <Card>
          <CardBody>
            <Row>
              <Col md="3">
                <Button
                  color="primary"
                  onClick={getFile}
                  disabled={showDownload()}
                >
                  Скачать УПД
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
