export const tableColumns = [
  {
    dataField: "synonym",
    text: "Синоним",
    headerStyle: (colum, colIndex) => {
      return { width: '50%' };
    },
    classes: () => 'editable',
  },
  {
    dataField: "brand",
    text: "Бренд",
    formatter: (cell, row) => cell.name,
    headerStyle: (colum, colIndex) => {
      return { width: '50%' };
    },
    editable: false
  }
];