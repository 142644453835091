import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat, priceFormat } from "utils/format";
import { useHistory } from "react-router-dom";
import { CheckCircle } from "react-feather";

import user from "assets/img/user.svg";

import "./acceptanceActsTable.scss";

export const AcceptanceActsTable = ({ acceptanceActs }) => {
  const history = useHistory();
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      headerStyle: () => {
        return { width: "3%" };
      },
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date",
      text: "Дата",
      formatter: (cell, row) => dateFormat(cell),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "provider",
      text: "Поставщик",
      formatter: (cell, row) => <span className="cute">{row.provider}</span>,
      headerStyle: () => {
        return { width: "13%" };
      },
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(parseFloat(cell)),
      headerClasses: () => "right",
      classes: () => "right",
    },
    {
      dataField: "admin",
      text: "Кто создал",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell, row) => (
        <span className="user-cell">
          <img src={user} alt="user" />
          {cell}
        </span>
      ),
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/receipt/acceptanceActs/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={acceptanceActs}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
