import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Plus } from "react-feather";
import { toast } from "react-toastify";
import Select from "react-select";

import { MySpinner } from "components/MySpinner";
import { addAddress } from "services/api";
import { validHandler } from "utils/validation";
import { CloseButton } from "components/CloseButton";
import { TRANSPORT_COMPANY } from "pages/orderOne";

export const CreateAddressModal = ({ customer, setAddresses }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [transportCompany, setTransportCompany] = useState("");
  const [senderIsPayer, setSenderIsPayer] = useState(false);
  const [contact, setContact] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    const { errors } = validHandler({
      city,
      address,
    });
    setErrors(errors);
  }, [address, city]);

  const createAddressHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      city,
      address,
    });

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    addAddress({
      customer: customer?.id,
      address,
      city,
      contact,
      comment,
      transportCompany,
      senderIsPayer: senderIsPayer === true ? 1 : 0,
    })
      .then((res) => {
        setAddress("");
        setTransportCompany("");
        setCity("");
        setSenderIsPayer(false);
        setContact("");
        setComment("");
        setOpen(false);
        setNeedValid(false);
        setError(null);
        toast.success("Адрес успешно добавлен");
        if (setAddresses) {
          setAddresses(res);
        }
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при добавлении адреса"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const payers = () => [
    { label: "ЛИДЕР ТРАК", value: true },
    {
      label: customer?.name,
      value: false,
    },
  ];

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        <Plus size={14} />
      </Button>
      <Modal isOpen={open} toggle={() => setOpen(false)}>
        {error && <Alert color="danger">{error}</Alert>}
        {loading && <MySpinner overlay />}
        <ModalHeader>
          <div className="modal-title-text">Добавить адрес</div>
          <CloseButton onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Город доставки</Label>
                <Input
                  name="city"
                  placeholder="Город"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  invalid={needValid && errors.hasOwnProperty("city")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Адрес доставки</Label>
                <Input
                  name="address"
                  placeholder="Адрес доставки"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  invalid={needValid && errors.hasOwnProperty("address")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Транспортная компания</Label>
                <Select
                  name="transportCompany"
                  placeholder="Транспортная компания"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={TRANSPORT_COMPANY}
                  value={TRANSPORT_COMPANY.find(
                    (item) => item.value === transportCompany
                  )}
                  onChange={(val) => setTransportCompany(val.value)}
                  isClearable
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Плательщик</Label>
                <Select
                  name="senderIsPayer"
                  placeholder="Плательщик"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={payers()}
                  value={senderIsPayer}
                  onChange={setSenderIsPayer}
                  isClearable
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Контакты получателя</Label>
                <Input
                  name="contact"
                  placeholder="Контакты получателя"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                  invalid={needValid && errors.hasOwnProperty("contact")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Комментарий к отправке</Label>
                <Input
                  type="textarea"
                  name="comment"
                  placeholder="Комментарий к отправке"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  invalid={needValid && errors.hasOwnProperty("comment")}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-between">
            <Button onClick={() => setOpen(false)} color="secondary">
              Закрыть
            </Button>
            <Button onClick={createAddressHandler} color="primary">
              Сохранить
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
