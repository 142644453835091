import React, { useEffect, useRef, useState } from "react";

import { SelectSearch } from "components/SelectSearch";

import { cellSuggestion } from "services/api";

import "./selectCell.scss";
import { Cut } from "components/Cut";

export const SelectCell = ({ row, cellHandler }) => {
  const [shown, setShown] = useState(false);
  const rootEl = useRef(null);

  useEffect(() => {
    const onClick = (e) => {
      rootEl.current?.contains(e.target) || setShown(false);
    }
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  return <>
    <span onClick={() => setShown(true)} className="selectCell">
      <Cut>{row.nomenclature?.cell}</Cut>
      {!row.nomenclature?.cell && !shown && <span className="selectCellText">Выбрать ячейку</span>}
    </span >
    {shown && (
      <div ref={rootEl} className="selectCellBlock">
        <SelectSearch
          isClearable
          name="cell"
          placeholder="Выберите ячейку"
          functionSuggestion={cellSuggestion}
          value={row.nomenclature?.cell}
          setValue={(val) => {
            cellHandler(val);
            setShown(false);
          }}
        />
      </div>
    )}
  </>
}