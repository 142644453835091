import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useCart } from "../CartContext";
import { providersSuggestion, cartUpdate } from "services/api";

export const ChangeProviderCart = ({ row }) => {
  const { setCartLoading, setCart } = useCart();
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [provider, setProvider] = useState({
    label: row.provider?.name,
    value: row.provider?.id,
  });

  const providerMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name };
    });

  useEffect(() => {
    if (search.length < 3) {
      setSuggestions([]);
      return;
    }
    setSearchLoading(true);
    providersSuggestion(search)
      .then((res) => setSuggestions(providerMap(res)))
      .finally(() => setSearchLoading(false));
  }, [search]);

  const providerHandler = (data) => {
    setProvider(data);
    setCartLoading(true);
    cartUpdate({ id: row.id, provider: data.value })
      .then((res) => setCart(res.cart))
      .finally(() => setCartLoading(false));
  };

  return (
    <Select
      name="provider"
      placeholder="Выберите поставщика"
      className="react-select-container"
      classNamePrefix="react-select"
      options={suggestions}
      isSearchable
      isLoading={searchLoading}
      inputValue={search}
      onInputChange={setSearch}
      value={provider}
      defaultValue={[{ value: "", label: "" }]}
      onChange={providerHandler}
    />
  );
};
