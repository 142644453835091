import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getUser } from "services/api";
import { dateFormat } from "utils/format";

import "./customerModal.scss";
import { Cut } from "components/Cut";
import { customerName } from "utils/customerName";

export const CustomerModal = ({ customerId, openModal, setOpenModal }) => {
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({});

  const getAndSetCustomer = () => {
    if (!customerId) return;
    setLoading(true);
    getUser(customerId)
      .then((res) => {
        setCustomer(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!openModal) return;
    getAndSetCustomer();
    // eslint-disable-next-line
  }, [openModal]);

  const renderAddresses = () => {
    return (
      <table className="addresses">
        <tbody>
          {customer.addresses?.map((item) => (
            <tr key={item.id}>
              <td>{item.city}</td>
              <td>
                <Cut>{item.address}</Cut>
              </td>
              <td>
                <Cut>{item.transportCompany}</Cut>
              </td>
              <td>
                <Cut>
                  Плат.:{" "}
                  {item.senderIsPayer ? "ЛИДЕР ТРАК" : customerName(customer)}
                </Cut>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderContracts = () => {
    return (
      <table>
        <tbody>
          {customer.contracts?.map((item) => (
            <tr key={item.id}>
              <td>
                {item.number} {dateFormat(item.date)} {item.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="customer-modal"
    >
      <ModalHeader>
        <div className="modal-title-text">
          {customer.type === 1
            ? `${customer.lastName} ${customer.firstName}
                    ${customer.middleName}`
            : customer.orgNameShort}
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        {loading && <MySpinner overlay />}

        <table className="my-2 table table-sm">
          <tbody>
            {customer.type === 1 ? (
              <>
                <tr>
                  <th>ФИО</th>
                  <td>
                    {customer.lastName} {customer.firstName}{" "}
                    {customer.middleName}
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{customer.phone}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{customer.email}</td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <th>Краткое название</th>
                  <td>{customer.orgNameShort}</td>
                </tr>
                <tr>
                  <th>Полное название</th>
                  <td>{customer.orgName}</td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>{customer.phone}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{customer.email}</td>
                </tr>
                <tr>
                  <th>Юридический адрес</th>
                  <td>{customer.orgAddress}</td>
                </tr>
                <tr>
                  <th>Адрес грузополучателя</th>
                  <td>{customer.consigneeAddress}</td>
                </tr>
                <tr>
                  <th>ИНН / КПП</th>
                  <td>
                    {customer.orgInn} / {customer.orgKpp}
                  </td>
                </tr>
                <tr>
                  <th>Рассчетный счет</th>
                  <td>{customer.orgRs}</td>
                </tr>
                <tr>
                  <th>Корр счет</th>
                  <td>{customer.orgKs}</td>
                </tr>
                <tr>
                  <th>БИК</th>
                  <td>{customer.orgBik}</td>
                </tr>
                <tr>
                  <th>Банк</th>
                  <td>{customer.orgBank}</td>
                </tr>
                <tr>
                  <th>ФИО генерального директора</th>
                  <td>{customer.orgDir}</td>
                </tr>
              </>
            )}

            <tr>
              <th>Адреса доставки:</th>
              <td>{renderAddresses()}</td>
            </tr>
            <tr>
              <th>Договора:</th>
              <td>{renderContracts()}</td>
            </tr>

            {customer.withDiadoc && (
              <tr>
                <th>Диадок</th>
                <td>
                  <span className="with-diadoc">Работает с Диадок</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="edit-button d-flex justify-content-end">
          <a
            className="btn btn-primary"
            href={`/sales/users/${customer.id}`}
            target="_blank"
            rel="noreferrer"
          >
            Редактировать
          </a>
        </div>
      </ModalBody>
    </Modal>
  );
};
