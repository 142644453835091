import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getProviders } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { Checkbox } from "components/Checkbox";

import { tableColumns } from "./tableData";
import "./providers.scss";

export const ProvidersPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [providers, setProviders] = useState([]);
  const [providersCount, setProvidersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [price, setPrice] = useState(query.get("price") ? 1 : 0);
  const [isShowReset, setIsShowReset] = useState(false);

  const getAndSetProviders = () => {
    getProviders({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      price,
    })
      .then((res) => {
        setProviders(res.items);
        setProvidersCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetProviders();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (price) {
      url.append("price", price);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, price, search]);

  const checkboxHandler = (e) => {
    switch (e.target.name) {
      case "price":
        setPrice(e.target.checked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setLoading(true);
    getAndSetProviders();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/receipt/providers/${row.id}`);
    },
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetProviders();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetProviders();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, price]);

  const isSearch = () => {
    if (search || price) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
    setPrice(0);
  };

  return (
    <Container fluid className="p-0 providers">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Поставщики</h1>
          <Form className="mb-3">
            <Row>
              <Col md="4">
                <Label>Поиск</Label>
                <Input
                  name="search"
                  placeholder="По наименованию, ИНН"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={handleKeypress}
                />
              </Col>
              <Col md={1}>
                <Label>Только с прайсами</Label>
                <Checkbox
                  onChange={checkboxHandler}
                  checked={price}
                  name="price"
                >
                  Только с прайсами
                </Checkbox>
              </Col>
              <Col md={2}>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Button
            onClick={() => history.push("/receipt/providers/new")}
            color="primary"
          >
            Добавить поставщика
          </Button>
        </CardBody>
      </Card>
      <BootstrapTable
        keyField="id"
        data={providers}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={providersCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
