import React, { useEffect, useState } from "react";
import Select from "react-select";

export const SelectSearch = ({
  isClearable = false,
  name,
  placeholder,
  functionSuggestion,
  value,
  setValue,
  needValid,
  errors,
  isMulti
}) => {
  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const suggestionMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name, ...item };
    });

  useEffect(() => {
    if (search.length < 2) {
      setSuggestions([]);
      return;
    }
    setSearchLoading(true);
    functionSuggestion(search)
      .then((res) => setSuggestions(suggestionMap(res)))
      .finally(() => setSearchLoading(false));
    // eslint-disable-next-line
  }, [search]);

  const customStyles = {
    control: (base, state) => {
      return {
        ...base,
        borderColor: state.isFocused
          ? "#ced4da"
          : needValid && errors.hasOwnProperty(state.selectProps.name)
            ? "red"
            : "#ced4da",
        "&:hover": {
          borderColor: state.isFocused
            ? "#ced4da"
            : needValid && errors.hasOwnProperty(state.selectProps.name)
              ? "red"
              : "#ced4da",
        },
      };
    },
  };

  return (
    <Select
      isMulti={isMulti}
      isClearable={isClearable}
      styles={customStyles}
      name={name}
      placeholder={placeholder}
      className={`react-select-container ${isMulti ? 'react-select__is-multy' : ''}`}
      classNamePrefix="react-select"
      options={suggestions}
      isSearchable
      isLoading={searchLoading}
      inputValue={search}
      onInputChange={setSearch}
      value={value}
      onChange={setValue}
      invalid={needValid && errors.hasOwnProperty(name)}
    />
  );
};
