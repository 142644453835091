import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";

import { MySpinner } from "components/MySpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { getOrder, sendToDiadocFromUpd, updFileUpload } from "services/api";
import { dateFormat, priceFormat } from "utils/format";

import "./diadoc.scss";

export const DiadocPage = () => {
  const { id } = useParams();
  const inputFile = useRef();
  const [loading, setLoading] = useState(false);
  const [updFile, setUpdFile] = useState(null);
  const [updData, setUpdData] = useState({});
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const getFile = () => {
    if (inputFile) {
      inputFile.current.click();
    }
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getOrder(id)
      .then((res) => {
        setUpdData(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (!updFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", updFile);
    updFileUpload(formData)
      .then((res) => {
        setUpdData(res);
        setError(false);
      })
      .finally(() => {
        setLoading(false);
        inputFile.current.value = null;
      });
  }, [updFile]);

  const diadocHandler = () => {
    setLoading(true);
    sendToDiadocFromUpd(updData)
      .then((res) => {
        if (res.message) {
          setSuccess(res.message);
          setError(false);
        }
      })
      .catch((e) => {
        if (e.response.data.message) {
          setError(e.response.data.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const changeHandler = (e) => {
    const u = { ...updData };
    u[e.target.name] = e.target.value;
    setUpdData(u);
  };

  const renderUpd = () => (
    <Form>
      <Row>
        <Col lg="8">
          <table className="my-2 table table-sm">
            <tbody>
              <tr>
                <th>Номер УПД</th>
                <td>
                  {updData.number} ({parseInt(updData.number)})
                </td>
              </tr>
              <tr>
                <th>Дата</th>
                <td>{dateFormat(updData.date)}</td>
              </tr>
              <tr>
                <th>Грузополучатель и его адрес</th>
                <td>
                  {updData.consigneeOrg}

                  <Input
                    name="consigneesAddress"
                    placeholder="Адрес грузополучателя"
                    value={updData.consigneeAddress}
                    onChange={changeHandler}
                    className="mt-2"
                  />
                </td>
              </tr>
              <tr>
                <th>Покупатель</th>
                <td>{updData.buyerOrgName}</td>
              </tr>
              <tr>
                <th>Адрес покупателя</th>
                <td>{updData.buyerAddress}</td>
              </tr>
              <tr>
                <th>ИНН / КПП покупателя</th>
                <td>
                  {updData.buyerInn} / {updData.buyerKpp}
                </td>
              </tr>
              <tr>
                <th>Основание передачи / получения</th>
                <td>
                  <Row>
                    <Col lg="8">
                      <Input
                        name="baseDocumentName"
                        placeholder="Документ"
                        value={updData.baseDocumentName}
                        onChange={changeHandler}
                      />
                      <div className="doc">
                        №
                        <Input
                          name="baseDocumentNumber"
                          placeholder="Номер"
                          value={updData.baseDocumentNumber}
                          onChange={changeHandler}
                        />
                        от
                        <InputMask
                          name="baseDocumentDate"
                          type="text"
                          placeholder="Дата"
                          onChange={changeHandler}
                          value={updData.baseDocumentDate}
                          mask={"99.99.9999"}
                          className="form-control"
                        />
                      </div>
                      <Input
                        className="mt-2"
                        name="baseDocumentInfo"
                        placeholder="Дополнительная инормация об основании отгрузки товаров"
                        value={updData.baseDocumentInfo}
                        onChange={changeHandler}
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>

      <h3>Список позиций</h3>

      <table className="my-2 table">
        <thead>
          <tr>
            <th>№</th>
            <th>Артикул</th>
            <th>Наименование</th>
            <th className="right">Кол-во</th>
            <th className="right">Цена без НДС</th>
            <th className="right">Сумма без НДС</th>
            <th className="right">НДС</th>
            <th className="right">Сумма</th>
            <th>Страна</th>
            <th>Код страны</th>
            <th>ГТД</th>
          </tr>
        </thead>
        <tbody>
          {updData.products.map((product) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.article}</td>
              <td>{product.name}</td>
              <td className="right">{product.count}</td>
              <td className="right">{priceFormat(product.price)}</td>
              <td className="right">{priceFormat(product.summWithoutNds)}</td>
              <td className="right">{priceFormat(product.nds)}</td>
              <td className="right">{priceFormat(product.summ)}</td>
              <td>{product.countryName}</td>
              <td>{product.countryCode}</td>
              <td>{product.gtd}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}></td>
            <td className="right">
              <strong>{priceFormat(updData.totalWithoutNds)}</strong>
            </td>
            <td className="right">
              <strong>{priceFormat(updData.nds)}</strong>
            </td>
            <td className="right">
              <strong>{priceFormat(updData.total)}</strong>
            </td>
            <td colSpan={3}></td>
          </tr>
        </tfoot>
      </table>
    </Form>
  );

  return (
    <Container fluid className="p-0">
      <Card>
        <CardBody>
          <h1 className="h3 mb-3">Выгрузка в Диадок</h1>
          <Row>
            <Col lg="3">
              <FormGroup>
                <Button color="primary" onClick={getFile} className="mr-3">
                  <FontAwesomeIcon icon={faUpload} /> Загрузить УПД
                </Button>
                <Input
                  type="file"
                  id="file"
                  hidden
                  innerRef={inputFile}
                  onChange={(e) => setUpdFile(e.target.files[0])}
                />

                <Button color="primary" onClick={diadocHandler}>
                  Отправить в Диадок
                </Button>
              </FormGroup>
            </Col>
          </Row>
          {success && <Alert color="success">{success}</Alert>}
          {error && <Alert color="danger">{error}</Alert>}

          {loading && <MySpinner overlay />}
          {updData.number && renderUpd()}
        </CardBody>
      </Card>
    </Container>
  );
};
