import React from "react";
import { Button, Col, Row } from "reactstrap";
import { X as XIcon } from "react-feather";

import { priceFormat } from "utils/format";
import { CreateAdditionalExpanceModal } from "../CreateAdditionalExpanceModal";
import { CreateCustomDeclarationModal } from "../CreateCustomsDeclarationModal";
import { TableButton } from "components/TableButton";
import {
  removeAdditionalExpence,
  removeCustomsDeclaration,
} from "services/apiSupply";
import { toast } from "react-toastify";

export const AddedFunctions = ({
  id,
  customsDeclaration,
  additionalExpances,
  getAndSetSupply,
  setLoading,
  isNew,
}) => {
  const removeAdditionalExpenceHandler = (id) => {
    setLoading(true);
    removeAdditionalExpence({ id })
      .then((res) => {
        toast.success("Доп расход успешно удален");
        getAndSetSupply();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  const removeCustomsDeclarationHandler = (id) => {
    setLoading(true);
    removeCustomsDeclaration({ id })
      .then((res) => {
        toast.success("Таможенная декларация успешно удалена");
        getAndSetSupply();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  if (isNew) {
    return null;
  }

  return (
    <>
      <Row>
        <Col>
          {!customsDeclaration && (
            <CreateCustomDeclarationModal
              id={id}
              getAndSetSupply={getAndSetSupply}
            />
          )}

          <CreateAdditionalExpanceModal
            id={id}
            getAndSetSupply={getAndSetSupply}
          />
        </Col>
      </Row>

      {customsDeclaration && (
        <>
          <Row>
            <h5>Таможенная декларация</h5>
          </Row>
          <Row>
            <Col md="3">Таможенный сбор</Col>
            <Col md="3">
              <b>{priceFormat(customsDeclaration.customsDuty)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">Таможенный штраф</Col>
            <Col md="3">
              <b>{priceFormat(customsDeclaration.customsFine)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">Номер декларации</Col>
            <Col md="3">
              <b>{customsDeclaration.gtd}</b>
            </Col>
            <Col md="2">
              <TableButton
                Icon={XIcon}
                onClick={() =>
                  removeCustomsDeclarationHandler(customsDeclaration.id)
                }
              />
            </Col>
          </Row>
        </>
      )}

      {additionalExpances.length > 0 && (
        <>
          <Row>
            <h5>Дополнительные расходы</h5>
          </Row>

          {additionalExpances.map((item) => (
            <Row key={item.id}>
              <Col md="4">{item.name}</Col>
              <Col md="2">
                <b>{priceFormat(item.price)}</b>
              </Col>
              <Col md="2">
                <TableButton
                  Icon={XIcon}
                  onClick={() => removeAdditionalExpenceHandler(item.id)}
                />
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  );
};
