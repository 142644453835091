import React, { useEffect, useState } from "react";

import { getChangeLogNomenclature } from "services/api";
import { ChangeLogModal } from "components/ChangeLogModal";

import "./changeLog.scss";

export const ChangeLogNomenclature = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [changeLog, setChangeLog] = useState([]);

  const getAndSetChangeLog = () => {
    setLoading(true);
    getChangeLogNomenclature(id)
      .then((res) => {
        setChangeLog(res);
      })
      .catch((e) => {
        alert(e.response.data.message);
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!open) return;
    getAndSetChangeLog();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ChangeLogModal
      open={open}
      setOpen={setOpen}
      changeLog={changeLog}
      loading={loading}
    />
  );
};
