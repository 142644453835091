import React, { useEffect, useState } from "react";
import Moment from "react-moment";

import { priceFormat } from "utils/format";

import "./printInvoice.scss";

import logo from "./logo.png";

export const PrintInvoice = ({ utd }) => {
  return (
    <div>
      <style>
        {`@media print {
            @page {
              size: A4;
              margin: 0;
				    }}
          }`
        }
      </style>
      <div className="printInvoice">

        <table className="head-table">
          <tr>
            <td>
              <img src={logo} alt="" />
            </td>
          </tr>
          <tr>
            <td>
              Внимание! Оплата данного счета означает согласие с условиями поставки товара.
              Уведомление об оплате обязательно, в противном случае не гарантируется наличие товара на складе.
              Товар отпускается по факту прихода денег на р/с Поставщика, самовывозом, при наличии доверенности и паспорта.
            </td>
          </tr>
        </table>

        <table className="title-table">
          <tr>
            <td>
              ВНИМАНИЕ! У нас изменились банковские реквизиты.
            </td>
          </tr>
        </table>


        <table className="border-table">
          <caption>
            Образец заполнения платежного поручения
          </caption>
          <tr>
            <td colspan="4" rowspan="2">
              {utd.seller?.bank}
              <span>Банк получателя</span>
            </td>
            <td>
              БИК
            </td>
            <td>
              {utd.seller?.bik}
            </td>
          </tr>
          <tr>
            <td>
              Сч. №
            </td>
            <td>
              {utd.seller?.ks}
            </td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>{utd.seller?.inn}</td>
            <td>КПП</td>
            <td>{utd.seller?.kpp}</td>
            <td rowspan="2">Сч. №</td>
            <td rowspan="2">{utd.seller?.rs}</td>
          </tr>
          <tr>
            <td colspan="4">
              {utd.seller?.nameShort}
              <span>Получатель</span>
            </td>
          </tr>
        </table>

        <table className="payment-table">
          <caption>
            Счет на оплату № {utd.number} от <Moment format="D MMMM Y">{utd.date}</Moment> г.
          </caption>
          <tr>
            <td>
              Поставщик: &nbsp;&nbsp;
            </td>
            <td>
              {utd.seller?.nameShort}, ИНН {utd.seller?.inn}, КПП {utd.seller?.kpp}, {utd.seller?.address}, тел: {utd.admin?.phone}, +7 (999) 916 50 00
            </td>
          </tr>
          <tr>
            <td>
              Покупатель:&nbsp;&nbsp;
            </td>
            <td>
              {utd.buyerOrgName}, ИНН {utd.buyerInn}, КПП {utd.buyerKpp}, {utd.buyerAddress}
            </td>
          </tr>
        </table>

        <table className="products-table">
          <tr>
            <th>№</th>
            <th>Товары (работы, услуги)</th>
            <th>Артикул</th>
            <th>Производитель</th>
            <th>Количество</th>
            <th>Цена</th>
            <th>Ставка НДС</th>
            <th>Сумма НДС</th>
            <th>Сумма</th>
          </tr>

          {utd.products.map(product =>
            <tr key={product.id}>
              <td>{product.index}</td>
              <td>{product.name}</td>
              <td>{product.article}</td>
              <td>{product.brand}</td>
              <td>{product.count} шт</td>
              <td>{priceFormat(product.price, 2)}</td>
              <td>20%</td>
              <td>{priceFormat(product.nds, 2)}</td>
              <td>{priceFormat(product.summ, 2)}</td>
            </tr>
          )}

          <tr className="itogo">
            <td colspan="8">Итого:</td>
            <td>{priceFormat(utd.summ - utd.nds, 2)}</td>
          </tr>
          <tr className="nds">
            <td colspan="8">В т.ч. НДС 20%</td>
            <td>{priceFormat(utd.nds, 2)}</td>
          </tr>
          <tr className="itogoWithNds">
            <td colspan="8">Итого с НДС:</td>
            <td>{priceFormat(utd.summ, 2)}</td>
          </tr>
          <tr className="itogoText">
            <td colspan="9">Всего наименований {utd.count}, на сумму {priceFormat(utd.summ, 2)} RUB</td>
          </tr>
          <tr className="summText">
            <td colspan="9">{utd.summText}</td>
          </tr>

        </table>

        <table className="signatures-table">
          <tr>
            <td>Руководитель</td>
            <td>
              <img src="signature.png" alt="" className="signature" />
              <span>подпись</span>
            </td>
            <td>
              Миникаев Р.Л.
              <span>расшифровка подписи</span>
              <img src="stamp.png" alt="" className="stamp" />
            </td>
            <td>
              Бухгалтер
            </td>
            <td>
              <img src="signature.png" alt="" className="signature" />
              <span>подпись</span>
            </td>
            <td>
              Миникаев Р.Л.
              <span>расшифровка подписи</span>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              По приказу №1, от 27.07.2016 г.
            </td>
            <td colspan="3">
              По приказу №1, от 27.07.2016 г.
            </td>
          </tr>
        </table>

        <table className="importantly-table">
          <tr>
            <td>
              ВАЖНО
              При оплате в назначении платежа обязательно указывайте
              номер и дату оплачиваемого счета(ов).
            </td>
          </tr>
          <tr>
            <td>
              Оплата данного счета является акцептом договора розничной купли-продажи в соответствии со ст. 438 ГК РФ.
            </td>
          </tr>
        </table>


      </div >
    </div >
  );
};
