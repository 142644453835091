import React from "react";
import { Trash } from "react-feather";
import { Button } from "reactstrap";

import { useOrders } from "../OrdersContext";
import { removeProduct } from "services/api";

export const RemoveButton = ({ id }) => {
  const { setLoading, setProducts } = useOrders();

  const remove = () => {
    if (!window.confirm("Вы действительно хотите удалить товар?")) {
      return;
    }
    setLoading(true);
    removeProduct({ id })
      .then((res) => setProducts(res.products))
      .finally(() => setLoading(false));
  };

  return (
    <Button color="link" size="sm" onClick={remove}>
      <Trash color="red" size={20} />
    </Button>
  );
};
