import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { getCharacteristics } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { CharacteristicsModal } from "features/Characteristics/CharacteristicsModal";
import { CharacteristicsTable } from "features/Characteristics/CharacteristiscTable";

export const CharacteristicsPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [characteristics, setCharacteristics] = useState([]);
  const [characteristicsCount, setCharacteristicsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [type, setType] = useState(query.get("type") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(null);

  const getAndSetCharacteristics = () => {
    getCharacteristics({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
    })
      .then((res) => {
        setCharacteristics(res.items);
        setCharacteristicsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search]);

  useEffect(() => {
    setLoading(true);
    getAndSetCharacteristics();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetCharacteristics();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const searchHandler = () => {
    if (!search) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetCharacteristics();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const isSearch = () => {
    if (search) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
  };

  return (
    <Container fluid className="p-0 characteristics">
      {loading && <MySpinner overlay />}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Характеристики</h1>
          <Form className="mb-3">
            <Row>
              <Col md="4">
                <Label>Поиск</Label>
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По наименованию"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col md="2">
              <Button onClick={() => setOpenModal(true)} color="primary">
                Добавить характеристику
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <CharacteristicsTable
        characteristics={characteristics}
        getAndSetCharacteristics={getAndSetCharacteristics}
        setLoading={setLoading}
        setOpenModal={setOpenModal}
        setId={setId}
      />

      <Card>
        <CardBody>
          <Pagination
            totalCount={characteristicsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>

      <CharacteristicsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setLoading={setLoading}
        loading={loading}
        id={id}
        getAndSetCharacteristics={getAndSetCharacteristics}
      />
    </Container>
  );
};
