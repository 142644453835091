import classNames from "classnames";
import React from "react";

import "./tableButton.scss";

export const TableButton = ({ Icon, onClick, disabled, className }) => {
  return (
    <button
      onClick={onClick}
      className={classNames("action-table", className)}
      type="button"
      disabled={disabled}
    >
      <Icon size={14} color="black" />
    </button>
  );
};
