import { axiosInstance } from "./api";

export const getSupplies = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/supply.get", { params });
  return res.data;
};

export const getSupplyCart = async () => {
  const res = await axiosInstance.get("/supply.getCart");
  return res.data;
};

export const getSupplyOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/supply.getOne", { params });
  return res.data;
};

export const getSupplyByNumber = async (number) => {
  const params = {
    number,
  };
  const res = await axiosInstance.get("/supply.getOneByNumber", { params });
  return res.data;
};

export const createSupply = async (props) => {
  const res = await axiosInstance.post("/supply.create", props);
  return res.data;
};

export const updateSupply = async (props) => {
  const res = await axiosInstance.post("/supply.update", props);
  return res.data;
};

export const addSupplyProduct = async (props) => {
  const res = await axiosInstance.post("/supply.addProduct", props);
  return res.data;
};

export const updateSupplyProduct = async (props) => {
  const res = await axiosInstance.post("/supply.updateProduct", props);
  return res.data;
};

export const removeSupplyProducts = async (props) => {
  const res = await axiosInstance.post("/supply.removeProducts", props);
  return res.data;
};

export const updateSupplyProductsWarehuse = async (props) => {
  const res = await axiosInstance.post("/supply.updateWarehouses", props);
  return res.data;
};

export const supplyFileImportUpload = async (props) => {
  const res = await axiosInstance.post("/supply.fileUpload", props);
  return res.data;
};

export const supplyFileImportUpdate = async (props) => {
  const res = await axiosInstance.post("/supply.fileUploadUpdate", props);
  return res.data;
};

export const getSupplyLayoutSheet = async (props) => {
  const res = await axiosInstance.post("/supply.getLayoutSheet", props, {
    responseType: "blob",
  });
  return res.data;
};

export const carryOutSupply = async (props) => {
  const res = await axiosInstance.post("/supply.carryOut", props);
  return res.data;
};

export const getSupplyToExcel = async (props) => {
  const res = await axiosInstance.post("/supply.getToExcel", props, {
    responseType: "blob",
  });
  return res.data;
};

export const exportFilesForTsd = async (props) => {
  const res = await axiosInstance.post("/supply.exportFilesForTsd", props);
  return res.data;
};

export const sendReport = async (props) => {
  const res = await axiosInstance.post("/supply.sendReport", props);
  return res.data;
};

export const getOpenedOrders = async (article) => {
  const params = {
    article
  };
  const res = await axiosInstance.get("/orders.getOpened", { params });
  return res.data;
};

export const getAcceptanceActs = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/acceptanceActs.get", { params });
  return res.data;
};

export const getAcceptanceActOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/acceptanceActs.getOne", { params });
  return res.data;
};

export const createAcceptanceAct = async (props) => {
  const res = await axiosInstance.post("/acceptanceActs.create", props);
  return res.data;
};

export const removeAcceptanceActProduct = async (props) => {
  const res = await axiosInstance.post("/acceptanceActs.removeProduct", props);
  return res.data;
};

export const carryOutAcceptanceAct = async (props) => {
  const res = await axiosInstance.post("/acceptanceActs.carryOut", props);
  return res.data;
};

export const importAcceptanceAct = async (props) => {
  const res = await axiosInstance.post("/acceptanceActs.import", props);
  return res.data;
};

export const getAcceptanceActLayoutSheet = async (props) => {
  const res = await axiosInstance.post(
    "/acceptanceActs.getLayoutSheet",
    props,
    {
      responseType: "blob",
    }
  );
  return res.data;
};

export const getCountry = async (code) => {
  const params = {
    code,
  };
  const res = await axiosInstance.get("/country.get", { params });
  return res.data;
};

export const getEnteringBalances = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/enteringBalances.get", { params });
  return res.data;
};

export const getEnteringBalanceOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/enteringBalances.getOne", { params });
  return res.data;
};

export const createEnteringBalance = async (props) => {
  const res = await axiosInstance.post("/enteringBalances.create", props);
  return res.data;
};

export const updateEnteringBalance = async (props) => {
  const res = await axiosInstance.post("/enteringBalances.update", props);
  return res.data;
};

export const updateEnteringBalanceProduct = async (props) => {
  const res = await axiosInstance.post(
    "/enteringBalances.updateProduct",
    props
  );
  return res.data;
};

export const carryOutEnteringBalance = async (props) => {
  const res = await axiosInstance.post("/enteringBalances.carryOut", props);
  return res.data;
};

export const addEnteringBalanceProduct = async (props) => {
  const res = await axiosInstance.post("/enteringBalances.addProduct", props);
  return res.data;
};

export const getCorrOrders = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/corrOrder.get", { params });
  return res.data;
};

export const getCorrOrderOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/corrOrder.getOne", { params });
  return res.data;
};

export const getOrderForCorr = async (number) => {
  const params = {
    number,
  };
  const res = await axiosInstance.get("/corrOrder.getOrder", { params });
  return res.data;
};

export const createCorrOrder = async (props) => {
  const res = await axiosInstance.post("/corrOrder.create", props);
  return res.data;
};

export const updateCorrOrder = async (props) => {
  const res = await axiosInstance.post("/corrOrder.update", props);
  return res.data;
};

export const carryOutCorrOrder = async (props) => {
  const res = await axiosInstance.post("/corrOrder.carryOut", props);
  return res.data;
};

export const downloadCorrOrder = async (props) => {
  const res = await axiosInstance.post("/corrOrder.download", props, {
    headers: { "Content-Type": "multipart/form-data" },
    responseType: "blob",
  });
  return res.data;
};

export const getCorrOrderListToExcel = async (props) => {
  const res = await axiosInstance.post(
    "/corrOrder.downloadListToExcel",
    props,
    {
      responseType: "blob",
    }
  );
  return res.data;
};

export const sendCorrOrderToDiadoc = async (props) => {
  const res = await axiosInstance.post("/corrOrder.sendToDiadoc", props);
  return res.data;
};

export const getReturnsToProvider = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/returnsToProvider.get", { params });
  return res.data;
};

export const getReturnToProviderOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/returnsToProvider.getOne", { params });
  return res.data;
};

export const createReturnToProvider = async (props) => {
  const res = await axiosInstance.post("/returnsToProvider.create", props);
  return res.data;
};

export const updateReturnToProvider = async (props) => {
  const res = await axiosInstance.post("/returnsToProvider.update", props);
  return res.data;
};

export const carryOutReturnToProvider = async (props) => {
  const res = await axiosInstance.post("/returnsToProvider.carryOut", props);
  return res.data;
};

export const updateReturnToProviderProduct = async (props) => {
  const res = await axiosInstance.post(
    "/returnsToProvider.updateProduct",
    props
  );
  return res.data;
};

export const getUpd = async (props) => {
  const res = await axiosInstance.post("/returnsToProvider.getUpd", props, {
    responseType: "blob",
  });
  return res.data;
};

export const getReturnsToProviderToExcel = async (props) => {
  const res = await axiosInstance.post(
    "/returnsToProvider.downloadList",
    props,
    {
      responseType: "blob",
    }
  );
  return res.data;
};

export const createCustomsDeclaration = async (props) => {
  const res = await axiosInstance.post("/customsDelcarations.create", props);
  return res.data;
};

export const removeCustomsDeclaration = async (props) => {
  const res = await axiosInstance.post("/customsDelcarations.remove", props);
  return res.data;
};

export const createAdditionalExpence = async (props) => {
  const res = await axiosInstance.post("/additionalExpences.create", props);
  return res.data;
};

export const removeAdditionalExpence = async (props) => {
  const res = await axiosInstance.post("/additionalExpences.remove", props);
  return res.data;
};

export const getOrdersToProvider = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/ordersToProvider.get", { params });
  return res.data;
};

export const getOrderToProviderOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/ordersToProvider.getOne", { params });
  return res.data;
};

export const createOrderToProvider = async (props) => {
  const res = await axiosInstance.post("/ordersToProvider.create", props);
  return res.data;
};

export const updateOrderToProvider = async (props) => {
  const res = await axiosInstance.post("/ordersToProvider.update", props);
  return res.data;
};

export const getOrderToProviderStatuses = async () => {
  const res = await axiosInstance.get("/ordersToProvider.getStatuses");
  return res.data;
};

export const setOrderToProviderStatus = async (props) => {
  const res = await axiosInstance.post("/ordersToProvider.statusUpdate", props);
  return res.data;
};

export const orderToProviderFileUpload = async (props) => {
  const res = await axiosInstance.post("/ordersToProvider.fileUpload", props);
  return res.data;
};

export const orderToProviderUploadDocument = async (props) => {
  const res = await axiosInstance.post(
    "/ordersToProvider.uploadDocument",
    props
  );
  return res.data;
};

export const orderToProviderSetDateArrival = async (props) => {
  const res = await axiosInstance.post(
    "/ordersToProvider.setDateArrival",
    props
  );
  return res.data;
};

export const getOrderToProviderDocument = async (props) => {
  const res = await axiosInstance.post("/ordersToProvider.getDocument", props, {
    responseType: "blob",
  });
  return res.data;
};

export const updateOrderToProviderProduct = async (props) => {
  const res = await axiosInstance.post(
    "/ordersToProvider.updateProduct",
    props
  );
  return res.data;
};

export const addOrderToProviderProduct = async (props) => {
  const res = await axiosInstance.post("/ordersToProvider.addProduct", props);
  return res.data;
};

export const removeOrderToProviderProducts = async (props) => {
  const res = await axiosInstance.post(
    "/ordersToProvider.removeProduct",
    props
  );
  return res.data;
};

export const getPriceLists = async (props) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };

  const res = await axiosInstance.get("/api/v2/priceList/get", { params });
  return res.data;
};

export const getPriceListOne = async (id) => {
  const params = {
    id,
  };
  const res = await axiosInstance.get("/api/v2/priceList/getOne", { params });
  return res.data;
};

export const createPriceList = async (props) => {
  const res = await axiosInstance.post("/api/v2/priceList/create", props);
  return res.data;
};

export const updatePriceList = async (props) => {
  const res = await axiosInstance.post("/api/v2/priceList/update", props);
  return res.data;
};
