import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";

import "./movingProductTable.scss";

export const MovingProductTableNew = ({ products }) => {
  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <span className="cute">{row.nomenclature?.name}</span>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => "editable right",
      formatter: (cell, row) => row.count,
      validator: (newValue, row) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Должны быть только цифры",
          };
        }
        if (newValue > row.countOld) {
          return {
            valid: false,
            message: "Не должно быть больше чем кол-во",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Кол-во не может быть меньше 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "50%" };
      },
      editable: false,
      footer: (columnData) => <></>,
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="moving-product"
        keyField="id"
        data={products}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
        })}
      />
    </>
  );
};
