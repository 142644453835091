import React from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { CheckCircle } from "react-feather";

import { Cut } from "components/Cut";

import { dateTimeFormat, priceFormat } from "utils/format";
import user from "assets/img/user.svg";

import "./suppliesTable.scss";

export const SuppliesTable = ({ supplies }) => {
  const history = useHistory();
  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      headerStyle: () => {
        return { width: "3%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      headerStyle: () => {
        return { width: "3%" };
      },
      formatter: (cell) =>
        cell ? <CheckCircle color="green" size={17} /> : "",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date",
      text: "Дата",
      headerStyle: () => {
        return { width: "7%" };
      },
      formatter: (cell) => dateTimeFormat(cell),
    },
    {
      dataField: "factNum",
      text: "Номер сч.факт",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => {
        if (row.bailor) {
          return (
            <>
              <span className="bailor">СВХ</span>
              {cell}
            </>
          );
        }
        return cell;
      },
    },
    {
      dataField: "provider",
      text: "Поставщик",
      headerStyle: () => {
        return { width: "13%" };
      },
      classes: () => "bold",
    },
    {
      dataField: "admin",
      text: "Создал",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <Cut>
          <span className="user-cell">
            <img src={user} alt="user" />
            {cell}
          </span>
        </Cut>
      ),
    },
    {
      dataField: "storeKeeper",
      text: "Приемщик",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => (
        <Cut>
          <span className="user-cell">
            <img src={user} alt="user" />
            {cell}
          </span>
        </Cut>
      ),
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "4%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "comment",
      text: "Комментарий",
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cell, row) => <span className="cute">{cell}</span>,
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push(`/receipt/supply/${row.id}`);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={supplies}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
