import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ru", ru);

export const SelectDate = ({ date, setDate }) => {
  console.log(date);

  const [value, setValue] = useState(new Date(date));

  const dateHandler = (val = null) => {
    if (val) {
      setValue(val);
      setDate(val);
      return;
    }
    setDate(value);
  };

  return (
    <DatePicker
      placeholderText="Дата"
      selected={value}
      onChange={(val) => setValue(val)}
      onBlur={() => dateHandler()}
      onSelect={(val) => dateHandler(val)}
      selectsStart
      dateFormat="dd.MM.yyyy"
      locale="ru"
      className="form-control"
    />
  );
};
