import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { CloseButton } from "components/CloseButton";
import { createAdditionalExpence } from "services/apiSupply";
import { toast } from "react-toastify";
import { MySpinner } from "components/MySpinner";

export const CreateAdditionalExpanceModal = ({ id, getAndSetSupply }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [needValid, setNeedValid] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const createAdditionalExpenceHandler = () => {
    setLoading(true);
    createAdditionalExpence({
      supply: id,
      name,
      price,
    })
      .then((res) => {
        toast.success("Дополнительный расход успешно добавлен");
        setName("");
        setPrice("");
        setOpenModal(false);
        getAndSetSupply();
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpenModal(true)}
        className="button-wide"
      >
        Создать доп. расход
      </Button>

      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <div className="modal-title-text">Создать дополнительный расход</div>
          <CloseButton onClick={() => setOpenModal(false)} />
        </ModalHeader>
        {loading && <MySpinner overlay />}
        <ModalBody className="m-3">
          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>Наименование</Label>
                <Input
                  name="name"
                  placeholder="Наименование"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>Сумма</Label>
                <Input
                  name="price"
                  placeholder="Сумма"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              onClick={createAdditionalExpenceHandler}
              color="primary"
              className="button-wide"
            >
              Создать
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
