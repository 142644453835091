import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";

import { declOfNum } from "utils/declOfNum";

import "./footer.scss";
import { PAGE_HEIGHT } from "../Row";

export const Footer = ({ utd, page, parent }) => {
  const ref = useRef(null);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    if (ref && parent) {
      console.log('parent', parent);
      console.log('footerBottom', ref.current.getBoundingClientRect().bottom);
      console.log('footerBottom - parent', Math.ceil(ref.current.getBoundingClientRect().bottom - parent));
      setPages(Math.ceil((ref.current.getBoundingClientRect().bottom - parent) / PAGE_HEIGHT));
      console.log(Math.ceil((ref.current.getBoundingClientRect().bottom - parent) / PAGE_HEIGHT));
    }
  }, [ref, parent]);

  return (
    <div ref={ref}>
      <table className="chiefSigner">
        <tbody>
          <tr>
            <td>Документ <br />
              составлен на {pages}<br />
              {declOfNum(pages, ['листе', 'листах', 'листах'])}
            </td>
            <td>
              <table className="shiefSignerTable">
                <tbody>
                  <tr>
                    <td>
                      Руководитель организации
                      или иное уполномоченное лицо
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span>(Подпись)</span>
                    </td>
                    <td>
                      {utd.seller?.chief}
                      <span>(ф.и.о.)</span>
                    </td>
                    <td>
                      Главный бухгалтер
                      или иное уполномоченное лицо
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span>(Подпись)</span>
                    </td>
                    <td>
                      Миникаев Ренат Леонидович
                      <span>(ф.и.о.)</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Индивидуальный предприниматель или иное уполномоченное лицо
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span>(Подпись)</span>
                    </td>
                    <td>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span>(ф.и.о.)</span>
                    </td>
                    <td colSpan="3">
                      <span>
                        (реквизиты свидетельства о государственной регистрации индивидуального предпринимателя)
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="basis-table">
        <tbody>
          <tr>
            <td>
              Основание передачи (сдачи) / получения (приемки)
            </td>
            <td>
              {utd.dogovorName}
              <span>(договор; доверенность и др.)</span>
            </td>
            <td>
              [8]
            </td>
          </tr>
        </tbody>
      </table>

      <table className="data-table">
        <tbody>
          <tr>
            <td>
              Данные о транспортировке и грузе
            </td>
            <td>
              <span>
                (транспортная накладная, поручение экспедитору, экспедиторская / складская расписка и др. / масса нетто/
                брутто
                груза, если не приведены ссылки на транспортные документы, содержащие эти сведения)
              </span>
            </td>
            <td>
              [9]
            </td>
          </tr>
        </tbody>
      </table >

      <table className="transport">
        <tbody>
          <tr>
            <td>

              <table className="transport-table">
                <tbody>
                  <tr>
                    <td colSpan="4">
                      Товар (груз) передал / услуги, результаты работ, права сдал
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>(должность)</span>
                    </td>
                    <td>
                      <span>(подпись)</span>
                    </td>
                    <td>
                      <span>(ф.и.о.)</span>
                    </td>
                    <td>[10]</td>
                  </tr>
                  <tr>
                    <td>Дата отгрузки, передачи (сдачи)</td>
                    <td colSpan="2"><Moment format="DD.MM.YYYY">{utd.shippingDate}</Moment> г.</td>
                    <td>[11]</td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      Иные сведения об отгрузке, передаче
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <span>
                        (ссылки на неотъемлемые приложения, сопутствующие документы, иные документы и т.п.)
                      </span>
                    </td>
                    <td>
                      [12]
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      Ответственный за правильность оформления факта хозяйственной жизни
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {utd.post}
                      <span>(должность)</span>
                    </td>
                    <td>
                      <span>(подпись)</span>
                    </td>
                    <td>
                      {utd.admin?.name}
                      <span>(ф.и.о.)</span>
                    </td>
                    <td>[13]</td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      Наименование экономического субъекта - составителя документа (в т.ч. комиссионера / агента)
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      {utd.seller?.name}, ИНН/КПП {utd.seller?.inn}/{utd.seller?.kpp}
                      <span>
                        (может не заполняться при проставлении печати в М.П., может быть указан ИНН / КПП)
                      </span>
                    </td>
                    <td>
                      [14]
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      М.П.
                    </td>
                  </tr>
                </tbody>
              </table>

            </td>
            <td>

              <table className="transport-table">
                <tbody>
                  <tr>
                    <td colSpan="4">
                      Товар (груз) получил / услуги, результаты работ, права принял
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>(должность)</span>
                    </td>
                    <td>
                      <span>(подпись)</span>
                    </td>
                    <td>
                      <span>(ф.и.о.)</span>
                    </td>
                    <td>[15]</td>
                  </tr>
                  <tr>
                    <td>Дата получения (приемки)</td>
                    <td colSpan="2">
                      « &nbsp;&nbsp;&nbsp; » &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20
                      &nbsp;&nbsp;&nbsp;года
                    </td>
                    <td>[16]</td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      Иные сведения о получении, приемке
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <span>
                        (ссылки на неотъемлемые приложения, сопутствующие документы, иные документы и т.п.)
                      </span>
                    </td>
                    <td>
                      [17]
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      Ответственный за правильность оформления факта хозяйственной жизни
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &nbsp;
                      <span>(должность)</span>
                    </td>
                    <td>
                      <span>(подпись)</span>
                    </td>
                    <td>
                      &nbsp;
                      <span>(ф.и.о.)</span>
                    </td>
                    <td>[18]</td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      Наименование экономического субъекта - составителя документа (в т.ч. комиссионера / агента)
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      {utd.buyerOrgName}, ИНН/КПП {utd.buyerInn}/{utd.buyerKpp}
                      <span>
                        (может не заполняться при проставлении печати в М.П., может быть указан ИНН / КПП)
                      </span>
                    </td>
                    <td>
                      [19]
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      М.П.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}