import React, { useState, useRef } from "react";
import { Input } from "reactstrap";
import { priceFormat } from "utils/format";

import "./editable.scss";

export const EditableInput = ({ row, cell, onChange = null }) => {
  const [value, setValue] = useState(cell);
  const [editMode, setEditMode] = useState(false);

  const textInput = useRef(null);

  const onBlur = (e) => {
    if (onChange) {
      onChange(value);
    }
    setEditMode(false);
  };

  return (
    <>
      {editMode ? (
        <Input
          type="text"
          ref={textInput}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          value={value}
          maxLength={6}
          autoFocus
        />
      ) : (
        <div className="editable" onClick={() => setEditMode(true)}>
          {value}
        </div>
      )}
    </>
  );
};
