import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "reactstrap";
import classNames from "classnames";
import { MoreHorizontal } from "react-feather";

import { userSuggestion } from "services/api";
import { CustomerModal } from "features/Orders/CustomerModal";

import "./selectCustomer.scss";

export const SelectCustomer = ({ label, customer, setCustomer, className = null }) => {
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [openModalCustomer, setOpenModalCustomer] = useState(false);

  const customerMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name };
    });

  useEffect(() => {
    if (search.length < 3) {
      setSuggestions([]);
      return;
    }
    setSearchLoading(true);
    userSuggestion(search)
      .then((res) => setSuggestions(customerMap(res)))
      .finally(() => setSearchLoading(false));
  }, [search]);

  return (
    <>
      <div className={classNames("select-customer", className)}>
        {label && <Label>Клиент</Label>}

        <div
          className={classNames({
            "with-button": customer?.value,
          })}
        >
          <Select
            name="user"
            placeholder="Выберите клиента"
            className="react-select-container"
            classNamePrefix="react-select"
            options={suggestions}
            isSearchable
            isClearable
            isLoading={searchLoading}
            inputValue={search}
            onInputChange={setSearch}
            value={customer}
            onChange={setCustomer}
          />
          {customer?.value && (
            <button
              type="button"
              className="open-client-button"
              onClick={() => setOpenModalCustomer(true)}
            >
              <MoreHorizontal size={15} />
            </button>
          )}
        </div>
      </div >

      {customer?.value && (
        <CustomerModal
          customerId={customer?.value}
          openModal={openModalCustomer}
          setOpenModal={setOpenModalCustomer}
        />
      )
      }
    </>
  );
};
