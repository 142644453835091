import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";

import {
  getReturnToProviderOne,
  getSupplyByNumber,
  createReturnToProvider,
  carryOutReturnToProvider,
  getUpd,
  updateReturnToProvider,
} from "services/apiSupply";
import { Checkbox } from "components/Checkbox";
import { dateFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import { ReturnToProviderProductTable } from "features/ReturnsToProvider/ReturnToProviderProductTable";
import { ReturnToProviderProductTableNew } from "features/ReturnsToProvider/ReturnToProviderProductTableNew";
import { useUser } from "features/Users/UserContext";

import "./returnToProvider.scss";

export const ReturnToProviderOnePage = () => {
  const { user } = useUser();
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(!id ? true : false);
  const [number, setNumber] = useState("");
  const [supplyNumber, setSupplyNumber] = useState("");
  const [supply, setSupply] = useState({});
  const [date, setDate] = useState(new Date().toLocaleString("ru-RU"));
  const [comment, setComment] = useState("");
  const [admin, setAdmin] = useState("");
  const [carriedOut, setCarriedOut] = useState(false);
  const [products, setProducts] = useState([]);
  const [checkStockSupply, setCheckStockSupply] = useState(false);

  const getAndSetReturnToProvider = () => {
    getReturnToProviderOne(id)
      .then((res) => {
        setComment(res.comment);
        setAdmin(res.admin);
        setNumber(res.number);
        setProducts(res.products);
        setDate(dateFormat(res.date));
        setSupply(res.supply);
        setCarriedOut(res.carriedOut);
        setCheckStockSupply(res.checkStockSupply);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetReturnToProvider();
  }, []);

  const getAndSetSupply = () => {
    if (!supplyNumber) return;
    getSupplyByNumber(supplyNumber)
      .then((res) => {
        setSupply(res);
        setProducts(
          res.products.map((val) => {
            return {
              ...val,
              countOld: val.count,
              count: 0,
            };
          })
        );
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const createReturnToProviderHandler = () => {
    createReturnToProvider({
      date,
      supply: {
        id: supply.id,
      },
      comment,
      products: products.filter((prod) => prod.count > 0),
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Возврат поставщику успешно создан");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const updateReturnToProviderHandler = () => {
    updateReturnToProvider({
      id,
      checkStockSupply,
      comment,
    })
      .then((res) => {
        setComment(res.comment);
        setCheckStockSupply(res.checkStockSupply);
        toast.success("Возврат поставщику обновлен");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutReturnToProvider({
      id,
      carryOut: !carriedOut,
    })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        if (res.carriedOut) {
          toast.success("Возврат поставщику успешно проведен");
        } else {
          toast.success("Возврат поставщику успешно распроведен");
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const getUpdHandler = () => {
    getUpd({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `УПД на возврат № ${number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новый возврат поставщику`;
    }

    return `Возврат поставщику № ${number || ""}`;
  };

  const access = () => {
    if (user.roles?.find((role) => role === "ROLE_ADMIN")) {
      return true;
    }
    return false;
  };

  return (
    <Container fluid className="p-0 return-to-provider-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        <>
          {!isNew && (
            <>
              <div className="return-to-provider-one__date-block">
                <Label>Дата</Label>
                <InputMask
                  id="date"
                  name="date"
                  type="text"
                  onChange={() => {}}
                  value={date}
                  mask={"99.99.9999"}
                  className="form-control date"
                  disabled
                />
              </div>
              <div className="corr-order-one__carried-out-button">
                {carriedOut && <CheckCircle color="green" />}

                <Button
                  color="primary"
                  onClick={carryOutHandler}
                  className="button-wide ml-1"
                >
                  {carriedOut ? `Распровести` : `Провести`}
                </Button>
              </div>
            </>
          )}
        </>
      </HeaderPage>

      <Card className="entering-balance-one__form mb-0">
        <CardBody className="pb-3">
          {isNew ? (
            <Row>
              <Col md="6">
                <Label className="text-sm-right">
                  На основании поступления
                </Label>
                <InputGroup>
                  <Input
                    name="supplyNumber"
                    placeholder="Поступление"
                    value={supplyNumber || ""}
                    onChange={(e) => setSupplyNumber(e.target.value)}
                  />
                  <Button color="primary" onClick={getAndSetSupply}>
                    загрузить
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md="4">
                <Label className="text-sm-right">
                  На основании поступления
                </Label>
              </Col>
              <Col md="4">
                <b>{supply.number}</b>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="4">
              <Label className="text-sm-right">Номер поступления</Label>
            </Col>
            <Col md="4">
              <b>{supply.factNum}</b>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Label className="text-sm-right">Поставщик</Label>
            </Col>
            <Col md="4">
              <b>{supply.provider?.name}</b>
            </Col>
          </Row>

          {isNew ? (
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Создал поступление</Label>
              </Col>
              <Col md="4">
                <b>{supply.admin?.name}</b>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Создал возврат</Label>
              </Col>
              <Col md="4">
                <b>{admin}</b>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment || ""}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
          {access() && (
            <Row>
              <Col>
                <Label>
                  Не проверять привязку к поступлению при проведении
                </Label>
                <Checkbox
                  name="checkStockSupply"
                  value="checkStockSupply"
                  checked={checkStockSupply}
                  onChange={(e) => setCheckStockSupply(e.target.checked)}
                />
              </Col>
            </Row>
          )}

          {carriedOut && (
            <Row>
              <Col>
                <Button
                  color="primary"
                  onClick={getUpdHandler}
                  className="button-wide"
                >
                  Печать
                </Button>
              </Col>
            </Row>
          )}

          {!isNew && !carriedOut && (
            <Row>
              <Col>
                <Button
                  color="primary"
                  onClick={updateReturnToProviderHandler}
                  className="button-wide"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      {isNew ? (
        <ReturnToProviderProductTableNew products={products} />
      ) : (
        <ReturnToProviderProductTable
          products={products}
          setProducts={setProducts}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      <Card>
        <CardBody>
          {isNew && (
            <Button
              color="primary"
              onClick={createReturnToProviderHandler}
              className="button-wide"
            >
              Создать
            </Button>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
