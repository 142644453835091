import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  carryOutAcceptanceAct,
  createAcceptanceAct,
  getAcceptanceActLayoutSheet,
  getAcceptanceActOne,
  importAcceptanceAct,
} from "services/apiSupply";
import { dateFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import { MySpinner } from "components/MySpinner";
import { AcceptanceActProductTable } from "features/AcceptanceActs/AcceptanceActProductTable";
import { AcceptanceActProductTableNew } from "features/AcceptanceActs/AcceptanceActProductTableNew";
import { SelectProvider } from "components/SelectProvider";
import { AddProductModal } from "features/AcceptanceActs/AddProductModal";

import "./acceptanceActOne.scss";

export const AcceptanceActOnePage = () => {
  const history = useHistory();
  const { id } = useParams();

  const [isNew, setIsNew] = useState(!id ? true : false);
  const [openModal, setOpenModal] = useState(false);
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [products, setProducts] = useState([]);
  const [provider, setProvider] = useState(null);
  const [carriedOut, setCarriedOut] = useState(false);
  const [acceptanceActFile, setAcceptanceActFile] = useState(null);
  const inputAcceptanceActFile = useRef(null);

  const getAndSetAcceptanceAct = () => {
    getAcceptanceActOne(id)
      .then((res) => {
        setProducts(res.products);
        setNumber(res.number);
        setDate(res.date);
        setCarriedOut(res.carriedOut);
        setProvider({ value: res.provider?.id, title: res.provider?.name });
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const validate = () => {
    if (products.find((val) => val.nomenclature === null)) {
      return false;
    }

    return true;
  };

  const createAcceptanceActHandler = () => {
    if (!validate()) {
      toast.error("Есть ошибочные позиции");
      return;
    }
    setLoading(true);
    createAcceptanceAct({
      provider: provider?.value,
      products,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Акт успешно создан");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutAcceptanceAct({ id, carryOut: carriedOut ? 0 : 1 })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        if (res.carriedOut) {
          toast.success("Акт успешно проведен");
        } else {
          toast.success("Акт успешно распроведен");
        }
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetAcceptanceAct();
  }, []);

  useEffect(() => {
    if (!acceptanceActFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", acceptanceActFile);
    importAcceptanceAct(formData)
      .then((res) => {
        setProducts(res);
      })
      .finally(() => {
        setLoading(false);
        inputAcceptanceActFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptanceActFile]);

  const getLayoutSheetHandler = () => {
    if (!id) return;
    setLoading(true);
    getAcceptanceActLayoutSheet({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Раскладочный лист № ${number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новый акт приема-передачи`;
    }

    return `Акт приема передачи № ${number}`;
  };

  const importHandler = () => {
    if (inputAcceptanceActFile) {
      inputAcceptanceActFile.current.click();
    }
  };

  return (
    <Container fluid className="p-0 corr-order-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        {!isNew && (
          <div className="corr-order-one__carried-out-button">
            {carriedOut && <CheckCircle color="green" />}

            <Button
              color="primary"
              onClick={carryOutHandler}
              className="button-wide ml-1"
            >
              {carriedOut ? `Распровести` : `Провести`}
            </Button>
          </div>
        )}
      </HeaderPage>

      <Card className="corr-order-one__form mb-0">
        <CardBody className="pb-3">
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Дата:</Label>
            </Col>
            <Col md="9">
              <b>{dateFormat(date)}</b>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label className="text-sm-right">Поставщик: </Label>
            </Col>
            <Col md="9">
              {isNew ? (
                <SelectProvider provider={provider} setProvider={setProvider} />
              ) : (
                <b>{provider?.title}</b>
              )}
            </Col>
          </Row>
          {!carriedOut ? (
            <Row>
              <Col md={3}>
                <Button
                  onClick={() => setOpenModal(true)}
                  color="primary"
                  className="button-wide btn btn-primary mr-2"
                >
                  Добавить товар
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  onClick={importHandler}
                  color="primary"
                  className="button-wide btn btn-primary"
                >
                  Импорт
                </Button>
                <Input
                  type="file"
                  id="acceptanceAct"
                  hidden
                  innerRef={inputAcceptanceActFile}
                  onChange={(e) => setAcceptanceActFile(e.target.files[0])}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Button className="ml-3" onClick={getLayoutSheetHandler}>
                Скачать раскладку
              </Button>
            </Row>
          )}
        </CardBody>
      </Card>

      {isNew ? (
        <AcceptanceActProductTableNew
          products={products}
          setProducts={setProducts}
        />
      ) : (
        <AcceptanceActProductTable
          products={products}
          setProducts={setProducts}
          setLoading={setLoading}
          carriedOut={carriedOut}
        />
      )}

      <Card>
        <CardBody>
          {isNew && (
            <Button
              color="primary"
              onClick={createAcceptanceActHandler}
              className="button-wide"
            >
              Создать
            </Button>
          )}
        </CardBody>
      </Card>

      <AddProductModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setProducts={setProducts}
      />
    </Container>
  );
};
