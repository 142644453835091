import React from "react";
import { X as XIcon } from "react-feather";

import "./closeButton.scss";

export const CloseButton = ({ onClick }) => {
  return (
    <button className="close-button" onClick={onClick}>
      <XIcon color="white" size={13} />
    </button>
  );
};
