import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { Link } from "react-router-dom";
import { dateFormat } from "utils/format";

export const GetReserveModal = ({
  reservedOrders,
  openModal,
  setOpenModal,
  loading,
}) => {
  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Товар в резерве в заказах</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      {loading && <MySpinner overlay />}
      <ModalBody className="m-3">
        {reservedOrders.map((val) => (
          <Row key={val.id}>
            <Col lg="2">
              <Link to={`/sales/orders/${val.id}`} target="_blank">
                № {val.number}
              </Link>
            </Col>
            <Col lg="2">{dateFormat(val.date)}</Col>
            <Col lg="8">{val.comment}</Col>
          </Row>
        ))}
      </ModalBody>
    </Modal>
  );
};
