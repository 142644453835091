import React, { useEffect, useState } from "react";


import "./ucdPrint.scss";

export const UcdPrintPage = () => {
  return (
    <div className="landscape" >
      <style>
        {`@media print {
            @page {
              size: A4 landscape
				    }}
          }`
        }
      </style>
      sdfasdasdfasdf
    </div>
  );
};
