import React, { useEffect, useState } from "react";
import Select from "react-select";

import { providersSuggestion } from "services/api";

export const SelectProvider = ({ provider, setProvider }) => {
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const providerMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name };
    });

  useEffect(() => {
    if (search.length < 3) {
      setSuggestions([]);
      return;
    }
    setSearchLoading(true);
    providersSuggestion(search)
      .then((res) => setSuggestions(providerMap(res)))
      .finally(() => setSearchLoading(false));
  }, [search]);

  return (
    <Select
      name="provider"
      placeholder="Выберите поставщика"
      className="react-select-container"
      classNamePrefix="react-select"
      options={suggestions}
      isSearchable
      isClearable
      isLoading={searchLoading}
      inputValue={search}
      onInputChange={setSearch}
      value={provider}
      onChange={setProvider}
    />
  );
};
