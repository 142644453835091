import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { CheckCircle, XCircle } from "react-feather";

import { DEPARTMENTS } from "pages/admins";

export const AdminsTable = ({ admins, setOpenModal, setId }) => {
  const tableColumns = [
    {
      dataField: "name",
      text: "ФИО",
      formatter: (cell, row) => (
        <>
          {row.lastName} {row.firstName} {row.middleName}
        </>
      ),
      headerStyle: () => ({ width: "25%" }),
    },
    {
      dataField: "active",
      text: "Активен",
      formatter: (cell) =>
        cell ? (
          <CheckCircle color="green" size={17} />
        ) : (
          <XCircle color="red" size={17} />
        ),
      headerStyle: () => ({ width: "3%" }),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "phone",
      text: "Телефон",
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "department",
      text: "Отдел",
      formatter: (cell) => DEPARTMENTS.find((val) => val.value === cell)?.label,
      headerStyle: () => ({ width: "15%" }),
    },
    {
      dataField: "",
      text: "",
      headerStyle: () => ({ width: "27%" }),
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      setId(row.id);
      setOpenModal(true);
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={admins}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        classes="pointer"
        rowEvents={rowEvents}
      />
    </>
  );
};
