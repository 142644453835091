import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";

import { validHandler } from "utils/validation";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import {
  addSupplyProduct,
  getCountry,
  updateSupplyProduct,
} from "services/apiSupply";

export const AddProductModal = ({ openModal, setOpenModal, setProducts }) => {
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [summ, setSumm] = useState("");
  const [count, setCount] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [gtd, setGtd] = useState("");
  const [nomenclature, setNomenclature] = useState(null);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const summInputHandler = (e) => {
    const summ = e.target.value.replace(",", ".").replace(" ", "");

    if (summ === "" || /^[0-9\b\.]+$/.test(summ)) {
      setSumm(summ);

      setPrice(((parseFloat(summ) * 100) / count || 1) / 100);
    }
  };

  const getAndSetCountry = () => {
    getCountry(countryCode)
      .then((res) => {
        if (!res) {
          toast.error("Не найдена страна по указанному коду");
          return;
        }

        setCountryName(res.shortName);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (countryCode.length < 3) return;
    getAndSetCountry();
  }, [countryCode]);

  const priceInputHandler = (e) => {
    // const price = e.target.value;
    // if (price === "" || /^[0-9\b]+$/.test(price)) {
    //   setPrice(price);
    // }
  };

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);

      setPrice(((parseFloat(summ) * 100) / count || 1) / 100);
    }
  };

  const selectedBrand = (brand) => {
    setBrand(brand?.name);
  };

  const addProductHandler = () => {
    setProducts((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        index: prev.length + 1,
        article,
        brand,
        name,
        nomenclature,
        price,
        count,
        summ,
        countryCode,
        countryName,
        gtd,
      },
    ]);

    setArticle("");
    setBrand("");
    setName("");
    setPrice("");
    setSumm("");
    setCountryCode("");
    setCountryName("");
    setGtd("");
    setCount(1);
    setNomenclature(null);
    setOpenModal(false);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Добавить товар в акт</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>

      {loading && <MySpinner overlay />}

      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>
              <ButtonGroup style={{ width: "100%" }}>
                <AutocompleteArticle
                  article={article}
                  brand={brand}
                  setArticle={setArticle}
                  setBrand={({ id, name }) => setBrand(name)}
                  setName={setName}
                  setNomenclature={setNomenclature}
                  setPrice={setPrice}
                  needValid={needValid}
                  errors={errors}
                  style={{ width: "218px" }}
                />
                <CreateNomenclatureModal
                  articleHandler={setArticle}
                  brandHandler={selectedBrand}
                  nameHandler={setName}
                  nomenclatureHandler={setNomenclature}
                />
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Сумма</Label>
              <Input
                name="summ"
                placeholder="Сумма"
                onChange={summInputHandler}
                value={summ}
                invalid={needValid && errors.hasOwnProperty("summ")}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Цена</Label>
              <Input
                name="price"
                placeholder="Цена"
                onChange={priceInputHandler}
                value={price}
                invalid={needValid && errors.hasOwnProperty("price")}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col lg="2">
            <FormGroup>
              <Label>Код страны</Label>
              <Input
                name="countryCode"
                placeholder="Цена"
                onChange={(e) => setCountryCode(e.target.value)}
                value={countryCode}
                invalid={needValid && errors.hasOwnProperty("countryCode")}
                maxLength="3"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <Label>Страна</Label>
              <Input
                name="countryName"
                placeholder="Страна"
                onChange={(e) => setCountryName(e.target.value)}
                value={countryName}
                invalid={needValid && errors.hasOwnProperty("countryName")}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>ГТД</Label>
              <Input
                name="gtd"
                placeholder="Кол-во"
                onChange={(e) => setGtd(e.target.value)}
                value={gtd}
                invalid={needValid && errors.hasOwnProperty("gtd")}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button
            onClick={addProductHandler}
            color="primary"
            className="button-wide"
          >
            Добавить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
