import classNames from "classnames";
import React from "react";
import { CornerRightDown, CornerRightUp, X as Xicon } from "react-feather";
import { Button, FormGroup, Input, Label } from "reactstrap";

import "./dashboardToolbar.scss";

export const DashboardToolbar = ({
  fileName,
  setFileName,
  getEstimateFile,
  setEstimateFile,
  inputEstimateFile,
  getOrderFile,
  inputOrderFile,
  setOrderFile,
}) => {
  return (
    <>
      <FormGroup>
        <Label>Проценка из файла</Label>
        <div className="dashboard-toolbar__import-buttons">
          <Button
            href="https://api.lider-truck.ru/files/templates/estimate.xlsx"
            className="button-wide btn btn-secondary"
          >
            Скачать шаблон
            <CornerRightDown size={15} className="ml-1" />
          </Button>
          <div className="dashboard-toolbar__with-reset">
            <Button
              color="primary"
              onClick={getEstimateFile}
              className={classNames("button-wide", {
                "dashboard-toolbar__red-button": fileName,
              })}
            >
              Загрузить 
              <CornerRightUp size={15} className="ml-1" />
            </Button>
            {fileName && (
              <button
                type="button"
                onClick={() => setFileName(null)}
                className="dashboard-toolbar__reset-upload"
              >
                <Xicon size={13} />
              </button>
            )}
          </div>

          <Input
            type="file"
            id="file"
            hidden
            innerRef={inputEstimateFile}
            onChange={(e) => setEstimateFile(e.target.files[0])}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <Label>Импорт заказа из файла</Label>
        <div className="dashboard-toolbar__import-buttons">
          <div className="dashboard-toolbar__import-buttons">
            <Button
              href="https://api.lider-truck.ru/files/templates/orderTemplate.xls"
              className="button-wide btn btn-secondary"
            >
              Скачать шаблон
              <CornerRightDown size={15} className="ml-1" />
            </Button>
            <Button
              onClick={getOrderFile}
              className="button-wide btn btn-tertiary"
            >
              Загрузить
              <CornerRightUp size={15} className="ml-1" />
            </Button>
          </div>
          <Input
            type="file"
            id="order"
            hidden
            innerRef={inputOrderFile}
            onChange={(e) => setOrderFile(e.target.files[0])}
          />
        </div>
      </FormGroup>
    </>
  );
};
