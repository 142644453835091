import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Plus, Copy } from "react-feather";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { AutocompleteArticle } from "../AutocompleteArticle";

import { MySpinner } from "components/MySpinner";
import { SelectSearch } from "components/SelectSearch";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { createProduct, providersSuggestion } from "services/api";
import { validHandler } from "utils/validation";
import { CloseButton } from "components/CloseButton";
import classNames from "classnames";
import { TableButton } from "components/TableButton";

export const CreateProductModal = ({
  row = null,
  small = false,
  addPrice = true,
  callBack = null,
  action = null,
}) => {
  const [article, setArticle] = useState(row?.article ?? "");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState({
    label: row?.brandName ?? "",
    value: row?.brandId ?? "",
  });
  const [name, setName] = useState(row?.name ?? "");
  const [nomenclature, setNomenclature] = useState(null);
  const [price, setPrice] = useState(row?.pricePurchase ?? "");
  const [count, setCount] = useState(row?.exist ?? "");
  const [provider, setProvider] = useState({
    label: row?.providerName ?? "",
    value: row?.providerId ?? "",
  });
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [needValid, setNeedValid] = useState(false);

  const validNomenclature = {
    article,
    brand: brand.value,
    name,
  };

  const validPrice = {
    article,
    brand: brand.value,
    name,
    price,
    count,
    provider: provider.value,
  };

  useEffect(() => {
    const { errors } = validHandler(addPrice ? validPrice : validNomenclature);
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, name, price, count, provider]);

  useEffect(() => {
    if (row?.brand) {
      setBrand({
        label: row?.brand,
        value: row?.brand,
      });
    }
    // eslint-disable-next-line
  }, [row]);

  const selectedBrand = (brand) => {
    setBrand({
      label: brand?.name,
      value: brand?.id,
    });
  };

  const createProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler(
      addPrice ? validPrice : validNomenclature
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);

    createProduct({
      article,
      brand: brand.label,
      name,
      nomenclature,
      price,
      count,
      provider: provider.value,
    })
      .then((res) => {
        setArticle("");
        setBrand("");
        setName("");
        setPrice("");
        setCount("");
        setProvider("");
        setSuccess("Товар успешно добавлен");
        setError(null);
        if (callBack) {
          callBack(res);
        }
        setOpen(false);
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setError(e.response.data.error);
        } else {
          setError("Ошибка создания товара");
        }
      })
      .finally(() => {
        setLoading(false);
        setNeedValid(false);
      });
  };

  const priceInputHandler = (e) => {
    const price = e.target.value;
    if (price === "" || /^[0-9\b]+$/.test(price)) {
      setPrice(price);
    }
  };

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);
    }
  };

  return (
    <>
      {action === "copy" ? (
        <TableButton
          Icon={Copy}
          onClick={() => setOpen(true)}
          className="size-big"
        />
      ) : (
        <Button
          onClick={() => setOpen(true)}
          color={action === "copy" ? "gray" : "primary"}
          className={classNames({ "button-wide": !small })}
        >
          {action === "copy" ? (
            <FontAwesomeIcon icon={faCopy} color="gray" />
          ) : (
            <>{small ? <Plus /> : <>Добавить товар</>}</>
          )}
        </Button>
      )}

      <Modal isOpen={open} toggle={() => setOpen(false)}>
        {success && <Alert color="success">{success}</Alert>}
        {error && <Alert color="danger">{error}</Alert>}
        {loading && <MySpinner overlay />}
        <ModalHeader>
          <div className="modal-title-text">
            {action === "copy" ? "Копировать товар" : "Добавить товар"}
          </div>
          <CloseButton onClick={() => setOpen(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label>Артикул</Label>
                <ButtonGroup style={{ width: "100%" }}>
                  <AutocompleteArticle
                    article={article}
                    brand={brand}
                    setArticle={setArticle}
                    setBrand={selectedBrand}
                    setName={setName}
                    setNomenclature={setNomenclature}
                    setPrice={setPrice}
                    needValid={needValid}
                    errors={errors}
                    style={{ width: "218px" }}
                  />
                  <CreateNomenclatureModal
                    articleHandler={setArticle}
                    brandHandler={selectedBrand}
                    nameHandler={setName}
                    nomenclatureHandler={setNomenclature}
                  />
                </ButtonGroup>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Бренд</Label>
                <Input
                  name="brand"
                  placeholder="Бренд"
                  value={brand.label}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label>Наименование</Label>
                <Input
                  name="name"
                  placeholder="Наименование"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  invalid={needValid && errors.hasOwnProperty("name")}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>

          {addPrice && (
            <>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label>Цена</Label>
                    <Input
                      name="price"
                      placeholder="Цена"
                      onChange={priceInputHandler}
                      value={price}
                      invalid={needValid && errors.hasOwnProperty("price")}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label>Кол-во</Label>
                    <Input
                      name="count"
                      placeholder="Кол-во"
                      onChange={countInputHandler}
                      value={count}
                      invalid={needValid && errors.hasOwnProperty("count")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label>Поставщик</Label>
                    <SelectSearch
                      name="provider"
                      placeholder="Выберите поставщика"
                      functionSuggestion={providersSuggestion}
                      value={provider}
                      setValue={setProvider}
                      needValid={needValid}
                      errors={errors}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          <div className="d-flex justify-content-between">
            <Button onClick={() => setOpen(false)} color="secondary">
              Закрыть
            </Button>
            <Button onClick={createProductHandler} color="primary">
              Сохранить
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
