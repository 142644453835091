import React, { useState } from "react";
import Select from "react-select";

import { updateMisgradingProduct } from "services/apiWarehouse";

export const ChangeWarehouse = ({
  row,
  warehouses,
  setLoading,
  setProducts,
}) => {
  const [warehouse, setWarehouse] = useState({
    label: row.warehouse?.name,
    value: row.warehouse?.id,
  });

  const warehouseHandler = (data) => {
    setWarehouse(data);
    setLoading(true);
    updateMisgradingProduct({
      ...row,
      warehouse: data.value,
    })
      .then((res) => setProducts(res.products))
      .finally(() => setLoading(false));
  };

  return (
    <Select
      name="warehouse"
      placeholder="Выберите склад"
      className="react-select-container"
      classNamePrefix="react-select"
      options={warehouses.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      })}
      value={warehouse}
      onChange={warehouseHandler}
    />
  );
};
