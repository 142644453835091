import React, { useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { CloseButton } from "components/CloseButton";
import {
  getSupplyProductsByNomenclature,
  updateStock,
} from "services/apiWarehouse";
import { toast } from "react-toastify";
import { MySpinner } from "components/MySpinner";
import { dateFormat } from "utils/format";

export const ResetDocumentsModal = ({
  openModal,
  setOpenModal,
  nomenclature,
  stockId,
  getAndSetStock,
  warehouse,
}) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAndSetDocuments = () => {
    getSupplyProductsByNomenclature({
      nomenclature,
      warehouse,
      stockId,
    })
      .then(setDocuments)
      .catch((e) => toast.error(e.responce?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (openModal === false) return;
    getAndSetDocuments();
  }, [openModal]);

  const setDocumentHandler = (val) => {
    setLoading(true);
    updateStock({
      id: stockId,
      supplyProduct: val.document === "supply" ? val.id : null,
      enteringBalanceProduct:
        val.document === "enteringBalance" ? val.id : null,
    })
      .then(() => {
        getAndSetDocuments();
        getAndSetStock();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => {});
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">
          Изменить привязку документа
          <span className="modal-title-small">ID: {stockId}</span>
        </div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        {loading && <MySpinner overlay />}
        {documents.map((val) => (
          <Row key={val.id}>
            <Col lg="4">{val.id}</Col>
            <Col lg="3">
              <span className="link" onClick={() => setDocumentHandler(val)}>
                {val.number}
              </span>
            </Col>
            <Col lg="2">{dateFormat(val.date)}</Col>
            <Col lg="1">{val.stockByProduct}</Col>
          </Row>
        ))}
      </ModalBody>
    </Modal>
  );
};
