import react, { useEffect, useState } from "react";
import Select from "react-select";
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import { FormTitle } from "components/FormTitle";

import "./customerInfo.scss";
import classNames from "classnames";

export const TYPOLOGY = [
  { label: "Автопарк", value: "autopark" },
  { label: "СТО", value: "serviceStation" },
  { label: "Магазин", value: "shop" },
  { label: "Оптовый оператор", value: "wholesaleOperator" },
];

export const NEED_HELP = [
  { label: "Нет", value: "no" },
  { label: "Да", value: "yes" },
];

export const CustomerInfo = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  info,
  setInfo
}) => {
  return (
    <Card className="customer-info mb-0">
      <CardBody>
        <FormTitle>Информация о клиенте</FormTitle>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Типология клиента <span>*</span></Label>
            <Select
              name="typology"
              placeholder="Выберите типологию клиента"
              className={classNames("react-select-container", { "is-invalid": errors.typology && touched.typology ? true : false })}
              classNamePrefix="react-select"
              options={TYPOLOGY}
              value={TYPOLOGY.find((type) => values?.typology === type.value)}
              onChange={val => {
                setFieldValue("typology", val?.value);
                setInfo((prev) => ({
                  ...prev,
                  typology: val?.value
                }))
              }}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        {values?.typology === "autopark" && (
          <>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">MERCEDES</Label>
                <Input
                  name="mercedes"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.mercedes || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        mercedes: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
              <Col className="elem-field">
                <Label className="text-sm-right">MAN</Label>
                <Input
                  name="man"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.man || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        man: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
              <Col className="elem-field">
                <Label className="text-sm-right">SCANIA</Label>
                <Input
                  name="scania"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.scania || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        scania: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
              <Col className="elem-field">
                <Label className="text-sm-right">VOLVO</Label>
                <Input
                  name="volvo"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.volvo || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        volvo: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">DAF</Label>
                <Input
                  name="daf"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.daf || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        daf: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
              <Col className="elem-field">
                <Label className="text-sm-right">IVECO</Label>
                <Input
                  name="iveco"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.iveco || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        iveco: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
              <Col className="elem-field">
                <Label className="text-sm-right">RENAULT</Label>
                <Input
                  name="renault"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.renault || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        renault: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Прицепы</Label>
                <Input
                  name="trailers"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.trailers || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        trailers: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>

              <Col className="elem-field">
                <Label className="text-sm-right">Китайские авто</Label>
                <Input
                  name="chinaCars"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.chinaCars || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        chinaCars: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
              <Col className="elem-field">
                <Label className="text-sm-right">Отечественные авто</Label>
                <Input
                  name="russianCars"
                  type="number"
                  placeholder="Кол-во"
                  min="0"
                  value={info?.autopark?.russianCars || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        russianCars: Number(e.target.value),
                      },
                    }))
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Другое</Label>
                <Input
                  name="other"
                  placeholder="Напишите какие еще авто есть у клиента"
                  value={info?.autopark?.other || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      autopark: {
                        ...prev.autopark,
                        other: e.target.value,
                      },
                    }))
                  }
                />
              </Col>
            </Row>
          </>
        )}

        {info?.typology === "serviceStation" && (
          <>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Количество постов</Label>
                <Input
                  name="russianCars"
                  type="number"
                  placeholder="Количество постов"
                  value={info?.numbersOfPosts || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      numbersOfPosts: Number(e.target.value),
                    }))
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col className="elem-field">
                <Label className="text-sm-right">Какие работы проводят</Label>
                <Input
                  name="russianCars"
                  type="textarea"
                  placeholder="Напишите, какие работы проводят"
                  value={info?.services || ""}
                  onChange={(e) =>
                    setInfo((prev) => ({
                      ...prev,
                      services: e.target.value,
                    }))
                  }
                />
              </Col>
            </Row>
          </>
        )}

        {info?.typology !== "wholesaleOperator" && (
          <Row>
            <Col className="elem-field">
              <Label className="text-sm-right">
                Указать с какими поставщиками работают
              </Label>
              <Input
                name="providers"
                type="textarea"
                placeholder="Указать с какими поставщиками работают"
                value={info?.providers || ""}
                onChange={(e) =>
                  setInfo((prev) => ({
                    ...prev,
                    providers: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">
              Нужна помощь в подборе запчастей?
            </Label>
            <Select
              name="needHelp"
              placeholder="Да или нет"
              className="react-select-container"
              classNamePrefix="react-select"
              options={NEED_HELP}
              value={NEED_HELP.find((val) => info?.needHelp === val.value)}
              onChange={(val) =>
                setInfo((prev) => ({ ...prev, needHelp: val.value }))
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="elem-field">
            <Label className="text-sm-right">Дополнительная информация</Label>
            <Input
              name="addInfo"
              type="textarea"
              placeholder="Например, основная типология Автопарк, но есть посты для обслуживания техники"
              value={info?.addInfo || ""}
              onChange={(e) =>
                setInfo((prev) => ({
                  ...prev,
                  addInfo: e.target.value,
                }))
              }
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
