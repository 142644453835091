import { GoBack } from "components/GoBack";
import React from "react";

import "./headerPage.scss";

export const HeaderPage = ({ title, children, Element = false }) => {
  return (
    <div className="header-page">
      <div className="header-page__left">
        <h1 className="page-title">{title}</h1>

        {children}
      </div>
      {Element ? <Element /> : <GoBack />}
    </div>
  );
};
