import React, { useState } from "react";

import { MySpinner } from "components/MySpinner";
import { getStock } from "services/apiWarehouse";
import { Col, Row, Table } from "reactstrap";

import "./skladPopup.scss";

export const SkladPopup = ({ children, row }) => {
  const [show, setShow] = useState(false);
  const [balance, setBalance] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAndSetStock = () => {
    setLoading(true);
    getStock({
      nomenclature: row.nomenclature?.id,
    })
      .then((res) => {
        setBalance(res.balanceByWarehouse || []);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const popupHandler = () => {
    setShow(true);
    getAndSetStock();
  };

  const calcInStock = () => {
    return balance.reduce(
      (acc, val) => acc + parseInt(val.balance?.inStock),
      0
    );
  };

  const calcInReserve = () => {
    return balance.reduce(
      (acc, val) => acc + parseInt(val.balance?.inReserve),
      0
    );
  };

  return (
    <div
      onMouseEnter={popupHandler}
      onMouseLeave={() => setShow(false)}
      className="sklad-hover"
    >
      {show && (
        <div className="sklad-hover__popup">
          {loading && <MySpinner overlay />}

          {balance.length > 0 && (
            <table className="table">
              <tbody>
                <tr>
                  <td style={{ width: "60%" }}>Склад</td>
                  <td style={{ width: "20%" }}>Ост.</td>
                  <td style={{ width: "20%" }}>Рез.</td>
                </tr>
                {balance.map((val) => (
                  <tr key={val.warehouse?.id}>
                    <td>{val.warehouse?.name}</td>
                    <td>{val.balance?.inStock}</td>
                    <td>{val.balance?.inReserve}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>Итого:</td>
                  <td>
                    <b>{calcInStock()}</b>
                  </td>
                  <td>
                    <b>{calcInReserve()}</b>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      )}
      {children}
    </div>
  );
};
