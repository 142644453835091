import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { PlusSquare, X as XIcon } from "react-feather";
import classNames from "classnames";
import Select from "react-select";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { createCharacteristic } from "services/api";
import { TYPES } from "../CharacteristiscTable";

export const CharacteristicsModal = ({
  openModal,
  setOpenModal,
  setLoading,
  loading,
  getAndSetCharacteristics,
}) => {
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [type, setType] = useState(null);

  const createCharacteristicHandler = () => {
    setLoading(true);
    createCharacteristic({
      name,
      unit,
      type: type?.value,
    })
      .then((res) => {
        toast.success("Характеристика успешно создана");
        setOpenModal(false);
        getAndSetCharacteristics();
        setName("");
        setUnit("");
        setType(null);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={() => setOpenModal(false)}
      className="nomenclature-modal"
    >
      <ModalHeader>
        <div className="modal-title-text">Создать новую характеристику</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        {loading && <MySpinner overlay />}
        <Row className="main-info">
          <Col md={6}>
            <Label>Характеристика</Label>
            <Input
              maxLength="128"
              name="name"
              placeholder="Характеристика"
              value={name || ""}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Label>Единица измерения</Label>
            <Input
              maxLength="15"
              name="unit"
              placeholder="Единица измерения"
              value={unit || ""}
              onChange={(e) => setUnit(e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Label>Тип данных</Label>
            <Select
              name="type"
              placeholder="Тип данных"
              className="react-select-container"
              classNamePrefix="react-select"
              options={TYPES}
              value={type}
              onChange={setType}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-end mt-3">
          <Button onClick={createCharacteristicHandler} color="primary">
            Сохранить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
