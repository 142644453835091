import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";

import { validHandler } from "utils/validation";
import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";

export const AddProductModal = ({ openModal, setOpenModal, setProducts }) => {
  const [articleOut, setArticleOut] = useState("");
  const [articleIn, setArticleIn] = useState("");
  const [brandOut, setBrandOut] = useState("");
  const [brandIn, setBrandIn] = useState("");
  const [nameOut, setNameOut] = useState("");
  const [nameIn, setNameIn] = useState("");
  const [count, setCount] = useState(0);
  const [nomenclatureOut, setNomenclatureOut] = useState(null);
  const [nomenclatureIn, setNomenclatureIn] = useState(null);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);
    }
  };

  const addProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      nomenclatureOut,
      nomenclatureIn,
      count,
    });

    setErrors(errors);

    if (!valid) return;

    setProducts((prev) => [
      ...prev,
      {
        index: prev.length + 1,
        nomenclatureOut,
        nomenclatureIn,
        articleOut,
        articleIn,
        brandOut,
        brandIn,
        nameOut,
        nameIn,
        count,
      },
    ]);

    setOpenModal(false);

    toast.success("Товар успешно добавлен в пересортицу");
  };

  useEffect(() => {
    const { errors } = validHandler({
      nomenclatureOut,
      nomenclatureIn,
      count,
    });
    setErrors(errors);
    // eslint-disable-next-line
  }, [nomenclatureOut, nomenclatureIn, count]);

  const selectedBrandOut = (brand) => {
    setBrandOut(brand?.name);
  };

  const selectedBrandIn = (brand) => {
    setBrandIn(brand?.name);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Добавить товар в пересортицу</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>
              <ButtonGroup style={{ width: "100%" }}>
                <AutocompleteArticle
                  article={articleOut}
                  brand={brandOut}
                  setArticle={setArticleOut}
                  setBrand={({ id, name }) => setBrandOut(name)}
                  setName={setNameOut}
                  setNomenclature={setNomenclatureOut}
                  needValid={needValid}
                  errors={errors}
                  style={{ width: "218px" }}
                  invalid={
                    needValid && errors.hasOwnProperty("nomenclatureOut")
                  }
                />
                <CreateNomenclatureModal
                  articleHandler={setArticleOut}
                  brandHandler={selectedBrandOut}
                  nameHandler={setNameOut}
                  nomenclatureHandler={setNomenclatureOut}
                />
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brandOut"
                placeholder="Бренд"
                value={brandOut}
                invalid={needValid && errors.hasOwnProperty("brandOut")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="nameOut"
                placeholder="Наименование"
                onChange={(e) => setNameOut(e.target.value)}
                value={nameOut}
                invalid={needValid && errors.hasOwnProperty("nameOut")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="modal-dividing" />

        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>
              <ButtonGroup style={{ width: "100%" }}>
                <AutocompleteArticle
                  article={articleIn}
                  brand={brandIn}
                  setArticle={setArticleIn}
                  setBrand={({ id, name }) => setBrandIn(name)}
                  setName={setNameIn}
                  setNomenclature={setNomenclatureIn}
                  needValid={needValid}
                  errors={errors}
                  style={{ width: "218px" }}
                  invalid={needValid && errors.hasOwnProperty("nomenclatureIn")}
                />
                <CreateNomenclatureModal
                  articleHandler={setArticleIn}
                  brandHandler={selectedBrandIn}
                  nameHandler={setNameIn}
                  nomenclatureHandler={setNomenclatureIn}
                />
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brandIn"
                placeholder="Бренд"
                value={brandIn}
                invalid={needValid && errors.hasOwnProperty("brandIn")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="nameIn"
                placeholder="Наименование"
                onChange={(e) => setNameIn(e.target.value)}
                value={nameIn}
                invalid={needValid && errors.hasOwnProperty("nameIn")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button
            onClick={addProductHandler}
            color="primary"
            className="button-wide"
          >
            Добавить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
