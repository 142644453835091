import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Row, UncontrolledButtonDropdown } from "reactstrap";
import { Formik } from "formik";
import { object, array, string, setLocale } from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import classNames from "classnames";

import { locale } from "utils/validationLocale";
import { MySpinner } from "components/MySpinner";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";
import { HeaderPage } from "components/HeaderPage";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { TableButton } from "components/TableButton";
import { Trash } from "react-feather";
import { getWarehouses } from "services/apiWarehouse";
import { createOrUpdateSendingPriceList, getSendingPriceListOne } from "services/api";
import { SelectProvider } from "components/SelectProvider";
import { selectedStatuses, selectedTimes } from "utils/select2";

setLocale(locale);

const SendingPriceListSchema = object().shape({
  name: string().min(2).required(),
  subject: string().min(2).required()
});

const TIMES = [
  { value: 0, label: "0:00" },
  { value: 1, label: "1:00" },
  { value: 2, label: "2:00" },
  { value: 3, label: "3:00" },
  { value: 4, label: "4:00" },
  { value: 5, label: "5:00" },
  { value: 6, label: "6:00" },
  { value: 7, label: "7:00" },
  { value: 8, label: "8:00" },
  { value: 9, label: "9:00" },
  { value: 10, label: "10:00" },
  { value: 11, label: "11:00" },
  { value: 12, label: "12:00" },
  { value: 13, label: "13:00" },
  { value: 14, label: "14:00" },
  { value: 15, label: "15:00" },
  { value: 16, label: "16:00" },
  { value: 17, label: "17:00" },
  { value: 18, label: "18:00" },
  { value: 19, label: "19:00" },
  { value: 20, label: "20:00" },
  { value: 21, label: "21:00" },
  { value: 22, label: "22:00" },
  { value: 23, label: "23:00" },
]

export const SendingPriceListOnePage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [sendingPriceList, setSendingPriceList] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [provider, setProvider] = useState(null);
  const [warehouses, setWarehouses] = useState([]);

  const [filterTimes, setFilterTimes] = useState([]);

  const getAndSetSendingPriceList = () => {
    setLoading(true);
    getSendingPriceListOne(id)
      .then((res) => {
        setSendingPriceList(res);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (!id) return;
    getAndSetSendingPriceList();
  }, []);

  const sorted = useCallback((times) => {
    return times.sort(function (a, b) {
      if (a.value > b.value) {
        return 1;
      }
      if (a.value < b.value) {
        return -1;
      }
      return 0;
    })
  }, [filterTimes])

  const submitHandler = (values) => {
    setLoading(true);
    createOrUpdateSendingPriceList({
      id: values.id,
      customerId: values.customer?.value,
      name: values.name,
      specification: {
        emails: values.emails,
        warehouses: values.warehouses?.map(v => v.value),
        time: values.time.map(v => v.value),
        subject: values.subject,
        provider: values.provider
      }
    })
      .then(res => {
        setSendingPriceList(res);
        toast.success("Изменения успешно сохранены");
      })
      .catch(e => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false))
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetWarehouses();
  }, []);

  const renderTitle = () => {
    if (!id) {
      return `Новая рассылка`;
    }

    return `Рассылка "${sendingPriceList?.name}"`;
  };

  return (
    <Container fluid className="p-0 sending-price-list-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>

      <Formik
        initialValues={{
          id: sendingPriceList?.id || "",
          name: sendingPriceList?.name || "",
          customer: {
            label: sendingPriceList?.customer?.name,
            value: sendingPriceList?.customer?.id
          } || null,
          emails: sendingPriceList?.specification?.emails || [],
          warehouses: sendingPriceList?.specification?.warehouses.map(warehouseId => {
            const warehouse = warehouses.find(v => v.id === warehouseId);
            return { label: warehouse?.name, value: warehouse?.id };
          }) || [],
          time: sendingPriceList?.specification?.time.map(v => ({ label: TIMES.find(time => time.value === v).label, value: v })) || [],
          subject: sendingPriceList?.specification?.subject || "",
          provider: sendingPriceList?.specification?.provider || []
        }}
        validationSchema={SendingPriceListSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values);
          submitHandler(values, resetForm);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>

            {loading && <MySpinner overlay />}

            <HeaderPage title={renderTitle()} />

            <Card className="main-form mb-0">
              <CardBody className="pb-3">
                <Row>
                  <Col>
                    <Label className="text-sm-right">Наименование</Label>
                    <Input
                      id="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      invalid={
                        errors.name && touched.name ? true : false
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <SelectCustomer
                      label="Клиент"
                      customer={values.customer}
                      setCustomer={val => setFieldValue("customer", val)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="12" className="elem-field">
                    <Label className="text-sm-right">Email</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="elem-field">
                    <table className="table table-hover">
                      <tbody>
                        {values.emails.map((email, i) => (
                          <tr key={email}>
                            <td>{email}</td>
                            <td>
                              <TableButton
                                Icon={Trash}
                                onClick={() => {
                                  const newEmails = [...values.emails];
                                  newEmails.splice(i, 1);
                                  setFieldValue("emails", newEmails);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <InputGroup>
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        invalid={
                          errors.email && touched.email ? true : false
                        }
                      />
                      <Button onClick={() => {
                        setFieldValue("emails", [...values.emails, email]);
                        setEmail("");
                      }}>Добавить</Button>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Label className="text-sm-right">Склады</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="elem-field">
                    <table className="table table-hover">
                      <tbody>
                        {values.warehouses.map((warehouse, i) => (
                          <tr key={warehouse.value}>
                            <td>{warehouse.label}</td>
                            <td>
                              <TableButton
                                Icon={Trash}
                                onClick={() => {
                                  const newWarehouse = [...values.warehouses];
                                  newWarehouse.splice(i, 1);
                                  setFieldValue("warehouses", newWarehouse);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <InputGroup>
                      <Select
                        isClearable
                        name="warehouse"
                        placeholder="Выберите склад"
                        className={classNames("react-select-container", { "is-invalid": errors.typology && touched.typology ? true : false })}
                        classNamePrefix="react-select"
                        options={warehouses.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                        value={warehouse}
                        onChange={val => {
                          setFieldValue("warehouses", [...values.warehouses, val]);
                          setWarehouse(null);
                        }}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Label className="text-sm-right">Поставщики</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="elem-field">
                    <table className="table table-hover">
                      <tbody>
                        {values.provider.map((provider, i) => (
                          <tr key={provider.id}>
                            <td>{provider.name}</td>
                            <td>
                              <TableButton
                                Icon={Trash}
                                onClick={() => {
                                  const newProvider = [...values.provider];
                                  newProvider.splice(i, 1);
                                  setFieldValue("provider", newProvider);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <InputGroup>
                      <SelectProvider
                        provider={provider}
                        setProvider={setProvider}
                      />
                      <Button onClick={() => {
                        setFieldValue("provider", [...values.provider, { id: provider.value, name: provider.label }]);
                        setProvider(null);
                      }}>
                        Добавить
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Label className="text-sm-right">Расписание рассылки</Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Select
                      isMulti
                      name="status"
                      placeholder="Расписание"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={TIMES}
                      value={sorted(values.time)}
                      onChange={val => setFieldValue("time", val)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Label className="text-sm-right">Тема письма</Label>
                    <Input
                      id="subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      invalid={
                        errors.subject && touched.subject ? true : false
                      }
                    />
                  </Col>
                </Row>

                <div className="d-flex justify-content-end mt-3">
                  <Button type="submit" color="primary">
                    Сохранить рассылку
                  </Button>
                </div>
              </CardBody>
            </Card>
          </form>
        )}
      </Formik>
    </Container >
  );
}