import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import { Cut } from "components/Cut";

export const MarkupsTable = ({ markups, onClick = null }) => {
  const history = useHistory();

  const tableColumns = [
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "markup",
      text: "Наценка",
      formatter: (cell, row) => {
        if (cell.full_price) {
          return `${cell.percent} %`
        } else {
          return <Cut>{Object.entries(cell.list).length > 0 ?
            `${Object.entries(cell.list).map(([price, percent]) => {
              return `до ${price} р: ${percent} %`
            }).join(", ")}, выше - ${cell['~']} %`
            :
            `фикс ${cell['~']} %`}</Cut>;
        }
      },
      headerStyle: () => {
        return { width: "40%" };
      },
    }
  ];

  const rowEvents = {
    onClick: (e, row) => {
      if (onClick) {
        onClick(row.id)
      } else {
        history.push(`/sales/markups/${row.id}`);
      }
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={markups}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
      />
    </>
  );
};
