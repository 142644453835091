
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import { MySpinner } from "components/MySpinner";
import { MarkupsTable } from "features/Markups/MarkupsTable";
import useQuery from "utils/useQuery";
import { getMarkups } from "services/api";
import { Pagination } from "components/Pagination";

export const Markups = () => {
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [loading, setLoading] = useState(false);
  const [markups, setMarkups] = useState([]);
  const [markupsCount, setMarkupsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const getAndSetMarkups = () => {
    getMarkups({
      count: pageSize,
      offset: (currentPage - 1) * pageSize
    })
      .then((res) => {
        setMarkups(res.items);
        setMarkupsCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetMarkups();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  return <Container fluid className="p-0 markups">
    {loading && <MySpinner overlay />}
    <Card className="mb-0 filter">
      <CardBody>
        <h1 className="h3 mb-3">Шаблоны наценок</h1>
        <Row>
          <Col md="2">
            <Link
              to={`/sales/markups/new`}
              color="primary"
              className="button-wide btn btn-primary"
            >
              Добавить
            </Link>
          </Col>
        </Row>
      </CardBody>
    </Card>

    <div>
      <MarkupsTable markups={markups} />

      <Card>
        <CardBody>
          <Pagination
            totalCount={markupsCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </div>
  </Container>
}