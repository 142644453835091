import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { getOpenedOrders } from "services/apiSupply";

export const SetNomenclatureModal = ({
  product,
  products,
  updateData,
}) => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [nomenclature, setNomenclature] = useState("");
  const [needValid, setNeedValid] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);

  const setNomenclatureHandler = () => {
    const row = products[product.index - 1];
    row.nomenclature = {
      id: nomenclature,
      name,
      article,
      brand,
    };

    const newProducts = [...products];
    newProducts.splice(product.index - 1, 1, row);
    updateData(newProducts);

    setOpenModal(false);

    setArticle("");
    setBrand("");
    setName("");
    setNomenclature("");
  };

  const setSuggestions = (suggestions) => {

    if (suggestions.length <= 1) return;

    const findDuplicateArticles = (data) => {
      const articleCount = {};
      const duplicates = [];

      data.forEach(item => {
        const article = item.article;
        articleCount[article] = (articleCount[article] || 0) + 1;
      });

      for (const article in articleCount) {
        if (articleCount[article] > 1) {
          duplicates.push(article);
        }
      }

      return duplicates.length > 0 ? duplicates[0] : null;
    }
    const duplicates = findDuplicateArticles(suggestions);

    if (duplicates) {
      setLoading(true);
      getOpenedOrders(duplicates)
        .then((res) => {
          setOrderInfo(res);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    }

  };

  return (
    <>
      <Button onClick={() => setOpenModal(true)}>Выбрать</Button>
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <div className="modal-title-text">Выбрать номенклатуру</div>
          <CloseButton onClick={() => setOpenModal(false)} />
        </ModalHeader>

        {loading && <MySpinner overlay />}

        <ModalBody className="m-3">

          {orderInfo &&
            <>
              {orderInfo.map(item =>
                <>{item.article} {item.brand}, кол-во: {item.count}, №{item.number}, {item.provider}</>
              )}
            </>
          }

          <Row>
            <Col lg="6">
              <FormGroup>
                <Label>Артикул</Label>
                <ButtonGroup style={{ width: "100%" }}>
                  <AutocompleteArticle
                    article={article}
                    brand={brand}
                    setArticle={setArticle}
                    setBrand={({ id, name }) => setBrand(name)}
                    setName={setName}
                    setNomenclature={setNomenclature}
                    needValid={needValid}
                    style={{ width: "218px" }}
                    callback={setSuggestions}
                  />
                  <CreateNomenclatureModal
                    articleHandler={setArticle}
                    brandHandler={(brand) => setBrand(brand?.name)}
                    nameHandler={setName}
                    nomenclatureHandler={setNomenclature}
                  />
                </ButtonGroup>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Бренд</Label>
                <Input
                  name="brand"
                  placeholder="Бренд"
                  value={brand}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label>Наименование</Label>
                <Input
                  name="name"
                  placeholder="Наименование"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              onClick={setNomenclatureHandler}
              color="primary"
              className="button-wide"
            >
              Выбрать
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
