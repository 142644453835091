import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { createBrand } from "services/api";

import { validHandler } from "utils/validation";

export const CreateBrandModal = ({ openModal, setOpenModal, action }) => {
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState("");
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  useEffect(() => {
    const { errors } = validHandler({
      brand,
    });
    setErrors(errors);
  }, [brand]);

  const createSynonymHandler = () => {
    setNeedValid(true);
    const { valid, errors } = validHandler({
      brand,
    });
    setErrors(errors);
    if (!valid) return;
    setLoading(true);
    createBrand({
      name: brand,
    })
      .then(() => {
        setBrand("");
        action();
      })
      .catch((e) => {
        if (e.response?.data?.message) {
          alert(e.response.data.message);
        } else {
          alert("Ошибка создания бренда");
        }
      })
      .finally(() => {
        setOpenModal(false);
        setLoading(false);
        setNeedValid(false);
      });
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      {loading && <MySpinner overlay />}
      <ModalHeader>
        <div className="modal-title-text">Добавить бренд</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                invalid={needValid && errors.hasOwnProperty("brand")}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button onClick={createSynonymHandler} color="primary">
            Добавить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
