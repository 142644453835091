import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";
import { object, string } from "yup";

import { getProvider, updateProvider, createProvider, orgSuggestion } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Helmet } from "react-helmet";
import { HeaderPage } from "components/HeaderPage";
import { ActiveResource } from "components/ActiveResource";
import { LegalForm } from "features/Users/LegalForm";
import { Checkbox } from "components/Checkbox";
import { scrollToInvalid } from "utils/scrollToInvalid";

import "./provider.scss";

const ProvidercSchema = object().shape({
  name: string().min(2).required(),
  deliveryTime: string().required(),
  probability: string().required(),
  orgInn: string().required(),
  orgKpp: string().required(),
  orgName: string().required(),
  orgNameShort: string().required(),
  orgOgrn: string().required(),
  orgAddress: string().required(),
  orgRs: string().required(),
  orgKs: string().required(),
  orgBik: string().required(),
  orgBank: string().required(),
  genDir: string().required()
});

export const ProviderOnePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [provider, setProvider] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const getAndSetProvider = () => {
    getProvider(id)
      .then((res) => {
        setProvider(res);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    getAndSetProvider();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (values) => {
    setLoading(true);

    if (!values?.id) {
      createProvider(values)
        .then((res) => {
          history.push(res.id);
          toast.success("Поставщик успешно создан");
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      updateProvider(values)
        .then((res) => {
          setProvider(res);
          toast.success("Изменения успешно сохранены");
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const searchOrg = (search, setFieldValue) => {
    setSearch(search);
    if (search.length !== 10 && search.length !== 12) return;
    setLoading(true);
    orgSuggestion(search)
      .then(val => {
        console.log(val);
        const data = val[0] || null;

        if (!data) return;

        setFieldValue("orgNameShort", data.orgNameShort);
        setFieldValue("orgName", data.orgName);
        setFieldValue("orgAddress", data.orgAddress);
        setFieldValue("orgInn", data.orgInn);
        setFieldValue("orgKpp", data.orgKpp);
        setFieldValue("orgOgrn", data.orgOgrn);
        setFieldValue("orgOkpo", data.orgOkpo);
        setFieldValue("genDir", data.genDir);
        setFieldValue("firstName", data.firstName);
        setFieldValue("lastName", data.lastName);
        setFieldValue("middleName", data.middleName);

        toast.success("Данные организации успешно заполнены");

      })
      .catch(e => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false))
  };

  return (
    <Container fluid className="p-0 provider">
      <Helmet>
        <title>Поставщик {provider?.name || "новый"}</title>
      </Helmet>

      <Formik
        initialValues={{
          id: provider?.id || "",
          active: provider?.active || true,
          name: provider?.name || "",
          deliveryTime: provider?.deliveryTime || "",
          probability: provider?.probability || "",
          address: provider?.address || "",
          contacts: provider?.contacts || "",
          important: provider?.important || "",
          orgInn: provider?.orgInn || "",
          orgName: provider?.orgName || "",
          orgNameShort: provider?.orgNameShort || "",
          orgAddress: provider?.orgAddress || "",
          consigneeAddress: provider?.consigneeAddress || "",
          orgKpp: provider?.orgKpp || "",
          orgOgrn: provider?.orgOgrn || "",
          orgOkpo: provider?.orgOkpo || "",
          orgRs: provider?.orgRs || "",
          orgKs: provider?.orgKs || "",
          orgBik: provider?.orgBik || "",
          orgBank: provider?.orgBank || "",
          genDir: provider?.genDir || "",
          genDirGenitive: provider?.genDirGenitive || "",
          withPrice: provider?.withPrice || false,
          withDiadoc: provider?.withDiadoc || false,
          emailForPrice: provider?.emailForPrice || "",
          mailingPeriod: provider?.mailingPeriod || 0,
        }}
        validationSchema={ProvidercSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {

          submitHandler(values, resetForm);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (

          <form onSubmit={handleSubmit}>

            <HeaderPage title={`Поставщик ${provider?.name || "новый"}`}>
              <ActiveResource
                active={values.active}
                checkboxHandler={e => setFieldValue("active", e.target.checked)}
              />
            </HeaderPage>

            {loading && <MySpinner overlay />}

            <Card className="main-form mb-0">
              <CardBody>

                {!values.id && (
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">
                        ИНН <span>*</span>
                      </Label>
                      <Input
                        name="search"
                        placeholder="ИНН"
                        onChange={e => searchOrg(e.target.value, setFieldValue)}
                        value={search}
                        invalid={
                          errors.search && touched.search ? true : false
                        }
                      />
                      <span className="sub">
                        Начните с заполнения ИНН
                      </span>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col className="elem-field">
                    <Label className="text-sm-right">
                      Внутреннее наименование (псевдоним)
                    </Label>
                    <Input
                      name="name"
                      placeholder="Внутреннее наименование (псевдоним)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      invalid={
                        errors.name && touched.name ? true : false
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="elem-field">
                    <Label className="text-sm-right">Срок поставки, ч</Label>
                    <Input
                      name="deliveryTime"
                      placeholder="Срок поставки"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.deliveryTime}
                      invalid={
                        errors.deliveryTime && touched.deliveryTime ? true : false
                      }
                    />
                  </Col>
                  <Col className="elem-field">
                    <Label className="text-sm-right">Вероятность поставки, %</Label>
                    <Input
                      name="probability"
                      placeholder="Вероятность поставки"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.probability}
                      invalid={
                        errors.probability && touched.probability ? true : false
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="elem-field">
                    <Label className="text-sm-right">Адрес</Label>
                    <Input
                      type="textarea"
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      invalid={
                        errors.address && touched.address ? true : false
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="elem-field">
                    <Label className="text-sm-right">Контакты</Label>
                    <Input
                      type="textarea"
                      name="contacts"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.contacts}
                      invalid={
                        errors.contacts && touched.contacts ? true : false
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="elem-field">
                    <Label className="text-sm-right">Важная информация</Label>
                    <Input
                      type="textarea"
                      name="important"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.important}
                      invalid={
                        errors.important && touched.important ? true : false
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="elem-field">
                    <Label className="text-sm-right">Отправляет прайсы</Label>
                    <Checkbox
                      name="withPrice"
                      checked={values.withPrice}
                      onChange={e => setFieldValue("withPrice", e.target.checked)}
                      invalid={
                        errors.withPrice && touched.withPrice ? true : false
                      }
                    />
                  </Col>
                </Row>

                {values?.withPrice && (
                  <>
                    <Row>
                      <Col className="elem-field">
                        <Label className="text-sm-right">Email для прайсов</Label>
                        <Input
                          name="emailForPrice"
                          placeholder="Email для прайсов"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emailForPrice}
                          invalid={
                            errors.emailForPrice && touched.emailForPrice ? true : false
                          }
                        />
                      </Col>
                      <Col className="elem-field">
                        <Label className="text-sm-right">
                          Период обновления цен
                        </Label>
                        <Input
                          type="number"
                          name="mailingPeriod"
                          placeholder="Период обновления цен"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mailingPeriod}
                          invalid={
                            errors.mailingPeriod && touched.mailingPeriod ? true : false
                          }
                        />
                      </Col>
                    </Row>
                  </>
                )}

              </CardBody>
            </Card>

            <LegalForm
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setLoading={setLoading}
            />

            <div className="provider__save">
              <Button
                type="submit"
                color="primary"
                className="button-wide mr-3"
                onClick={scrollToInvalid}
              >
                Сохранить
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Container>
  );
};
