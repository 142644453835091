import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { dateFormat, priceFormat } from "utils/format";
import { ButtonAddToCart } from "../ButtonAddToCart";
import { Button, Col, Row } from "reactstrap";
import { Camera } from "react-feather";
import classNames from "classnames";

import { CreateProductModal } from "../CreateProductModal";
import { BarChart } from "components/BarChart";
import { Cut } from "components/Cut";

import "./searchResultTable.scss";
import moment from "moment";

export const SearchResultTable = ({
  result,
  setId,
  setOpenNomenclatureModal,
}) => {
  const [showResult, setShowResult] = useState(5);

  const setOpenNomenclature = (id) => {
    console.log(id);
    setId(id);
    setOpenNomenclatureModal(true);
  };

  const tableColumns = [
    {
      dataField: "article",
      text: "Артикул",
      classes: () => "bold",
      headerStyle: (colum, colIndex) => {
        return { width: "18%", minWidth: "100px" };
      },
      editable: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      formatter: (cell) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "nomenclature",
      text: "",
      formatter: (cell) =>
        cell?.id ? (
          <div
            onClick={() => setOpenNomenclature(cell.id)}
            className="modal-button"
          >
            <Camera color="gray" />
          </div>
        ) : (
          ""
        ),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      editable: false,
    },
    {
      dataField: "pricePurchase",
      text: "Зак.",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      classes: () => "right",
      headerClasses: () => "right",
      formatter: (cell) => priceFormat(cell),
      editable: false,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      formatter: (cell) => priceFormat(cell),
      classes: () => "editable right bold",
      headerClasses: () => "right",
      validator: (newValue) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Должны быть только цифры",
          };
        }
        if (newValue > 1000000) {
          return {
            valid: false,
            message: "Слишком большая цена",
          };
        }
        if (newValue < 1) {
          return {
            valid: false,
            message: "Цена не может быть меньше 1",
          };
        }
        return true;
      },
    },
    {
      dataField: "exist",
      text: "Нал.",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      formatter: (cell, row) => {
        return (row.our && cell > 0) ? <BarChart color="green" count={cell} /> : cell;
      },
      classes: (cell, row) => "right",
      editable: false,
    },
    {
      dataField: "existReserve",
      text: "Рез.",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: (cell) => "right",
      formatter: (cell, row) =>
        cell ? (
          <div>{cell ? <BarChart color="red" count={cell} /> : cell}</div>
        ) : (
          ""
        ),
      editable: false,
    },
    {
      dataField: "providerName",
      text: "Поставщик / пр.лист",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row) => {
        return <Cut>{row.priceListName ? row.priceListName : row.providerName}
          {" "}
          {(row.dateUpdate
            && !moment(row.dateUpdate).add(1, 'd').isAfter())
            && moment(row.dateUpdate).format("DD.MM.Y H:m")
          }</Cut>
      },
      classes: (cell, row) => {
        if (!row.dateUpdate) return;

        if (!moment(row.dateUpdate).add(1, 'd').isAfter()) {
          return "orange-bg";
        }

        if (!moment(row.dateUpdate).add(3, 'd').isAfter()) {
          return "red-bg";
        }
      },
      editable: false,
    },
    {
      dataField: "warehouseName",
      text: "Склад",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row) => {
        if (row.inTransit) {
          return (
            <>
              <span>В пути</span>
              <span className="date_arrival">{row.dateArrival}</span>
            </>
          );
        }
        return <span className="cute">{cell}</span>;
      },
      classes: (cell, row) =>
        classNames({
          "green-bg": (cell && !row.inTransit) || row.inStock,
          "blue-bg": row.inTransit,
        }),
      editable: false,
    },
    {
      dataField: "action",
      text: "В корзину",
      headerStyle: (colum, colIndex) => {
        return { width: "182px" };
      },
      formatter: (_, row) => (
        <div className="d-flex">
          <ButtonAddToCart row={row} />
          <CreateProductModal row={row} small addPrice action="copy" />
        </div>
      ),
      editable: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "sort",
      order: "asc",
    },
  ];

  return (
    <Row className="search-result">
      <Col lg="12">
        {result.length > 0 && (
          <BootstrapTable
            keyField="id"
            data={result.slice(0, showResult)}
            hover={true}
            columns={tableColumns}
            bootstrap4
            bordered={false}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
            })}
            defaultSorted={defaultSorted}
          />
        )}

        {result.length > 5 && result.length > showResult && (
          <div className="button-more">
            <Button
              onClick={() => setShowResult((prev) => prev + 5)}
              color="primary"
              className="button-wide"
            >
              Показать ещё
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};
