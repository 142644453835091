import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

import { downloadContractsToExcel, getCustomers, getUsersToExcel } from "services/api";
import { useUser } from "features/Users/UserContext";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";
import { UsersTable } from "features/Users/UsersTable";
import { TYPOLOGY } from "features/Users/CustomerInfo";

import "./users.scss";

const SORT_FIELDS = [
  { label: "Баланс ↑", value: "balance_asc", by: "balance", dir: "asc" },
  { label: "Баланс ↓", value: "balance_desc", by: "balance", dir: "desc" },
];

export const SOURCES_USERS = [
  {
    value: "site",
    label: "Сайт",
  },
  {
    value: "manager",
    label: "Менеджер",
  },
];

const ACTIVE_TYPES = [
  {
    value: "1",
    label: "Да",
  },
  {
    value: "0",
    label: "Нет",
  },
];

const CUSTOMER_TYPES = [
  {
    value: 1,
    label: "Физ",
  },
  {
    value: 2,
    label: "Юр",
  },
];

export const UsersPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const { admins, user } = useUser();
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [isShowReset, setIsShowReset] = useState(false);
  const [active, setActive] = useState(
    query.get("active")
      ? { label: query.get("activeName"), value: query.get("active") }
      : null
  );
  const [type, setType] = useState(
    query.get("type")
      ? { label: query.get("typeName"), value: query.get("type") }
      : null
  );
  const [typology, setTypology] = useState(
    query.get("typology")
      ? { label: query.get("typologyName"), value: query.get("typology") }
      : null
  );
  const [admin, setAdmin] = useState(
    query.get("admin")
      ? { label: query.get("adminName"), value: query.get("admin") }
      : null
  );

  const [salesRep, setSalesRep] = useState(
    query.get("salesRep")
      ? { label: query.get("salesRepName"), value: query.get("salesRep") }
      : null
  );

  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
      (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
    )
    : null;
  const [sort, setSort] = useState(sortByDefault);

  const getAndSetUsers = () => {
    getCustomers({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      sort,
      admin: admin?.value,
      salesRep: salesRep?.value,
      active: active?.value,
      type: type?.value,
      typology: typology?.value,
    })
      .then((res) => {
        setUsers(res.items);
        setUsersCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (sort?.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (admin?.value) {
      url.append("admin", admin?.value);
      url.append("adminName", admin?.label);
    }

    if (salesRep?.value) {
      url.append("salesRep", salesRep?.value);
      url.append("salesRepName", salesRep?.label);
    }

    if (type?.value) {
      url.append("type", type?.value);
      url.append("typeName", type?.label);
    }

    if (active?.value) {
      url.append("active", active?.value);
      url.append("activeName", active?.label);
    }

    if (typology?.value) {
      url.append("typology", typology?.value);
      url.append("typologyName", typology?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage,
    pageSize,
    sort,
    admin,
    salesRep,
    active?.value,
    type?.value,
    typology?.value
  ]);

  useEffect(() => {
    setLoading(true);
    getAndSetUsers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetUsers();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    sort,
    admin?.value,
    salesRep?.value,
    active?.value,
    type?.value,
    typology?.value
  ]);

  const isSearch = () => {
    if (
      search ||
      admin?.value ||
      salesRep?.value ||
      active?.value ||
      type?.value ||
      typology?.value
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetUsers();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const downloadHandler = () => {
    setLoading(true);
    getUsersToExcel({
      search,
      sort: sort?.value,
      admin: admin?.value,
      salesRep: salesRep?.value,
      active: active?.value,
      type: type?.value,
      typology: typology?.value
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Клиенты.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadContractsHandler = () => {
    setLoading(true);
    downloadContractsToExcel()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Договоры.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadRender = () => {
    if (user.roles?.find((role) => role === "ROLE_ADMIN")) {
      return (
        <>
          <Button onClick={downloadHandler} color="primary">
            Скачать клиентов
          </Button>
          <Button onClick={downloadContractsHandler} color="primary" className="ml-2">
            Скачать договоры
          </Button>
        </>

      );
    }
    return null;
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "admin":
        setAdmin(val);
        break;
      case "salesRep":
        setSalesRep(val);
        break;
      case "active":
        setActive(val);
        break;
      case "type":
        setType(val);
        break;
      case "typology":
        setTypology(val);
        break;
      default:
        break;
    }
  };

  const onSort = (field, order) => {
    setSort({
      by: field,
      dir: order,
    });
  };

  const resetFilter = () => {
    setSearch("");
    setAdmin(null);
    setSalesRep(null);
    setActive(null);
    setType(null);
    setTypology(null);
  };

  return (
    <Container fluid className="p-0 users">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Клиенты</h1>
          <Form className="mb-3">
            <Row>
              <Col md={3} className="mb-1">
                <Label>Поиск</Label>
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По фамилии, email, фирме, ИНН"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                </InputGroup>
              </Col>

              <Col md={2} className="mb-1">
                <Label>Менеджер</Label>
                <Select
                  name="admin"
                  placeholder="Ответственный менеджер"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={admin}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={2} className="mb-1">
                <Label>Торговый представитель</Label>
                <Select
                  name="salesRep"
                  placeholder="Торговый представитель"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins}
                  value={salesRep}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={1}>
                <Label>Активен</Label>
                <Select
                  name="active"
                  placeholder="Актив"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ACTIVE_TYPES}
                  value={active}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col md={1}>
                <Label>Тип</Label>
                <Select
                  name="type"
                  placeholder="юр, физ"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={CUSTOMER_TYPES}
                  value={type}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Label>Типология</Label>
                <Select
                  name="typology"
                  placeholder="Типология"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={TYPOLOGY}
                  value={typology}
                  onChange={selectHandler}
                  isClearable
                />
              </Col>
              <Col>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
          <div className="d-flex">
            <Button
              onClick={() => history.push("/sales/users/new")}
              color="primary"
              className="mr-2"
            >
              Добавить клиента
            </Button>

            {downloadRender()}
          </div>
        </CardBody>
      </Card>
      <div>
        <UsersTable
          users={users}
          onSort={onSort}
        />

        <Card>
          <CardBody>
            <Pagination
              totalCount={usersCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
