import React from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Trash } from "react-feather";

import { AddProductModal } from "../AddProductModal";
import { RemoveButton } from "../RemoveButton";

import "./misgradingProductTable.scss";

export const MisgradingProductTableNew = ({ products, setProducts }) => {
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "articleOut",
      text: "Артикул 1",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brandOut",
      text: "Бренд 1",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "nameOut",
      text: "Наименование 1",
      formatter: (cell, row) => <span className="cute">{row.nameOut}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => "editable right",
      validator: (newValue, row) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Должны быть только цифры",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Кол-во не может быть меньше 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "articleIn",
      text: "Артикул 2",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brandIn",
      text: "Бренд 2",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "nameIn",
      text: "Наименование 2",
      formatter: (cell, row) => <span className="cute">{row.nameIn}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      formatter: (cell, row) => (
        <RemoveButton removeHandler={() => removeProductsHandler(row.index)} />
      ),
      footer: (columnData) => <></>,
    },
  ];

  const removeProductsHandler = (index) => {
    setProducts((prev) => {
      prev.splice(
        prev.findIndex((val) => val.index === index),
        1
      );
      return [...prev];
    });
  };

  return (
    <>
      <Card className="misgrading-product mb-0">
        <CardBody>
          <Row className="misgrading-product__toolbar">
            <Col md="7" className="button-block">
              <Button
                color="primary"
                onClick={() => setOpenModal(true)}
                className="button-wide"
              >
                Добавить товар
              </Button>

              {selected.length > 0 && (
                <>
                  <Button
                    onClick={removeProductsHandler}
                    color="danger"
                    className="ml-2"
                  >
                    <Trash size="15" /> Удалить
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <BootstrapTable
        keyField="index"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
        })}
      />

      <AddProductModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setProducts={setProducts}
      />
    </>
  );
};
