export const colorStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: data.color,
      color: "white",
    };
  },
};

export const selectedStatuses = (statuses, filterStatus) => {
  return statuses.map((val) => {
    for (let st of filterStatus) {
      if (st.value === val.id) {
        return {
          label: val.name,
          value: val.id,
          color: val.color,
        };
      }
    }
    return false;
  });
};
