import React, { useState, useEffect } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import classNames from "classnames";

import { Cut } from "components/Cut";

import { getNomenclature, updateNomenclature } from "services/api";
import { dateFormat, priceFormat } from "utils/format";
import { GetBestPrice } from "../GetBestPrice";
import { searchPrice } from "services/api";
import { PROVIDERS } from "pages/dashboard";
import {
  getNomenclatureInReserve,
  updateBestPrice,
} from "services/apiAnalitics";
import { GetReserveModal } from "features/AnaliticsStock/GetReserveModal";

import "./analiticsStock.scss";

const FIELDS = {
  default: [
    "article",
    "brand",
    "name",
    "costPrice",
    "marketplacePrice",
    "fixPrice",
    "bestPrice",
    "providerName",
    "count",
    "existReserve",
    "exist",
    "warehouseName",
    "daysFromSupply",
    "daysFromShipping",
    "daysFromReserve",
    "barcode",
    "editBy",
  ],
  nomenclature: [
    "article",
    "brand",
    "name",
    "costPrice",
    "count",
    "existReserve",
    "exist",
    "barcode",
  ],
  brands: ["brand", "costPrice", "count", "existReserve", "exist"],
  providers: ["providerName", "costPrice", "count", "existReserve", "exist"],
  warehouses: ["warehouseName", "costPrice", "count", "existReserve", "exist"],
  company: ["company", "costPrice", "count", "existReserve", "exist"],
};

export const AnaliticsStockTable = ({
  analiticsStock,
  setAnaliticsStock,
  setLoading,
  getAndSetAnaliticsStock,
  onSort,
  context,
}) => {
  const [result, setResult] = useState([]);
  const [progress, setProgress] = useState(0);
  const [analiticsId, setAnaliticstId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [nomenclatureId, setNomenclatureId] = useState(null);
  const [reservedOrders, setReservedOrders] = useState([]);
  const [reserveModalLoading, setReserveModalLoading] = useState([]);
  const [columns, setColumns] = useState([]);

  const sortResult = (items) => {
    return items.sort(function (a, b) {
      if (a.sort > b.sort) {
        return 1;
      }
      if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    });
  };

  const getBestPriceHandler = (article, brand, id) => {
    setLoading(true);
    setAnaliticstId(id);
    setProgress(0);
    setResult([]);
    const percent = 100 / PROVIDERS.length;
    PROVIDERS.forEach((provider, i) => {
      searchPrice({ article, brand, provider })
        .then((res) => {
          setResult((prev) =>
            sortResult([...prev, ...res.prices]).filter(
              (r) => r.providerId !== "518680fd-6d10-4543-96a4-108534ba69ca"
            )
          );
        })
        .finally(() => {
          setProgress((prev) => Math.floor(prev + percent));
        });
    });
  };

  useEffect(() => {
    if (progress >= 99) {
      if (result.length > 0) {
        updateBestPrice({
          id: analiticsId,
          price: result[0].pricePurchase || null,
        })
          .then(() => {
            getAndSetAnaliticsStock();
            toast.success("Цена успешно сохранена");
          })
          .finally(() => {});
      } else {
        toast.error("Ничего не найдено");
        setLoading(false);
      }
    }
  }, [progress]);

  const reserveModalHandler = (nomenclatureId) => {
    setNomenclatureId(nomenclatureId);
    setOpenModal(true);
    setReserveModalLoading(true);
    getNomenclatureInReserve({ id: nomenclatureId })
      .then((res) => {
        setReservedOrders(res);
      })
      .finally(() => {
        setReserveModalLoading(false);
      });
  };

  const tableColumns = [
    {
      dataField: "company",
      text: "Подразделение",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "article",
      text: "Артикул",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      headerStyle: () => {
        return { width: "8%" };
      },
      editable: false,
    },
    {
      dataField: "name",
      text: "Наименование",
      headerStyle: () => {
        return { width: "18%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "warehouseName",
      text: "Склад",
      headerStyle: () => {
        return { width: "9%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "providerName",
      text: "Поставщик",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "costPrice",
      text: "Себ-ть",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      editable: false,
    },
    {
      dataField: "marketplacePrice",
      text: <Cut>Площадки</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) =>
        classNames("right bold", {
          "red-bg": row.marketplacePrice < row.costPrice,
        }),
    },
    {
      dataField: "fixPrice",
      text: <Cut>Фикс прайс</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => priceFormat(cell),
      headerClasses: () => "right",
      classes: (cell, row) =>
        classNames("right bold", { "red-bg": row.fixPrice < row.costPrice }),
    },
    {
      dataField: "bestPrice",
      text: <Cut>Лучший прайс</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => (
        <>
          {priceFormat(cell)}
          <GetBestPrice
            getBestPriceHandler={() =>
              getBestPriceHandler(row.article, row.brand, row.id)
            }
          />
        </>
      ),
      headerClasses: () => "right",
      classes: () => "center",
      editable: false,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "existReserve",
      text: "Резерв",
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "exist",
      text: <Cut>Своб. ост.</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "daysFromSupply",
      text: <Cut>Дней с пост.</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "daysFromShipping",
      text: <Cut>Дней с реализ.</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
    },
    {
      dataField: "daysFromReserve",
      text: <Cut>Дней с перв. рез.</Cut>,
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) =>
        cell ? (
          <span
            className="link"
            onClick={() => reserveModalHandler(row.nomenclature)}
          >
            {cell}
          </span>
        ) : (
          ""
        ),
      headerClasses: () => "right",
      classes: () => "right bold",
      editable: false,
      sort: true,
      onSort,
      sortFunc: () => {
        return false;
      },
    },
    {
      dataField: "barcode",
      text: "Штрихкод",
      headerStyle: () => {
        return { width: "6%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
    {
      dataField: "editBy",
      text: "Кто изменил цену",
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      editable: false,
    },
  ];

  useEffect(() => {
    const newColumns = tableColumns.filter((col) => {
      if (FIELDS[context?.value || "default"].includes(col.dataField))
        return col;
    });
    setColumns(newColumns);
  }, [context]);

  const editHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateNomenclature({
      id: row.nomenclature,
      fixPrice: row.fixPrice,
      minPrice: row.minPrice,
      marketplacePrice: row.marketplacePrice,
    })
      .then((res) => {
        toast.success("Данные успешно сохранены");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const rowEvents = {
    onClick: (e, row) => {
      setAnaliticsStock(
        analiticsStock.map((val) => {
          val.selected = false;
          if (val.id === row.id) {
            val.selected = true;
          }
          return val;
        })
      );
    },
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.selected) {
      style.backgroundColor = "#dcf2ff";
    }
    return style;
  };

  return (
    <>
      {columns.length && (
        <BootstrapTable
          keyField="id"
          data={analiticsStock}
          columns={columns}
          bootstrap4
          bordered={false}
          hover={true}
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell: (oldData, newData, row) =>
              editHandler(oldData, newData, row),
          })}
          rowStyle={rowStyle}
          rowEvents={rowEvents}
        />
      )}
      <GetReserveModal
        id={nomenclatureId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        reservedOrders={reservedOrders}
        loading={reserveModalLoading}
      />
    </>
  );
};
