import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { ChevronLeft as ChevronLeftIcon } from "react-feather";

import "./goBack.scss";

export const GoBack = () => {
  const history = useHistory();
  return (
    <div className="back-to">
      <Button className="mb-1"  onClick={history.goBack}>
        <ChevronLeftIcon size={17}/>
        Вернуться
      </Button>
    </div>
  );
};
