import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row, Table } from "reactstrap";
import { Trash } from "react-feather";

import { FormTitle } from "components/FormTitle";
import { TableButton } from "components/TableButton";
import { updateAccount } from "services/api";
import { toast } from "react-toastify";

export const Emails = ({ customer, setLoading }) => {
  const [shownForm, setShownForm] = useState(false);
  const [email, setEmail] = useState("");
  const [emailsForDocuments, setEmailsForDocuments] = useState([]);

  useEffect(() => {
    if (!customer?.users[0]?.emailsForDocuments) return;
    setEmailsForDocuments(customer?.users[0]?.emailsForDocuments);
  }, [customer?.users[0]?.emailsForDocuments]);

  const removeHandler = (email) => {
    if (!window.confirm("Вы действительно хотите удалить email?")) {
      return;
    }

    emailsForDocuments.splice(
      emailsForDocuments.findIndex((val) => val === email),
      1
    );

    setLoading(true);
    updateAccount({
      id: customer?.users[0].id,
      emailsForDocuments
    })
      .then((res) => {
        toast.success("Изменения успешно сохранены");
        setEmailsForDocuments(res?.users[0]?.emailsForDocuments);
        setShownForm(false);
        setEmail("");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  const addEmailHandler = () => {
    setLoading(true);


    console.log(emailsForDocuments);
    updateAccount({
      id: customer?.users[0].id,
      emailsForDocuments: [...emailsForDocuments, email]
    })
      .then((res) => {
        toast.success("Изменения успешно сохранены");
        setEmailsForDocuments(res?.users[0]?.emailsForDocuments);
        setShownForm(false);
        setEmail("");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  const renderEmailsTable = () => {
    if (!emailsForDocuments?.length) {
      return null;
    }

    return <Table className="table-hover emails">
      <thead>
        <tr>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {emailsForDocuments.map(email =>
          <tr
            key={email}
          >
            <td>{email}</td>
            <td>
              <TableButton
                Icon={Trash}
                onClick={() => removeHandler(email)}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>

  };

  return <>
    <Card className="mb-0">
      <CardBody className="pb-2">
        <FormTitle>Email для отправки документов</FormTitle>
      </CardBody>
    </Card>
    <Card
      className="main-form"
    >
      <CardBody className="pt-0">
        {renderEmailsTable()}
        <div className="email__add">
          {shownForm ? (<>
            <Row className="mb-2">
              <Col className="elem-field">
                <Label className="text-sm-right">Email</Label>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end buttons">
              <Button
                color="primary"
                onClick={addEmailHandler}
                className="email__save mr-2"
              >
                Сохранить
              </Button>
              <Button
                color="secondary"
                onClick={() => setShownForm(false)}
              >
                Отменить
              </Button>
            </div>
          </>) : <Button onClick={() => setShownForm(true)}>Добавить email</Button>}

        </div>
      </CardBody>
    </Card>
  </>
}