import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import classNames from "classnames";

import { AccountModal } from "../AccountModal";
import { CheckCircle, XCircle } from "react-feather";
import { FormTitle } from "components/FormTitle";

export const Accounts = ({ getAndSetCustomer, customer }) => {
  const [openModal, setOpenModal] = useState(false);
  const [accountId, setAccountId] = useState(null);

  const openAccount = (id = null) => {
    setAccountId(id);
    setOpenModal(true);
  };

  useEffect(() => {
    if (openModal === false) {
      setAccountId(null);
    }
  }, [openModal]);

  const renderAccountsTable = () => {
    if (customer?.users?.length <= 1) {
      return null;
    }

    return <Table className="table-hover accounts">
      <thead>
        <tr>
          <th>Название</th>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Отчество</th>
          <th>Телефон</th>
          <th>Email</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {customer?.users.map(item =>
          <tr
            key={item.id}
            className={classNames({ inactive: !item.active })}
            onClick={() => openAccount(item.id)}
          >
            <td>{item.title}</td>
            <td>{item.lastName}</td>
            <td>{item.firstName}</td>
            <td>{item.middleName}</td>
            <td>{item.phone}</td>
            <td>{item.email}</td>
            <td>{item.active ?
              <CheckCircle color="green" size={17} />
              :
              <XCircle color="red" size={17} />
            }</td>
          </tr>
        )}
      </tbody>
    </Table>

  };

  return (
    <>
      <Card className="mb-0">
        <CardBody className="pb-2">
          <FormTitle>Подразделения</FormTitle>
        </CardBody>
      </Card>
      <Card
        className="main-form"
      >
        <CardBody className="pt-0">
          {renderAccountsTable()}
          <Button onClick={() => openAccount()}>Добавить подразделение</Button>

          <AccountModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            getAndSetCustomer={getAndSetCustomer}
            customer={customer}
            accountId={accountId}
            setAccountId={setAccountId}
          />
        </CardBody>
      </Card >
    </>
  )

}